import React, { FC, useCallback, useEffect, useMemo, useState, FormEvent } from 'react';
import useFormFields from '@usereact/use-form-fields';

import _isNull from 'lodash/isNull';

import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/information-circle.svg';
import { ReactComponent as MarkerIcon } from 'assets/icons/style-two-pin-marker.svg';
import { ReactComponent as SegmentIcon } from 'assets/icons/data-transfer-horizontal.svg';

import CameraInactiveMarker from 'assets/images/markers/camera-inactive.svg';
import CameraMarker from 'assets/images/markers/camera.svg';
import NodeMarker from 'assets/images/markers/node.svg';
import NodeSelectedMarker from 'assets/images/markers/node-selected.svg';

import { Button } from 'shared/button';
import { Card } from 'shared/card';
import { Checkbox } from 'shared/checkbox';
import { Table } from 'shared/table';

import { Map } from 'shared/maps/map';
import { Markers } from 'shared/maps/markers';

import { useNodeClusters } from './use-node-clusters';
import { AppLayout } from '../app.layout';

// Types
import { NodeGroup } from 'types';

const styleTop6 = { top: '6rem' };
const cameraInactiveMarkerIcon = { url: CameraInactiveMarker, anchor: { x: 22, y: 22 } };
const cameraMarkerIcon = { url: CameraMarker, anchor: { x: 22, y: 22 } };
const nodeMarkerIcon = { url: NodeMarker, scaledSize: { height: 8, width: 8 }, anchor: { x: 4, y: 4 } };
const nodeSelectedMarkerIcon = { url: NodeSelectedMarker, scaledSize: { height: 8, width: 8 }, anchor: { x: 4, y: 4 } };
const mapOptions = { draggableCursor: 'cell', disableDoubleClickZoom: true };

export const NodeClustersPage = () => {
  const {
    deleteNodeGroup,
    deselectNodeGroup,
    getData,
    getPositionForNode,
    getPositionForNodeGroup,
    isNodeGroupInactive,
    isLoading,
    isNodeSelected,
    isReadyOnly,
    nodeGroups,
    nodes,
    onDrawRectangle,
    saveNodeGroup,
    selectedNodeGroup,
    selectNodeGroup,
    selectNodeGroupById,
  } = useNodeClusters();

  return (
    <AppLayout name="Node clusters">
      <div className="xl:flex">
        <div className="w-full xl:w-1/2 relative">
          <div className="sticky" style={styleTop6}>
            <Card title="Map" icon={<MarkerIcon />}>
              {isLoading && (
                <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                  <Loading className="text-gray-500 w-16 mx-auto my-20" />
                </div>
              )}
              <Map
                canDrawRectangle={!isReadyOnly}
                onChange={getData}
                onDrawRectangle={onDrawRectangle}
                options={mapOptions}
              >
                <Markers
                  data={nodes?.data}
                  getPosition={getPositionForNode}
                  icon={nodeMarkerIcon}
                  iconWhenSelected={nodeSelectedMarkerIcon}
                  isSelected={isNodeSelected}
                />
                <Markers
                  data={nodeGroups?.data}
                  getPosition={getPositionForNodeGroup}
                  hasLabels={true}
                  icon={cameraMarkerIcon}
                  iconWhenInactive={cameraInactiveMarkerIcon}
                  isInactive={isNodeGroupInactive}
                  labelProp="label"
                  onClick={selectNodeGroup}
                />
              </Map>
            </Card>
          </div>
        </div>

        <div className="w-full xl:w-1/2">
          <div className="pt-8 xl:pl-8 xl:pt-0">
            {!isReadyOnly && (
              <div className="mb-8">
                <Card title="Instructions" icon={<InfoIcon />}>
                  <div className="p-6">
                    <ul className="text-sm list-disc list-outside pl-4">
                      <li className="mb-1">Mouse click to select/unselect a single node</li>
                      <li className="mb-1">Use drawing tool to draw rectangle to select multiple nodes</li>
                    </ul>
                  </div>
                </Card>
              </div>
            )}

            {selectedNodeGroup && !isReadyOnly && (
              <NodeGroupForm
                isLoading={isLoading}
                isReadyOnly={isReadyOnly}
                onClear={deselectNodeGroup}
                onDelete={deleteNodeGroup}
                onSubmit={saveNodeGroup}
                nodeGroup={selectedNodeGroup as NodeGroup}
              />
            )}

            {!!nodeGroups?.data?.length && (
              <NodeGroupsTable
                onRowSelect={selectNodeGroupById}
                nodeGroups={nodeGroups.data}
                selectedNodeGroupId={selectedNodeGroup?.id as number}
              />
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

type NodeGroupsTable = { nodeGroups: NodeGroup[]; onRowSelect: Function; selectedNodeGroupId: number };
const NodeGroupsTable: FC<NodeGroupsTable> = ({ nodeGroups, onRowSelect, selectedNodeGroupId }) => {
  const headers = useMemo(
    () => ({
      label: 'Label',
      count: 'Number of nodes',
      owner: 'Serving railroad',
    }),
    [],
  );

  const data = useMemo(() => {
    return nodeGroups.map((ng) => {
      return {
        id: ng.id,
        label: ng.label,
        count: ng.count,
        owner: ng.owner,
      };
    });
  }, [nodeGroups]);

  return (
    <div className="mb-8">
      <Card title="Node clusters" icon={<SegmentIcon />}>
        <Table data={data} headers={headers} onRowSelect={onRowSelect} selectedEventId={selectedNodeGroupId} />
      </Card>
    </div>
  );
};

type NodeGroupForm = {
  isLoading: boolean;
  isReadyOnly: boolean;
  nodeGroup: NodeGroup;
  onClear: Function;
  onDelete: Function;
  onSubmit: Function;
};
const NodeGroupForm: FC<NodeGroupForm> = ({ isLoading, isReadyOnly, onClear, onDelete, onSubmit, nodeGroup }) => {
  const [id, setId] = useState<number | null>();
  const isExisting = !!id;

  const { values, fields, setValue } = useFormFields({
    label: '',
    owner: '',
  });

  const [isActive, setIsActive] = useState(false);
  const onIsActiveChange = useCallback((_, v) => setIsActive(v), []);

  useEffect(() => {
    if (!nodeGroup) return;
    setId(nodeGroup.id);
    setValue('label', nodeGroup.label);
    setValue('owner', nodeGroup.owner);
    setIsActive(!_isNull(nodeGroup.isActive) ? nodeGroup.isActive : true);
  }, [nodeGroup, id, setValue]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit({ id, isActive, ...values });
    },
    [onSubmit, id, isActive, values],
  );

  const handleClear = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onClear();
    },
    [onClear],
  );

  const handleDelete = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onDelete(id);
    },
    [onDelete, id],
  );

  return (
    <div className="mb-8">
      <Card title={isExisting ? 'Node cluster' : 'Add node cluster'} icon={<SegmentIcon />}>
        <form className="text-sm" onSubmit={handleSubmit}>
          <div className="p-6">
            <div className="flex flex-wrap -mx-4">
              <fieldset className="w-1/2 px-4 mb-6">
                <label className="block mb-1">Node group label</label>
                <input
                  type="text"
                  className="w-full placeholder-gray-400 border border-gray-400 p-3"
                  placeholder="Node group label"
                  {...fields.label}
                />
              </fieldset>
              <fieldset className="w-1/2 px-4 mb-6">
                <label className="block mb-1">Serving railroad</label>
                <input
                  type="text"
                  className="w-full placeholder-gray-400 border border-gray-400 p-3"
                  placeholder="Serving railroad"
                  {...fields.owner}
                />
              </fieldset>
            </div>
            <div className="flex flex-wrap -mx-4">
              <fieldset className="w-1/2 px-4 mb-6">
                <div className="block">
                  <Checkbox
                    isReadyOnly={isReadyOnly}
                    label="Is active"
                    onChange={onIsActiveChange}
                    uid="isNodeGroupActive"
                    value={isActive}
                  />
                </div>
              </fieldset>
            </div>
          </div>
          <div className="sm:flex items-center justify-between bg-gray-100 border-t border-gray-300 p-4">
            <div className="text-xs uppercase mb-4 sm:mb-0 sm:mr-2 text-right md:text-left">
              {nodeGroup.count && <>Selected nodes: {nodeGroup.count}</>}
            </div>
            <div className="flex justify-end">
              <div className="mx-2">
                <Button fill="outline" color="gray-600" onClick={handleClear}>
                  Clear
                </Button>
              </div>
              {isExisting && (
                <div className="mx-2">
                  <Button fill="outline" color="red-700" onClick={handleDelete}>
                    Delete
                  </Button>
                </div>
              )}
              <div className="mx-2">
                <input
                  className="bg-blue-700 border border-blue-700 rounded text-white px-4 py-2 cursor-pointer disabled:bg-gray-500 disabled:border-gray-500 disabled:cursor-not-allowed"
                  disabled={isLoading}
                  type="submit"
                  value={isLoading ? 'Please wait...' : `${isExisting ? 'Update' : 'Add'} grouping`}
                />
              </div>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};
