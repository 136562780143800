import React, { FC, useCallback, useEffect, useMemo, useState, FormEvent } from 'react';
import useFormFields from '@usereact/use-form-fields';

import { ReactComponent as UserIcon } from 'assets/icons/single-neutral-circle-alternate.svg';
import { Button } from 'shared/button';
import { Card } from 'shared/card';
import { Select, SelectOptionType } from 'shared/select';
import { User } from 'types';
import { RoleList } from 'constants/roles';
import { TierList } from 'constants/tier';



type UserForm = { isLoading: boolean; onClear: Function; onDelete: Function; onSubmit: Function; user: User };
export const UserForm: FC<UserForm> = ({ isLoading, onClear, onDelete, onSubmit, user }) => {
  const [uid, setUid] = useState<string | null>();
  const isExisting = !!uid;

  const roleOptions = useMemo(() => RoleList.map((value) => ({ value, label: value })), []);
  const tierOptions = useMemo(() => TierList.map((value) => ({ value, label: value })), []);

  const [role, setRole] = useState<SelectOptionType>();
  const [tier, setTier] = useState<SelectOptionType>({ value: "1", label: "1" });
  const selectRoleFromSelect = useCallback((option) => setRole(option), []);
  const selectTierFromSelect = useCallback((option) => setTier(option), []);

  const { values, fields, setValue } = useFormFields({
    name: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    setUid(user.uid);
    setValue('email', user.email);
    setValue('password', '');
    setValue(
      'name',
      user.providerData.length && user.providerData[0].displayName ? user.providerData[0].displayName : '',
    );
    setRole({ label: user.customClaims?.role, value: user.customClaims?.role });
    setTier({ label: user.customClaims && user.customClaims.tier ? user.customClaims.tier : "1", value: user.customClaims && user.customClaims.tier ? user.customClaims.tier : "1" });

  }, [user, setValue]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit(isExisting ? { uid, role: role?.value, tier: tier?.value, name: values.name } : { role: role?.value, tier: tier?.value, ...values });
    },
    [onSubmit, uid, role, tier, values, isExisting],
  );

  const handleClear = useCallback(() => onClear(), [onClear]);
  const handleDelete = useCallback(() => onDelete(user.uid), [onDelete, user.uid]);

  return (
    <Card title={isExisting ? 'Edit user' : 'Add user'} icon={<UserIcon />}>
      <form className="text-sm" onSubmit={handleSubmit}>
        <div className="pt-6 px-6">
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6">
              <label className="block mb-1" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                className="p-3 placeholder-gray-400 border border-gray-400 w-full mb-6"
                placeholder="Name"
                {...fields.name}
              />
            </fieldset>
            <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6">
              <label className="block mb-1" htmlFor="password">
                Role
              </label>
              <Select onChange={selectRoleFromSelect} options={roleOptions} placeholder="Role" value={role} />
            </fieldset>
          </div>
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6">
              <label className="block mb-1" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                disabled={isExisting}
                className="p-3 placeholder-gray-400 border border-gray-400 w-full"
                placeholder="Email"
                {...fields.email}
              />
            </fieldset>

            <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6">
              {!isExisting && (
                <>
                  <label className="block mb-1" htmlFor="password">
                    Password
                  </label>
                  <input
                    type="password"
                    className="p-3 placeholder-gray-400 border border-gray-400 w-full"
                    placeholder="Password"
                    {...fields.password}
                  />
                </>
              )}
            </fieldset>
          </div>
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-6/12 sm:mx-4 mb-6">
              <label className="block mb-1" htmlFor="password">
                Tier
              </label>
              <Select onChange={selectTierFromSelect} options={tierOptions} placeholder="Role" value={tier} />
            </fieldset>
          </div>
        </div>
        <div className="flex items-center justify-end bg-gray-100 border-t border-gray-300 p-4">
          {!isLoading && (
            <div className="mx-2">
              <Button fill="outline" color="gray-600" onClick={handleClear}>
                Clear
              </Button>
            </div>
          )}
          {isExisting && !isLoading && (
            <div className="mx-2">
              <Button color="red-700" onClick={handleDelete}>
                Delete
              </Button>
            </div>
          )}
          <div className="mx-2">
            <input
              className="bg-blue-700 rounded text-white px-4 py-2 cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
              disabled={isLoading}
              type="submit"
              value={isLoading ? 'Please wait...' : `${isExisting ? 'Update user' : 'Add user'}`}
            />
          </div>
        </div>
      </form>
    </Card>
  );
};
