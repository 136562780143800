import { useState, useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

import _find from 'lodash/find';
import _isNull from 'lodash/isNull';
import _uniq from 'lodash/uniq';

import { useApi } from 'hooks/use-api';

import { useUser } from 'auth.provider';

import { Notify } from 'services/notify';

import { DEFAULT_MAP_BOUNDS } from 'constants/defaults';

import { stringifyMapBounds } from 'utils/stringify-map-bounds';

// Types
import { Node, NodeGroup } from 'types';

export const useNodeClusters = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const { isReadyOnly = false } = useUser();

  const DEFAULT_LIMIT = 3000;

  const [selectedNodes, setSelectedNodes] = useState<number[]>([]);

  const [{ data: nodes, loading: isNodesLoading }, getNodes] = useApi({ url: '/nodes' });
  const [{ data: nodeGroups, loading: isNodeGroupsLoading }, getNodeGroups] = useApi({
    url: '/nodeGroups',
  });
  const [{ loading: isNodeGroupLoading }, updateNodeGroups] = useApi({
    url: '/nodeGroups',
  });

  const newNodeGroup = Object.assign({
    label: '',
    owner: '',
    isActive: false,
  });
  const [selectedNodeGroup, setSelectedNodeGroup] = useState<Partial<NodeGroup>>(newNodeGroup);

  useEffect(() => {
    const yes = isNodesLoading || isNodeGroupsLoading || isNodeGroupLoading;
    setIsLoading(yes);
  }, [isNodesLoading, isNodeGroupsLoading, isNodeGroupLoading]);

  useEffect(() => {
    const nodeGroupsData = nodeGroups?.data;
    if (!nodeGroupsData) return;

    let _groupNodes: number[] = [];
    let _nodesWithCamera: number[] = [];

    nodeGroupsData.forEach(({ nodes = [], hasCamera = [] }: { nodes: number[]; hasCamera: number[] }) => {
      _groupNodes = _groupNodes.concat(nodes);
      if (hasCamera) _nodesWithCamera = _nodesWithCamera.concat(nodes);
    });
  }, [nodeGroups]);

  const getData = useCallback(
    (bounds: MapBounds = DEFAULT_MAP_BOUNDS, cb: Function = () => ({})) => {
      const bbox = stringifyMapBounds(bounds);
      getNodes({ params: { limit: DEFAULT_LIMIT, bbox } });
      getNodeGroups({ params: { limit: DEFAULT_LIMIT, bbox } });
      cb();
    },
    [getNodeGroups, getNodes],
  );

  const getPositionForNode = (node: Node) => {
    return node.coordinates;
  };

  const getPositionForNodeGroup = (ng: NodeGroup) => {
    return { lat: parseFloat(ng.center.lat), lng: parseFloat(ng.center.lng) };
  };

  type MapBounds = { south: number; north: number; west: number; east: number };
  const selectNodesInArea = ({ south, north, west, east }: MapBounds) => {
    const newSelectedNodes: number[] = [];
    ((nodes?.data as Node[]) || []).forEach(({ locationid, coordinates: { lat, lng } }) => {
      if (lat >= south && lat <= north && lng >= west && lng <= east) {
        newSelectedNodes.push(locationid);
      }
    });
    setSelectedNodes(newSelectedNodes);
  };

  const onDrawRectangle = (rect: any) => {
    const bounds = rect.getBounds().toJSON();
    selectNodesInArea(bounds);
    rect.setMap(null);
  };

  const selectNodeGroupById = useCallback(
    (id) => {
      if (!nodeGroups?.data?.length) return;
      const ng = _find(nodeGroups.data, { id: parseInt(id) });
      setSelectedNodeGroup(ng);
    },
    [nodeGroups],
  );

  const selectNodeGroup = (nodeGroup: NodeGroup) => {
    setSelectedNodeGroup(nodeGroup);
  };

  const isNodeGroupInactive = (nodeGroup: NodeGroup) => {
    return !_isNull(nodeGroup.isActive) ? !nodeGroup.isActive : false;
  };

  const isNodeSelected = (node: Node) => {
    return selectedNodes.includes(node.locationid) || selectedNodeGroup?.nodes?.includes(node.locationid);
  };

  const deselectNodeGroup = useCallback(() => {
    setSelectedNodes([]);
    setSelectedNodeGroup(newNodeGroup);
  }, [newNodeGroup]);

  const saveNodeGroup = useCallback(
    (ng) => {
      const isExisting = !!ng.id;
      const url = '/nodeGroups' + (isExisting ? `/${ng.id}` : '');
      const method = isExisting ? 'PATCH' : 'POST';

      const existingNodes = selectedNodeGroup?.nodes ? selectedNodeGroup.nodes : [];
      const nodes = _uniq(selectedNodes.concat(existingNodes));

      if (!nodes.length) {
        return addToast('Please select at least one node', { appearance: 'error', autoDismiss: true });
      } else if (!ng.label || !ng.owner) {
        return addToast('Please complete form to proceed', { appearance: 'error', autoDismiss: true });
      }

      updateNodeGroups({ url, method, data: { ...ng, nodes } })
        .then(() => getData())
        .then(() => {
          setSelectedNodes([]);
          if (!isExisting) setSelectedNodeGroup(newNodeGroup);
          addToast('Node cluster saved successfully', { appearance: 'success', autoDismiss: true });
        })
        .catch(() => {
          addToast('Failed to save node cluster.', { appearance: 'error', autoDismiss: true });
        });
    },
    [addToast, getData, newNodeGroup, selectedNodeGroup, selectedNodes, updateNodeGroups],
  );

  const deleteNodeGroup = (id: number) => {
    Notify.confirm({
      title: 'Delete node cluster',
      text: 'Are you sure you want to proceed?',
      isDestructive: true,
    }).then(({ isDismissed }) => {
      if (isDismissed) return;
      updateNodeGroups({ url: `/nodeGroups/${id}`, method: 'DELETE' })
        .then(() => getData())
        .then(() => {
          deselectNodeGroup();
          addToast('Node cluster deleted successfully', { appearance: 'success', autoDismiss: true });
        })
        .catch(() => {
          addToast('Failed to delete node cluster.', { appearance: 'error', autoDismiss: true });
        });
    });
  };

  return {
    deleteNodeGroup,
    deselectNodeGroup,
    getData,
    getPositionForNode,
    getPositionForNodeGroup,
    isNodeGroupInactive,
    isLoading,
    isNodeSelected,
    isReadyOnly,
    nodeGroups,
    nodes,
    onDrawRectangle,
    saveNodeGroup,
    selectedNodeGroup,
    selectNodeGroup,
    selectNodeGroupById,
  };
};
