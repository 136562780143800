import React, { FC, useCallback, useEffect, useMemo, useState, FormEvent } from 'react';
import useFormFields from '@usereact/use-form-fields';

import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/information-circle.svg';
import { ReactComponent as MarkerIcon } from 'assets/icons/style-two-pin-marker.svg';
import { ReactComponent as SegmentIcon } from 'assets/icons/data-transfer-horizontal.svg';

import CameraMarker from 'assets/images/markers/camera.svg';

import { Button } from 'shared/button';
import { Card } from 'shared/card';
import { Select, SelectOptionType } from 'shared/select';
import { Table } from 'shared/table';

import { Map } from 'shared/maps/map';
import { Polylines } from 'shared/maps/polylines';
import { Markers } from 'shared/maps/markers';

import { useSegmentClusters } from './use-segment-clusters';
import { AppLayout } from '../app.layout';

// Types
import { Camera, SegmentGroup } from 'types';

const styleTop6 = { top: '6rem' };
const cameraMarkerIcon = { url: CameraMarker, anchor: { x: 22, y: 22 } };
const mapOptions = { draggableCursor: 'cell', disableDoubleClickZoom: true };

export const SegmentClustersPage = () => {
  const {
    cameras,
    deleteSegmentGroup,
    deselectSegmentGroup,
    getCameraNameById,
    getData,
    getPositionForCamera,
    groupSegments,
    isLoading,
    isReadyOnly,
    saveSegmentGroup,
    segmentGroups,
    segments,
    segmentsWithCamera,
    selectedSegmentGroup,
    selectedSegments,
    selectSegment,
    selectSegmentGroupById,
  } = useSegmentClusters();

  return (
    <AppLayout name="Segment clusters">
      <div className="xl:flex">
        <div className="w-full xl:w-1/2 relative">
          <div className="sticky" style={styleTop6}>
            <Card title="Map" icon={<MarkerIcon />}>
              {isLoading && (
                <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                  <Loading className="text-gray-500 w-16 mx-auto my-20" />
                </div>
              )}
              <Map onChange={getData} options={mapOptions}>
                <Polylines
                  groupSegments={groupSegments.concat(selectedSegments)}
                  hoveredSegments={selectedSegmentGroup.segments}
                  onClick={selectSegment}
                  segments={segments?.data}
                  segmentsWithCamera={segmentsWithCamera}
                  selectedSegmentGroup={selectedSegmentGroup}
                />
                <Markers
                  data={cameras?.data}
                  getPosition={getPositionForCamera}
                  hasLabels={true}
                  icon={cameraMarkerIcon}
                  labelProp="nickname"
                />
              </Map>
            </Card>
          </div>
        </div>

        <div className="w-full xl:w-1/2">
          <div className="pt-8 xl:pl-8 xl:pt-0">
            {!isReadyOnly && (
              <div className="mb-8">
                <Card title="Instructions" icon={<InfoIcon />}>
                  <div className="p-6">
                    <ul className="text-sm list-disc list-outside pl-4">
                      <li className="mb-1">Mouse click to select/unselect a single segment</li>
                      <li className="mb-1">Enter segment group name</li>
                      <li className="mb-1">Click on Add grouping / Update Grouping</li>
                      <li className="mb-1">
                        Camera placement is found on the Operator Dashboard, Camera Placement tab
                      </li>
                      <li className="mb-1">
                        Based on camera placement if train direction from right to left indicates Northbound than select
                        that camera as Right Camera
                      </li>
                      <li className="mb-1">
                        Based on camera placement if train direction from right to left indicates Southbound than select
                        that camera as Left Camera
                      </li>
                      <li className="mb-1">
                        Based on camera placement if train direction from right to left indicates Westbound than select
                        that camera as Left Camera
                      </li>
                      <li>
                        Based on camera placement if train direction from right to left indicates Eastbound than select
                        that camera as Right Camera
                      </li>
                    </ul>
                  </div>
                </Card>
              </div>
            )}

            {selectedSegmentGroup && !isReadyOnly && (
              <SegmentGroupForm
                cameras={cameras?.data}
                getCameraNameById={getCameraNameById}
                isLoading={isLoading}
                onClear={deselectSegmentGroup}
                onDelete={deleteSegmentGroup}
                onSubmit={saveSegmentGroup}
                segmentGroup={selectedSegmentGroup as SegmentGroup}
              />
            )}

            {!!segmentGroups?.data?.length && (
              <SegmentGroupsTable
                getCameraNameById={getCameraNameById}
                onRowSelect={selectSegmentGroupById}
                segmentGroups={segmentGroups.data}
                selectedSegmentGroupId={selectedSegmentGroup?.id as number}
              />
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

type SegmentGroupsTable = {
  segmentGroups: SegmentGroup[];
  getCameraNameById: Function;
  onRowSelect: Function;
  selectedSegmentGroupId: number;
};
const SegmentGroupsTable: FC<SegmentGroupsTable> = ({
  segmentGroups,
  getCameraNameById,
  onRowSelect,
  selectedSegmentGroupId,
}) => {
  const headers = useMemo(
    () => ({
      label: 'Label',
      leftCamera: 'Left camera',
      leftCameraDirection: 'Left camera direction',
      rightCamera: 'Right camera',
      rightCameraDirection: 'Right camera direction',
      segmentCount: 'Number of segments',
      owners: 'Owner(s)',
      subDiv: 'Sub Div',
      capacity: 'Max capacity',
      Tracks: 'Tracks',
      tcs: 'TCS',
    }),
    [],
  );

  const data = useMemo(() => {
    return segmentGroups.map((sg) => {
      return {
        id: sg.id,
        label: sg.label,
        leftCamera: getCameraNameById(sg.leftCameraId),
        leftCameraDirection: sg.leftCameraDirection || '-',
        rightCamera: getCameraNameById(sg.rightCameraId),
        rightCameraDirection: sg.rightCameraDirection || '-',
        segmentCount: sg.segments.length,
        owners: sg.owners,
        subDiv: sg.subDiv,
        capacity: sg.capacity,
        Tracks: sg.tracks,
        tcs: sg.tcs,
      };
    });
  }, [segmentGroups, getCameraNameById]);

  return (
    <div className="mb-8">
      <Card title="Segment clusters" icon={<SegmentIcon />}>
        <Table data={data} headers={headers} onRowSelect={onRowSelect} selectedEventId={selectedSegmentGroupId} />
      </Card>
    </div>
  );
};

type SegmentGroupForm = {
  cameras: Camera[];
  getCameraNameById: Function;
  isLoading: boolean;
  onClear: Function;
  onDelete: Function;
  onSubmit: Function;
  segmentGroup: SegmentGroup;
};
const SegmentGroupForm: FC<SegmentGroupForm> = ({
  cameras,
  getCameraNameById,
  isLoading,
  onClear,
  onDelete,
  onSubmit,
  segmentGroup,
}) => {
  const [id, setId] = useState<number | null>();
  const isExisting = !!id;

  const [tcs, setTcs] = useState<SelectOptionType>();
  const selectTcsFromSelect = useCallback((v) => setTcs(v), []);

  const [leftCamera, setLeftCamera] = useState<SelectOptionType>();
  const [rightCamera, setRightCamera] = useState<SelectOptionType>();

  const selectLeftCameraFromSelect = useCallback((camera) => setLeftCamera(camera), []);
  const selectRightCameraFromSelect = useCallback((camera) => setRightCamera(camera), []);

  const { values, fields, setValue } = useFormFields({
    label: '',
    owners: '',
    subDiv: '',
    tracks: '',
    capacity: '',
  });

  const cameraDirectionOptions = useMemo(
    () => [
      { value: 'left_to_right', label: 'left_to_right' },
      { value: 'right_to_left', label: 'right_to_left' },
    ],
    [],
  );

  const [leftCameraDirection, setLeftCameraDirection] = useState<SelectOptionType>();
  const onLeftCameraDirectionChange = useCallback((v) => setLeftCameraDirection(v), []);

  const [rightCameraDirection, setRightCameraDirection] = useState<SelectOptionType>();
  const onRightCameraDirectionChange = useCallback((v) => setRightCameraDirection(v), []);

  useEffect(() => {
    setId(segmentGroup.id);

    setValue('label', segmentGroup.label);
    setValue('owners', segmentGroup.owners);
    setValue('subDiv', segmentGroup.subDiv);
    setValue('tracks', String(segmentGroup.tracks));
    setValue('capacity', String(segmentGroup.capacity));

    setTcs({ value: segmentGroup.tcs, label: segmentGroup.tcs });
    setLeftCamera(
      segmentGroup.leftCameraId
        ? { value: segmentGroup.leftCameraId, label: getCameraNameById(segmentGroup.leftCameraId) }
        : undefined,
    );
    setRightCamera(
      segmentGroup.rightCameraId
        ? { value: segmentGroup.rightCameraId, label: getCameraNameById(segmentGroup.rightCameraId) }
        : undefined,
    );

    setLeftCameraDirection({ value: segmentGroup.leftCameraDirection, label: segmentGroup.leftCameraDirection });
    setRightCameraDirection({ value: segmentGroup.rightCameraDirection, label: segmentGroup.rightCameraDirection });
  }, [segmentGroup, id, setValue, getCameraNameById]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit({
        id,
        ...values,
        leftCameraDirection: leftCameraDirection?.value,
        leftCameraId: leftCamera?.value,
        rightCameraDirection: rightCameraDirection?.value,
        rightCameraId: rightCamera?.value,
        tcs: tcs?.value,
      });
    },
    [onSubmit, id, leftCamera, leftCameraDirection, rightCamera, rightCameraDirection, tcs, values],
  );

  const handleClear = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onClear();
    },
    [onClear],
  );

  const handleDelete = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onDelete(id);
    },
    [onDelete, id],
  );

  return (
    <div className="mb-8">
      <Card title={isExisting ? 'Segment cluster' : 'Add segment cluster'} icon={<SegmentIcon />}>
        <form className="text-sm" onSubmit={handleSubmit}>
          <div className="p-6">
            <div className="flex flex-wrap -mx-4">
              <fieldset className="w-1/3 px-4 mb-6">
                <label className="block mb-1">Segment group label</label>
                <input
                  type="text"
                  className="w-full placeholder-gray-400 border border-gray-400 p-3"
                  placeholder="Segment group label"
                  {...fields.label}
                />
              </fieldset>
              <fieldset className="w-1/3 px-4 mb-6">
                <label className="block mb-1">Left camera</label>
                <Select
                  onChange={selectLeftCameraFromSelect}
                  options={(cameras || []).map((camera: Camera) => ({ value: camera.id, label: camera.nickname }))}
                  placeholder="Select camera"
                  value={leftCamera}
                />
              </fieldset>
              <fieldset className="w-1/3 px-4 mb-6">
                <label className="block mb-1">Right camera</label>
                <Select
                  onChange={selectRightCameraFromSelect}
                  options={(cameras || []).map((camera: Camera) => ({ value: camera.id, label: camera.nickname }))}
                  placeholder="Select camera"
                  value={rightCamera}
                />
              </fieldset>
              <fieldset className="w-1/3 px-4 mb-6">
                <label className="block mb-1">Owner(s)</label>
                <input
                  type="text"
                  className="w-full placeholder-gray-400 border border-gray-400 p-3"
                  placeholder="Owner(s)"
                  {...fields.owners}
                />
              </fieldset>
              <fieldset className="w-1/3 px-4 mb-6">
                <label className="block mb-1">Left camera direction</label>
                <Select
                  options={cameraDirectionOptions}
                  value={leftCameraDirection}
                  onChange={onLeftCameraDirectionChange}
                />
              </fieldset>
              <fieldset className="w-1/3 px-4 mb-6">
                <label className="block mb-1">Right camera direction</label>
                <Select
                  options={cameraDirectionOptions}
                  value={rightCameraDirection}
                  onChange={onRightCameraDirectionChange}
                />
              </fieldset>
              <fieldset className="w-1/3 px-4 mb-6">
                <label className="block mb-1">Sub div</label>
                <input
                  type="text"
                  className="w-full placeholder-gray-400 border border-gray-400 p-3"
                  placeholder="SQUAMISH"
                  {...fields.subDiv}
                />
              </fieldset>
              <fieldset className="w-1/3 px-4 mb-6">
                <label className="block mb-1">Max capacity</label>
                <input
                  type="text"
                  className="w-full placeholder-gray-400 border border-gray-400 p-3"
                  placeholder="Max capacity"
                  {...fields.capacity}
                />
              </fieldset>
              <fieldset className="w-1/3 px-4 mb-6">
                <label className="block mb-1">Tracks</label>
                <input
                  type="text"
                  className="w-full placeholder-gray-400 border border-gray-400 p-3"
                  placeholder="Tracks"
                  {...fields.tracks}
                />
              </fieldset>
              <fieldset className="w-1/3 px-4 mb-6">
                <label className="block mb-1">TCS</label>
                <Select
                  onChange={selectTcsFromSelect}
                  options={['DCS', 'TWC', 'OCS', 'ABS', 'CTC', 'TCS'].map((value) => ({ value, label: value }))}
                  placeholder="Select TCS"
                  value={tcs}
                />
              </fieldset>
            </div>
          </div>
          <div className="flex justify-end bg-gray-100 border-t border-gray-300 p-4">
            <div className="mx-2">
              <Button fill="outline" color="gray-600" onClick={handleClear}>
                Clear
              </Button>
            </div>
            {isExisting && (
              <div className="mx-2">
                <Button fill="outline" color="red-700" onClick={handleDelete}>
                  Delete
                </Button>
              </div>
            )}
            <div className="mx-2">
              <input
                className="bg-blue-700 border border-blue-700 rounded text-white px-4 py-2 cursor-pointer disabled:bg-gray-500 disabled:border-gray-500 disabled:cursor-not-allowed"
                disabled={isLoading}
                type="submit"
                value={isLoading ? 'Please wait...' : `${isExisting ? 'Update' : 'Add'} grouping`}
              />
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};
