import firebase from 'firebase/app';
import 'firebase/auth';
import { CurrentUser, createUser } from './user.model';

export type LoginModel = { email: string; password: string };

export class Authenticator {
  constructor(private auth: firebase.auth.Auth) {}

  static configure(firebase: firebase.app.App) {
    return new Authenticator(firebase.auth());
  }

  public login = async ({
    email,
    password,
    rememberMe,
  }: LoginModel & { rememberMe: boolean }): Promise<firebase.User> => {
    await this.auth.setPersistence(
      rememberMe ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION,
    );
    const { user: authUser } = await this.auth.signInWithEmailAndPassword(email, password);
    if (!authUser) throw new Error('User not found');
    return authUser;
  };

  public signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    const { user: authUser } = await await this.auth.signInWithPopup(provider);

    if (!authUser) throw new Error('Sign-in with Google failed!');
    return authUser;
  };

  public resetPassword = async (email: string) => await this.auth.sendPasswordResetEmail(email);

  public logout = () => this.auth.signOut();

  public observeCurrentUser = (callback: (user: CurrentUser) => void) => {
    return this.auth.onAuthStateChanged(
      (user) => {
        if (!user) return callback({ isLoggedIn: false });

        return user.getIdTokenResult(true).then((idTokenResult) => {
          return callback(createUser(user, idTokenResult));
        });
      },
      (error) => callback({ authError: error }),
    );
  };
}
