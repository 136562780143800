import React, { FC, Fragment, useMemo, useCallback, ChangeEvent } from 'react';
import { Column, useTable, useExpanded, usePagination } from 'react-table';

import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-double-1.svg';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-button-left.svg';
import { ReactComponent as RightIcon } from 'assets/icons/arrow-button-right.svg';

type Table = {
  canExpand?: boolean;
  canPaginate?: boolean;
  data: any[];
  expandComponent?: Function;
  onOpenModal?: Function;
  hasTopBorder?: boolean;
  headers: { [key: string]: string };
  onRowSelect?: Function;
  selectedEventId?: string | number;
};
export const Table: FC<Table> = ({
  canExpand = false,
  canPaginate = false,
  data = [],
  expandComponent = () => {
    return;
  },
  hasTopBorder = false,
  headers,
  onRowSelect,
  selectedEventId,
  onOpenModal
}) => {
  const columns: Column<typeof data[0]>[] = useMemo(() => {
    const cols = [];
    for (const accessor in headers) {
      cols.push({ id: accessor, accessor, Header: headers[accessor] });
    }
    return cols;
  }, [headers]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageSize: canPaginate ? 10 : data.length } },
    useExpanded,
    usePagination,
  );

  const gotoPreviousPage = useCallback(() => previousPage(), [previousPage]);
  const gotoNextPage = useCallback(() => nextPage(), [nextPage]);
  const gotoSpecificPage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => gotoPage(e.target.value ? Number(e.target.value) - 1 : 0),
    [gotoPage],
  );

  const onClick = useCallback(
    (e) => {
      onRowSelect && onRowSelect(e.currentTarget.id);
    },
    [onRowSelect],
  );

  const clickProps = {
    onClick: onRowSelect ? onClick : undefined,
  };

  return (
    <>
      <div className="overflow-x-scroll">
        <table {...getTableProps()} className="table table-auto w-full">
          <thead>
            {headerGroups.map((headerGroup, headerIdx) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerIdx}
                className={(hasTopBorder ? 'border-t ' : '') + 'border-b'}
              >
                {headerGroup.headers.map((column, columnIdx) => (
                  <th {...column.getHeaderProps()} key={columnIdx} className="text-xs bg-gray-100 p-3">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, idx) => {
              prepareRow(row);
              const expandedProps = canExpand ? { ...row.getToggleRowExpandedProps() } : {};
              const id = String((row.original as { id: string | number }).id);
              const isSelected = id && id === String(selectedEventId);

              return (
                <Fragment key={`${idx}-${id}`}>
                  <tr
                    {...row.getRowProps()}
                    {...clickProps}
                    {...expandedProps}
                    key={`${idx}-${id}`}
                    id={String(id)}
                    className={
                      canExpand
                        ? 'bg-gray-100 bg-opacity-50 border-b cursor-pointer'
                        : `even:bg-gray-100 even:bg-opacity-50 border-b last:border-none ${
                            onRowSelect ? 'cursor-pointer' : 'cursor-default'
                          }`
                    }
                  >
                    {row.cells.map((cell, cellIdx) => {
                      return (
                        <td {...cell.getCellProps()} key={cellIdx} className="text-sm p-3 whitespace-no-wrap">
                          <div className="flex items-center">
                            {cell.render('Cell')}
                            {!cellIdx && isSelected ? (
                              <CheckCircleIcon className="text-green-700 w-4 ml-2" />
                            ) : (
                              <div className="w-4 h-4 ml-2"></div>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  {canExpand && row.isExpanded && (
                    <tr key={`expand-${id}`}>
                      <td colSpan={row.cells.length}>{expandComponent(row.original , onOpenModal)}</td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        {canPaginate && (canPreviousPage || canNextPage) && (
          <div className="flex items-center justify-between text-xs p-4">
            <div>
              Page
              <span className="font-medium ml-1">
                {pageIndex + 1} of {pageOptions.length}
              </span>
            </div>

            <div className="flex">
              <button
                disabled={!canPreviousPage}
                className="flex items-center disabled:opacity-50 disabled:cursor-not-allowed mx-3"
                onClick={gotoPreviousPage}
              >
                <LeftIcon className="w-3 mr-1" />
                Previous
              </button>
              <button
                disabled={!canNextPage}
                className="flex items-center disabled:opacity-50 disabled:cursor-not-allowed mx-3"
                onClick={gotoNextPage}
              >
                Next
                <RightIcon className="w-3 ml-1" />
              </button>
            </div>
            <div>
              Go to page
              <input
                type="text"
                className="bg-gray-100 w-12 text-center border-gray-200 py-1 px-2 ml-2"
                defaultValue={pageIndex + 1}
                onChange={gotoSpecificPage}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
