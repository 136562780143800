import React, { FC, useMemo } from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import chroma from 'chroma-js';
import Modal from 'react-modal';
import { SegmentTrainActivityCarType } from 'types';
import { useUser } from 'auth.provider';

import _groupBy from 'lodash/groupBy';

import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DatabaseSearchIcon } from 'assets/icons/database-search-alternate.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/information-circle.svg';
import { ReactComponent as MarkerIcon } from 'assets/icons/style-two-pin-marker.svg';
import { ReactComponent as NodeGroupIcon } from 'assets/icons/button-record-alternate.svg';
import { ReactComponent as SegmentIcon } from 'assets/icons/data-transfer-horizontal.svg';
import { ReactComponent as TrainIcon } from 'assets/icons/railroad-train.svg';

import CameraInactiveMarker from 'assets/images/markers/camera-inactive.svg';
import CameraMarker from 'assets/images/markers/camera.svg';

import { Card } from 'shared/card';
import { Table } from 'shared/table';

import { Map } from 'shared/maps/map';
import { Polylines } from 'shared/maps/polylines';
import { Markers } from 'shared/maps/markers';
import styles from './dashboard.module.css';

import { TCS_LIST } from 'constants/abbreviations';
import { DATEPICKER_FORMAT, DATETIME_FORMAT } from 'constants/formats';

// Types
import { NodeGroup, Segment, SegmentAverageData, SegmentTrainActivity } from 'types';

import { useDashboard } from './use-dashboard';
import { AppLayout } from '../app.layout';

const styleTop6 = { top: '6rem' };
const cameraInactiveMarkerIcon = { url: CameraInactiveMarker, anchor: { x: 22, y: 22 } };
const cameraMarkerIcon = { url: CameraMarker, anchor: { x: 22, y: 22 } };

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: '90%',
    padding: 0,
    overflow: 'hidden',
  },
};

export const DashboardPage = () => {
  const {
    date,
    getData,
    getPositionForNodeGroup,
    groupSegments,
    handleTodayDate,
    isLoading,
    isNodeGroupInactive,
    isSegmentImageSeriesLoading,
    nodeGroups,
    onDateChange,
    segmentImageSeries,
    segments,
    segmentsWithCamera,
    selectedNodeGroup,
    selectedSegment,
    selectNodeGroup,
    selectSegment,
    onOpenModal,
    onCloseModal,
    open,
    selectedSegmentTrainActivity,
    modalTrainDetailHeaderTier1,
    modalTrainDetailHeaderTier2,
    modalTrainDetailData,
  } = useDashboard();

  const user = useUser();

  return (
    <AppLayout name="Dashboard">
      <div className="xl:flex">
        <div className="w-full xl:w-5/12 relative">
          <div className="sticky" style={styleTop6}>
            <Card title="Map" icon={<MarkerIcon />}>
              {isLoading && (
                <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                  <Loading className="text-gray-500 w-16 mx-auto my-20" />
                </div>
              )}
              <Map onChange={getData}>
                <Polylines
                  groupSegments={groupSegments}
                  onClick={selectSegment}
                  onDblClick={selectSegment}
                  segments={segments?.data}
                  segmentsWithCamera={segmentsWithCamera}
                  // onMouseOverSeg={onMouseOverSeg}
                  // onMouseOutSeg={onMouseOutSeg}
                />
                <Markers
                  data={nodeGroups?.data}
                  getPosition={getPositionForNodeGroup}
                  hasLabels={true}
                  icon={cameraMarkerIcon}
                  iconWhenInactive={cameraInactiveMarkerIcon}
                  isInactive={isNodeGroupInactive}
                  labelProp="label"
                  onClick={selectNodeGroup}
                />
              </Map>
            </Card>
          </div>
        </div>

        {/* Details */}
        <div className="w-full xl:w-7/12">
          <div className="pt-8 xl:pl-8 xl:pt-0">
            {selectedNodeGroup && (
              <NodeGroupTable nodeGroup={selectedNodeGroup} isNodeGroupInactive={isNodeGroupInactive} />
            )}
            {selectedSegment && (
              <div>
                <SegmentTable segment={selectedSegment} averageData={segmentImageSeries?.avgData} />
                <div className="mb-8">
                  <Card title="Selected date" icon={<CalendarIcon />}>
                    <div className="flex items-center p-4">
                      <DatePicker selected={date} onChange={onDateChange} dateFormat={DATEPICKER_FORMAT} />
                      {!isLoading && (
                        <div className="flex items-center justify-between w-full text-gray-600 cursor-pointer p-4">
                          <div className="text-sm" onClick={handleTodayDate}>
                            Today
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
                <div className="mb-8">
                  <Card
                    title={selectedSegment.label ? `Train activity (${selectedSegment.label})` : 'Train activity'}
                    icon={<TrainIcon />}
                  >
                    {isSegmentImageSeriesLoading && (
                      <div className="p-8">
                        <Loading className="text-gray-500 w-10 mx-auto" />
                      </div>
                    )}
                    {!isSegmentImageSeriesLoading && (
                      <>
                        {!!segmentImageSeries?.data?.length && segmentImageSeries?.avgData && (
                          <SegmentAverageDataTable averageData={segmentImageSeries?.avgData} />
                        )}
                        {!!segmentImageSeries?.data?.length && (
                          <SegmentTrainActivityTable
                            trainActivityData={segmentImageSeries?.data}
                            onOpenModal={onOpenModal}
                          />
                        )}
                        {!segmentImageSeries?.data?.length && (
                          <div className="text-center text-gray-600 p-8">
                            <DatabaseSearchIcon className="w-6 mx-auto mb-2" />
                            <div className="text-xs font-medium">No activity</div>
                          </div>
                        )}
                      </>
                    )}
                  </Card>
                </div>
              </div>
            )}
            {!selectedSegment && (
              <div className="bg-blue-100 border border-blue-400 text-blue-600 p-6">
                <div className="flex items-center mb-6">
                  <InfoIcon className="w-5 mr-3" />
                  <div className="font-medium">Instructions</div>
                </div>
                <div className="text-sm">
                  <ul className="text-sm list-disc list-outside pl-4">
                    <li className="mb-3">
                      You can click and drag the map just like Google Maps to show different areas.
                    </li>
                    <li className="mb-3">
                      After you drag the map, a box with the instructions{' '}
                      <span className="italic">“Search this area”</span> will appear in the upper left corner. You will
                      need to click on that box for the map and cameras to appear. You can then click on the segment you
                      want to view.
                    </li>
                    <li className="mb-3">
                      You can also zoom the map out or in to view more detail in a smaller geographical area or less
                      detail and a larger geographical area. The map will refresh itself.
                    </li>
                    <li>
                      You can also click on the box in the upper right corner of the map to expand the map to the whole
                      page.
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal
          isOpen={open}
          // onAfterOpen={afterOpenModal}
          onRequestClose={onCloseModal}
          closeTimeoutMS={500}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className={`${styles.modalHeader}`}>
            <h2
              style={{ fontSize: '1.3em' }}
            >{`${selectedSegmentTrainActivity?.railway}-${selectedSegmentTrainActivity?.trainId}`}</h2>
            <button className={styles.modalCloseBtn} onClick={onCloseModal}>
              X
            </button>
          </div>
          <div className="grid grid-flow-row auto-rows-max p-4">
            <div className="flex">
              <span className={styles.modalInfoKey}>Train Info :</span>
              <p className={styles.modalInfoValue}> {selectedSegmentTrainActivity?.railway}</p>
            </div>
            <div className="flex">
              <span className={styles.modalInfoKey}>Train ID :</span>
              <p className={styles.modalInfoValue}> {selectedSegmentTrainActivity?.trainId}</p>
            </div>
            <div className="flex">
              <span className={styles.modalInfoKey}>Train Direction : </span>
              <p className={styles.modalInfoValue}> {selectedSegmentTrainActivity?.direction}</p>
            </div>
            <div className="flex">
              <span className={styles.modalInfoKey}>Segment Info :</span>
              <p className={styles.modalInfoValue}> {selectedSegment?.label}</p>
            </div>
          </div>

          <div style={{ padding: '0px 1em' }}>
            <Table
              data={modalTrainDetailData}
              headers={user.tier === '2' ? modalTrainDetailHeaderTier2 : modalTrainDetailHeaderTier1}
              selectedEventId={''}
            />
          </div>
        </Modal>
      </div>
    </AppLayout>
  );
};

Modal.setAppElement('body');

const NodeGroupTable: FC<{ nodeGroup: NodeGroup; isNodeGroupInactive: Function }> = ({
  nodeGroup,
  isNodeGroupInactive,
}) => {
  const headers = useMemo(
    () => ({
      label: 'Sensor name',
      owner: 'Serving railroad',
      status: 'Status',
    }),
    [],
  );
  const data = useMemo(
    () => [
      {
        id: nodeGroup.id,
        label: nodeGroup.label,
        owner: nodeGroup.owner,
        status: isNodeGroupInactive(nodeGroup) ? 'Inactive' : 'Active',
      },
    ],
    [nodeGroup, isNodeGroupInactive],
  );

  return (
    <div className="mb-8">
      <Card title="Sensor" icon={<NodeGroupIcon />}>
        <Table data={data} headers={headers} />
      </Card>
    </div>
  );
};

const SegmentTable: FC<{ segment: Segment; averageData: SegmentAverageData | undefined }> = ({
  segment,
  averageData,
}) => {
  const usage =
    averageData?.trainVolume && segment?.capacity
      ? `${Math.round((averageData.trainVolume * 100) / segment.capacity)}%`
      : null;

  const headers = useMemo(
    () => ({
      owners: 'Owner(s)',
      subDiv: 'SubDiv',
      tracks: 'Main tracks',
      tcs: 'Traffic Control System',
      capacity: 'Capacity (trains/day)',
      volume: 'Train volume',
      usage: 'Percentage of capacity',
    }),
    [],
  );

  // TODO: Move default values to backend
  const data = useMemo(
    () => [
      {
        id: segment.objectid,
        owners: segment.owners,
        subDiv: segment.subDiv,
        tracks: segment.tracks,
        tcs: segment.tcs,
        capacity: segment.capacity,
        volume: averageData?.trainVolume,
        usage: usage,
      },
    ],
    [segment, usage, averageData],
  );

  return (
    <div className="mb-8">
      <Card title={`Segment (${segment.label})`} icon={<SegmentIcon />}>
        <Table data={data} headers={headers} />
        <div className="flex justify-between text-gray-600 text-xs italic p-3 mt-2">
          <div>
            {data[0].tcs} = {TCS_LIST[data[0].tcs]}
          </div>
          <div>Train volume is for selected date</div>
        </div>
      </Card>
    </div>
  );
};

const SegmentAverageDataTable: FC<{ averageData: SegmentAverageData }> = ({ averageData }) => {
  const headers = useMemo(
    () => ({
      volume: 'Train volume for selected date',
      trainTypes: 'Count of train types',
    }),
    [],
  );
  const data = useMemo(
    () => [
      {
        volume: `${averageData.trainVolume} total train${averageData.trainVolume ? 's' : ''}`,
        trainTypes: (
          <ul className="list-disc list-inside">
            {averageData.trainTypes.map((trainType, idx) => (
              <li key={idx}>
                {trainType.name} ({trainType.value} trains)
              </li>
            ))}
          </ul>
        ),
      },
    ],
    [averageData],
  );

  return <Table data={data} headers={headers} />;
};

const getDataForCarTypes = (carTypes: SegmentTrainActivityCarType[]) => {
  const data = [];
  const groupedCarTypes = _groupBy(carTypes, (car) => {
    return car.carType;
  });
  for (const type in groupedCarTypes) {
    const value = groupedCarTypes[type].length;
    data.push({ name: `${value} ${type}`, value });
  }
  return data;
};

const getDataForContainers = (carTypes: SegmentTrainActivityCarType[]) => {
  const data = [];
  const groupedContainers: Record<string, any> = {};
  carTypes.length > 0 &&
    carTypes.map((car) => {
      if (car.containers) {
        car.containers.map((c) => {
          groupedContainers[c.type] = groupedContainers[c.type] || [];
          groupedContainers[c.type].push(c);
          return c;
        });
      }
      return car;
    });
  for (const type in groupedContainers) {
    const value = groupedContainers[type].length;
    data.push({ name: `${value} ${type}`, value });
  }
  return data;
};

const SegmentTrainActivityComponent = (ta: SegmentTrainActivity, onOpenModal: Function) => {
  const data = getDataForCarTypes(ta.carTypes);
  const containers = getDataForContainers(ta.carTypes);
  const COLORS = chroma.cubehelix().start(200).rotations(1).lightness([0.4, 0.8]).scale().colors(data.length);

  return (
    <div className="p-4 border-b">
      <div className="text-xs font-medium border-b pb-2 mb-2">
        Car types
        <button
          className={`${styles.detailBtn} float-right cursor-pointer`}
          onClick={() => {
            onOpenModal(ta);
          }}
        >
          {' '}
          More Details
        </button>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {data &&
          data.length > 0 &&
          data.map((v, i) => {
            return (
              <div key={i} className="flex h-10 mt-4 ml-9">
                <div
                  style={{
                    marginTop: '3px',
                    width: '20px',
                    height: '20px',
                    background: `${COLORS[i % COLORS.length]}`,
                    borderRadius: '20px',
                  }}
                ></div>
                <p style={{ marginLeft: '0.5em' }}>{v.name}</p>
              </div>
            );
          })}
      </div>
      {containers && containers.length > 0 && (
        <>
          <div className="text-xs font-medium border-b pb-2 mb-2">Containers</div>
          <div className="grid grid-cols-3 gap-4">
            {containers.map((v, i) => {
              return (
                <div key={i} className="flex h-10 mt-4 ml-9">
                  <div
                    style={{
                      marginTop: '3px',
                      width: '20px',
                      height: '20px',
                      background: `${COLORS[i % COLORS.length]}`,
                      borderRadius: '20px',
                    }}
                  ></div>
                  <p style={{ marginLeft: '0.5em' }}>{v.name}</p>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const SegmentTrainActivityTable: FC<{ trainActivityData: SegmentTrainActivity[]; onOpenModal: Function }> = ({
  trainActivityData,
  onOpenModal,
}) => {
  const headers = useMemo(
    () => ({
      datetime: 'Date / time',
      trainId: 'Train ID',
      railway: 'Railway',
      direction: 'Direction',
      locomotives: 'Locomotives',
      cars: 'Cars',
      trainType: 'Train type',
      velocity: 'Velocity (MPH)',
    }),
    [],
  );

  const data = useMemo(() => {
    return trainActivityData.map((ta, idx) => {
      return {
        id: idx,
        datetime: moment(ta.time * 1000).format(DATETIME_FORMAT),
        railway: ta.railway,
        direction: ta.direction,
        locomotives: ta.locomotivesCount,
        cars: ta.cars,
        trainType: ta.trainType,
        velocity: ta.speed,
        carTypes: ta.carTypes,
        trainId: ta.trainId,
      };
    });
  }, [trainActivityData]);

  return (
    <Table
      data={data}
      headers={headers}
      canPaginate={true}
      canExpand={true}
      expandComponent={SegmentTrainActivityComponent}
      onOpenModal={onOpenModal}
    />
  );
};
