import React, { FC, useState, useCallback, useEffect } from 'react';

type Checkbox = { uid: string; label: string; value: boolean; onChange: Function; isReadyOnly?: boolean };
export const Checkbox: FC<Checkbox> = ({ uid, label, value, onChange, isReadyOnly = false }) => {
  const [val, setVal] = useState(value);
  const handleChange = useCallback(() => {
    if (isReadyOnly) return;

    const newVal = !val;
    setVal(newVal);
    onChange(uid, newVal);
  }, [isReadyOnly, onChange, uid, val]);

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <label className="flex items-center cursor-pointer mb-6">
      <input
        checked={val}
        className="form-checkbox text-blue-700"
        onChange={handleChange}
        readOnly={isReadyOnly}
        type="checkbox"
      />
      <span className="ml-3">{label}</span>
    </label>
  );
};
