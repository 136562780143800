import React, { FC, useCallback } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthRoutes, AppRoutes, DefaultRoute } from 'constants/routes';
import { useUser } from 'auth.provider';

type AuthRoute = RouteProps & { requiredRoles: string[] };
export const AuthRoute: FC<AuthRoute> = ({ component: RouteComponent, requiredRoles, ...rest }) => {
  const user = useUser();
  const isAuthorized = requiredRoles.includes(user.role);
  const isAuthorizedForDashboard = AppRoutes.dashboard.roles.includes(user.role);

  const render = useCallback(
    (routeProps) => {
      if (!RouteComponent) return null;

      return user.isLoggedIn && isAuthorized ? (
        <RouteComponent {...routeProps} />
      ) : (
        <Redirect
          to={
            user.isLoggedIn
              ? isAuthorizedForDashboard
                ? AppRoutes.dashboard.path
                : DefaultRoute.path
              : AuthRoutes.login.path
          }
        />
      );
    },
    [isAuthorized, isAuthorizedForDashboard, user.isLoggedIn, RouteComponent],
  );

  return <Route exact {...rest} render={render} />;
};
