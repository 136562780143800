import React, { FC, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { ReactComponent as DatabaseIcon } from 'assets/icons/database-1-alternate.svg';

import { ReactComponent as InfoIcon } from 'assets/icons/information-circle.svg';
import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as MarkerIcon } from 'assets/icons/style-two-pin-marker.svg';
import { ReactComponent as StatusIcon } from 'assets/icons/information-circle.svg';
import CameraMarker from 'assets/images/markers/camera.svg';
import NewCameraMarker from 'assets/images/markers/camera-new.svg';

import _ from 'lodash';

import { Card } from 'shared/card';
import { Select, SelectOptionType } from 'shared/select';

import { Map } from 'shared/maps/map';
import { Polylines } from 'shared/maps/polylines';
import { Markers } from 'shared/maps/markers';

import { CameraPlacementForm } from './camera-placement-form';
import { CameraList } from './camera-list';
import { StateUpdatesList, ChangeState } from './camera-status';

// Types
import { CallbackFn, Camera } from 'types';

const cameraMarkerIcon = { url: CameraMarker, anchor: { x: 22, y: 22 } };
const newCameraMarkerIcon = { url: NewCameraMarker, anchor: { x: 22, y: 22 } };
const mapOptions = { draggableCursor: 'cell', disableDoubleClickZoom: true };
const styleTopRemove = { marginTop: '-0.8rem' };

type CameraPlacement = {
  cameras: any;
  devices: any;
  deleteCamera: Function;
  deviceStates: any | null;
  getData: CallbackFn;
  getPositionForCamera: Function;
  humanize: Function;
  isLoading: boolean;
  isReadyOnly: boolean;
  isSegmentsLoading: boolean;
  isUpdateCameraLoading: boolean;
  newCameraList: Camera[];
  onMapDblClick: CallbackFn;
  onStateSubmit: Function;
  regions: any;
  saveCamera: Function;
  sensorStates: any | null;
  segments: any;
  selectCamera: (camera: Camera | null) => void;
  selectedCamera: Camera | null | undefined;
  selectedCameraForSelect: (SelectOptionType & { camera: Camera }) | undefined;
  sensorDetail: any;
  timezones: any;
};

export const CameraPlacement: FC<CameraPlacement> = ({
  cameras,
  devices,
  deleteCamera,
  deviceStates,
  getData,
  getPositionForCamera,
  humanize,
  isLoading,
  isReadyOnly,
  isSegmentsLoading,
  isUpdateCameraLoading,
  newCameraList,
  onMapDblClick,
  onStateSubmit,
  regions,
  saveCamera,
  segments,
  selectCamera,
  selectedCamera,
  selectedCameraForSelect,
  sensorDetail,
  sensorStates,
  timezones,
}) => {
  const orderedCameras = _.sortBy(cameras?.data, (o) => o.nickname);
  const selectCameraFromSelect = useCallback(({ camera }) => selectCamera(camera), [selectCamera]);

  const [checkedPolylines, setCheckedPolylines] = React.useState(
    (localStorage?.checkedPolylines && localStorage.checkedPolylines === 'false') || true,
  );
  const handleSetCheckedPolylines = useCallback(() => {
    localStorage.checkedPolylines = !checkedPolylines;
    setCheckedPolylines(!checkedPolylines);
  }, [checkedPolylines]);

  const cameraListTitle = `Sites (${cameras?.data?.length || 0})`;
  const stateUpdatesTitle = `History of state updates (${sensorDetail?.stateUpdates.length || 0})`;

  return (
    <div className="xl:flex">
      <div className="w-full xl:w-1/2 relative camera-list-map">
        <Tabs defaultIndex={0} forceRenderTabPanel={true}>
          <TabList>
            <Tab>Sites on the map</Tab>
            <Tab>List of sites</Tab>
          </TabList>
          {/* Map */}
          <TabPanel>
            <div className="w-full relative" style={styleTopRemove}>
              <div className="sticky">
                <Card title="Map" icon={<MarkerIcon />}>
                  {isSegmentsLoading && (
                    <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                      <Loading className="text-gray-500 w-16 mx-auto my-20" />
                    </div>
                  )}
                  <Map onChange={getData} onDblClick={onMapDblClick} options={mapOptions}>
                    <Polylines segments={checkedPolylines ? segments?.data : null} />
                    <Markers
                      data={cameras?.data}
                      getPosition={getPositionForCamera}
                      hasLabels={true}
                      icon={cameraMarkerIcon}
                      labelProp="nickname"
                      onClick={selectCamera}
                    />
                    {newCameraList.length && (
                      <Markers
                        data={newCameraList}
                        getPosition={getPositionForCamera}
                        icon={newCameraMarkerIcon}
                        onClick={selectCamera}
                      />
                    )}
                  </Map>
                  <p className="p-3 text-sm">
                    <label htmlFor="checkedPolylines">Display railroad tracks</label>
                    <input
                      checked={checkedPolylines}
                      className="form-checkbox text-blue-700 ml-3 cursor-pointer"
                      id="checkedPolylines"
                      onChange={handleSetCheckedPolylines}
                      type="checkbox"
                    />
                  </p>
                </Card>
              </div>
            </div>
          </TabPanel>
          {/* List */}
          <TabPanel>
            <div className="w-full relative" style={styleTopRemove}>
              <Card title={cameraListTitle} icon={<DatabaseIcon />}>
                {isLoading && (
                  <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                    <Loading className="text-gray-500 w-16 mx-auto my-20" />
                  </div>
                )}
                <CameraList
                  cameras={cameras}
                  getCameraStatusLabel={humanize}
                  orderedCameras={orderedCameras}
                  selectCamera={selectCamera}
                  regions={regions}
                />
              </Card>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      {/* Details */}
      <div className="w-full xl:w-1/2">
        <div className="pt-8 xl:pl-8 xl:pt-0">
          <div className="mb-8 text-sm">
            <Select
              isDisabled={!cameras?.data || !cameras?.data?.length}
              onChange={selectCameraFromSelect}
              options={(cameras?.data && !!cameras?.data?.length ? orderedCameras : []).map((camera: Camera) => ({
                camera,
                value: camera.id,
                label: `${camera.nickname} (${camera.id})`,
              }))}
              placeholder="Select site"
              value={selectedCameraForSelect}
            />
          </div>
          {!selectedCamera && (
            <div className="bg-blue-100 border border-blue-400 text-blue-600 p-6">
              <div className="flex items-center mb-4">
                <InfoIcon className="w-5 mr-3" />
                <div className="font-medium">Instructions</div>
              </div>
              <div className="text-sm">
                <div>Please select site to proceed to site configuration or event images tab.</div>
                <br />
                <div>Click (double) on a location on the map to add a new site.</div>
              </div>
            </div>
          )}

          {!!selectedCamera && (
            <>
              {(isUpdateCameraLoading || isLoading) && (
                <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                  <Loading className="text-gray-500 w-16 mx-auto my-20" />
                </div>
              )}
              <CameraPlacementForm
                camera={selectedCamera}
                getCameraStatusLabel={humanize}
                isLoading={isUpdateCameraLoading || isLoading}
                isReadyOnly={isReadyOnly}
                onDelete={deleteCamera}
                onSubmit={saveCamera}
                regions={regions}
                timezones={timezones}
              />
            </>
          )}

          {!!selectedCamera && (
            <div className="w-full relative mt-6">
              {(isUpdateCameraLoading || isLoading) && (
                <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                  <Loading className="text-gray-500 w-16 mx-auto my-20" />
                </div>
              )}
              <Card icon={<StatusIcon />} title={stateUpdatesTitle}>
                <StateUpdatesList
                  getCameraStatusLabel={humanize}
                  isLoading={isUpdateCameraLoading || isLoading}
                  selectedDevice={null}
                  sensorDetail={sensorDetail}
                />
              </Card>
            </div>
          )}

          {!!selectedCamera && !!selectedCamera?.id && (
            <ChangeState
              devices={devices}
              deviceStates={deviceStates}
              humanize={humanize}
              isLoading={isLoading}
              isReadyOnly={isReadyOnly}
              onStateSubmit={onStateSubmit}
              selectedCamera={selectedCamera}
              sensorDetail={sensorDetail}
              sensorStates={sensorStates}
            />
          )}
        </div>
      </div>
    </div>
  );
};
