import React, { FC, useCallback, useState, useMemo } from 'react';

// Types
import { Camera } from 'types';

export const CameraItem: FC<{
  selectCamera: (camera: Camera | null) => void;
  camera: Camera;
  getCameraStatusLabel: Function;
  regions: any;
}> = ({ selectCamera, camera, getCameraStatusLabel, regions }) => {
  const handleSelect = useCallback(() => selectCamera(camera), [selectCamera, camera]);
  const [hoverStyle, setHoverStyle] = useState('');

  const eventHandlers = useMemo(
    () => ({
      onClick() {
        handleSelect();
        window.scrollTo(0, 0);
      },
      onMouseOver() {
        setHoverStyle(' cursor-pointer text-blue-700 ');
      },
      onMouseOut() {
        setHoverStyle('');
      },
    }),
    [handleSelect],
  );
  const provinceId = !!camera?.administrativeRegion?.id ? Number(camera?.administrativeRegion?.id) : null;
  const provinceItem =
    provinceId && regions && regions?.administrativeRegions
      ? regions?.administrativeRegions.find((v: { id: any }) => Number(v.id) === provinceId)
      : {};

  return (
    <tr
      className={'text-sm border-b even:bg-gray-100 even:bg-opacity-50 last:border-none' + hoverStyle}
      key={camera.id}
      {...eventHandlers}
    >
      <td className="p-4 whitespace-no-wrap">{camera.id}</td>
      <td className="p-4 whitespace-no-wrap">{camera?.nickname || ''}</td>
      <td className="p-4 whitespace-no-wrap">
        {provinceItem?.country || ''}
        {!!provinceItem?.state && <br />}
        {provinceItem?.state || ''}
      </td>
      <td className="p-4 whitespace-no-wrap">
        {!!camera?.status ? getCameraStatusLabel(camera?.status.toLowerCase()) : ''}
      </td>
      <td className="p-4 whitespace-no-wrap">
        <input
          className="form-checkbox text-blue-700"
          defaultChecked={!!camera?.inProduction || false}
          disabled={true}
          type="checkbox"
        />
      </td>
      <td className="p-4 whitespace-no-wrap">
        <div className="flex items-center justify-end">
          <div className="flex items-center text-blue-700 cursor-pointer mr-6">
            <span className="mt-1">Select</span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export const CameraList: FC<{
  cameras: { [key: string]: any | null };
  orderedCameras: { [key: string]: any | null };
  selectCamera: (camera: Camera | null) => void;
  getCameraStatusLabel: Function;
  regions: any;
}> = ({ cameras, orderedCameras, selectCamera, getCameraStatusLabel, regions }) => (
  <div className="overflow-x-scroll">
    <table className="table table-auto w-full">
      <thead>
        <tr className="bg-gray-100 border-b text-xs">
          <th className="p-4">ID</th>
          <th className="p-4">Station name</th>
          <th className="p-4">Country and region</th>
          <th className="p-4">Legacy status</th>
          <th className="p-4">In production</th>
          <th className="p-4">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {(!!cameras?.data?.length ? orderedCameras : []).map((camera: Camera, index: React.Key) => (
          <CameraItem
            key={index}
            selectCamera={selectCamera}
            camera={camera}
            getCameraStatusLabel={getCameraStatusLabel}
            regions={regions}
          />
        ))}
      </tbody>
    </table>
  </div>
);
