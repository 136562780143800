import React, { FC, useCallback } from 'react';
import { useToasts, AddToast } from 'react-toast-notifications';
import useClipboard from 'react-use-clipboard';
import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/close.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pencil-1.svg';
import { ReactComponent as DatabaseIcon } from 'assets/icons/database-1-alternate.svg';
import { Card } from 'shared/card';
import { Customer } from 'types';
import { useCustomers } from './use-customers';
import { CustomerForm } from './customer-form';
import { AppLayout } from '../app.layout';

export const CustomersPage = () => {
  const {
    customers,
    deleteCustomer,
    deselectCustomer,
    getCustomersCb,
    isError,
    isLoading,
    saveCustomer,
    selectCustomer,
    selectedCustomer,
  } = useCustomers();
  const { addToast } = useToasts();

  return (
    <AppLayout name="Customers (API)">
      <div className="container max-w-5xl mx-auto px-4">
        <div className="mb-8">
          <CustomerForm
            customer={selectedCustomer}
            onSubmit={saveCustomer}
            onDelete={deleteCustomer}
            onClear={deselectCustomer}
            isLoading={isLoading}
          />
        </div>
        <Card title="Customers" icon={<DatabaseIcon />}>
          {isLoading && <Loading className="text-gray-500 w-16 my-20 mx-auto" />}
          {!isLoading && !!customers?.length && (
            <div className="overflow-x-scroll">
              <table className="table table-auto w-full">
                <thead>
                  <tr className="bg-gray-100 border-b text-xs">
                    <th className="p-4">Name</th>
                    <th className="p-4">Tier</th>
                    <th className="p-4">Email</th>
                    <th className="p-4">Token</th>
                    <th className="p-4"></th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer: Customer) => (
                    <CustomerRow
                      addToast={addToast}
                      customer={customer}
                      deleteCustomer={deleteCustomer}
                      selectCustomer={selectCustomer}
                      key={customer.id}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {!isLoading && !customers?.length && !isError && <div className="text-center p-10">No customers found.</div>}
          {!isLoading && isError && (
            <div className="text-center p-10">
              <div className="mb-4">Something went wrong! Please try again.</div>
              <span className="text-blue-700 border-b cursor-pointer" onClick={getCustomersCb}>
                Try again
              </span>
            </div>
          )}
        </Card>
      </div>
    </AppLayout>
  );
};

type CustomerRow = { addToast: AddToast; customer: Customer; selectCustomer: Function; deleteCustomer: Function };
const CustomerRow: FC<CustomerRow> = ({ addToast, customer, selectCustomer, deleteCustomer }) => {
  const handleSelect = useCallback(() => selectCustomer(customer), [selectCustomer, customer]);
  const handleDelete = useCallback(() => deleteCustomer(customer.id), [deleteCustomer, customer.id]);

  const [, setCopied] = useClipboard(customer.publicKey);

  const handleCopy = useCallback(() => {
    setCopied();
    addToast('Token copied to clipboard.', { appearance: 'success', autoDismiss: true });
  }, [setCopied, addToast]);

  return (
    <tr className="text-sm even:bg-gray-100 even:bg-opacity-50 border-b last:border-none" key={customer.id}>
      <td className="p-4 whitespace-no-wrap">{customer.name || '-'}</td>
      <td className="p-4 whitespace-no-wrap">{customer && customer.tier === "2" ? customer.tier : "1"}</td>
      <td className="p-4 whitespace-no-wrap">{customer.email}</td>
      <td className="p-4 whitespace-no-wrap">
        <span className="text-xs text-blue-700 border-b cursor-pointer" onClick={handleCopy}>
          Copy to clipboard
        </span>
      </td>
      <td className="p-4 whitespace-no-wrap">
        <div className="flex items-center justify-end">
          <div className="flex items-center text-blue-700 cursor-pointer mr-6" onClick={handleSelect}>
            <EditIcon className="w-3 mr-2" />
            <span className="mt-1">Edit</span>
          </div>
          <div className="flex items-center text-red-800 cursor-pointer" onClick={handleDelete}>
            <DeleteIcon className="w-3 mr-2" />
            <span className="mt-1">Delete</span>
          </div>
        </div>
      </td>
    </tr>
  );
};
