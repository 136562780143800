import { Roles } from 'constants/roles';

import { LoginPage } from 'pages/auth/login.page';
import { ResetPasswordPage } from 'pages/auth/reset-password.page';

import { CameraSnapshotsPage } from 'pages/app/camera-snapshots/camera-snapshots.page';
import { CustomersPage } from 'pages/app/customers/customers.page';
import { DashboardPage } from 'pages/app/dashboard/dashboard.page';
import { DeviceConfigurationPage } from 'pages/app/device-configuration/device-configuration.page';
import { DeviceDashboardPage } from 'pages/app/device-dashboard/device-dashboard.page';
import { HelpPage } from 'pages/app/help/help.page';
import { LegacyImagesPage } from 'pages/app/legacy-images/legacy-images.page';
import { NetworkStatusPage } from 'pages/app/network-status/network-status.page';
import { NodeClustersPage } from 'pages/app/node-clusters/node-clusters.page';
import { OperatorDashboardPage } from 'pages/app/operator-dashboard/operator-dashboard.page';
import { SegmentClustersPage } from 'pages/app/segment-clusters/segment-clusters.page';
import { UniqueTrainsDashboardPage } from 'pages/app/unique-trains-dashboard/unique-trains-dashboard.page';
import { UsersPage } from 'pages/app/users/users.page';

import { DefaultPage } from 'pages/app/default.page';

type Routes = { [key: string]: { path: string; name: string; component: () => JSX.Element } };
export const AuthRoutes: Routes = {
  login: {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  resetPassword: {
    path: '/reset-password',
    name: 'Reset password',
    component: ResetPasswordPage,
  },
};

export const DefaultRoute = {
  path: '/',
  name: 'Welcome to RailState',
  component: DefaultPage,
  roles: [
    Roles.admin,
    Roles.customer,
    Roles.imageTagger,
    Roles.manager,
    Roles.operator,
    Roles.owner,
    Roles.readOnly,
    Roles.sme,
    Roles.technician,
    Roles.thirdPartyAuditor,
  ],
};

type AppRoutes = {
  [key: string]: {
    path: string;
    name: string;
    roles: string[];
    component: () => JSX.Element;
    hide?: boolean;
  };
};
export const AppRoutes: AppRoutes = {
  dashboard: {
    path: '/dashboard',
    name: 'Dashboard',
    roles: [
      Roles.admin,
      Roles.customer,
      Roles.operator,
      Roles.owner,
      Roles.readOnly,
      Roles.sme,
      Roles.thirdPartyAuditor,
    ],
    component: DashboardPage,
  },
  operatorDashboardSiteID: {
    path: '/operator-dashboard/:siteID',
    name: 'Site dashboard',
    roles: [Roles.admin, Roles.operator, Roles.owner, Roles.readOnly, Roles.thirdPartyAuditor],
    component: OperatorDashboardPage,
    hide: true,
  },
  operatorDashboard: {
    path: '/operator-dashboard',
    name: 'Site dashboard',
    roles: [Roles.admin, Roles.operator, Roles.owner, Roles.readOnly, Roles.thirdPartyAuditor],
    component: OperatorDashboardPage,
  },
  deviceDashboardDeviceId: {
    path: '/device-dashboard/:deviceID',
    name: 'Device dashboard',
    roles: [Roles.admin, Roles.operator, Roles.owner, Roles.readOnly, Roles.thirdPartyAuditor],
    component: DeviceDashboardPage,
    hide: true,
  },
  deviceDashboard: {
    path: '/device-dashboard',
    name: 'Device dashboard',
    roles: [Roles.admin, Roles.operator, Roles.owner, Roles.readOnly, Roles.thirdPartyAuditor],
    component: DeviceDashboardPage,
  },
  uniqueTrainsDashboard: {
    path: '/unique-trains-dashboard',
    name: 'Unique trains dashboard',
    roles: [Roles.admin, Roles.operator, Roles.owner, Roles.readOnly, Roles.thirdPartyAuditor],
    component: UniqueTrainsDashboardPage,
  },
  cameraSnapshots: {
    path: '/camera-snapshots',
    name: 'Camera daily snapshots',
    roles: [Roles.admin, Roles.operator, Roles.owner, Roles.readOnly, Roles.thirdPartyAuditor],
    component: CameraSnapshotsPage,
  },
  deviceConfiguration: {
    path: '/device-configuration',
    name: 'Device configuration',
    roles: [Roles.admin, Roles.operator, Roles.technician],
    component: DeviceConfigurationPage,
  },
  segmentClusters: {
    path: '/segment-clusters',
    name: 'Segment clusters',
    roles: [Roles.admin, Roles.sme, Roles.owner, Roles.readOnly],
    component: SegmentClustersPage,
  },
  nodeClusters: {
    path: '/node-clusters',
    name: 'Node clusters',
    roles: [Roles.admin, Roles.sme, Roles.owner, Roles.readOnly],
    component: NodeClustersPage,
  },
  legacyImages: {
    path: '/legacy-images',
    name: 'Legacy images',
    roles: [Roles.admin, Roles.owner, Roles.readOnly],
    component: LegacyImagesPage,
  },
  users: {
    path: '/users',
    name: 'Users',
    roles: [Roles.admin],
    component: UsersPage,
  },
  customers: {
    path: '/customers',
    name: 'Customers',
    roles: [Roles.admin],
    component: CustomersPage,
  },
  networkStatus: {
    path: '/network-status',
    name: 'Network status',
    roles: [Roles.admin, Roles.operator, Roles.owner, Roles.readOnly, Roles.thirdPartyAuditor],
    component: NetworkStatusPage,
  },
  help: {
    path: '/help',
    name: 'Help',
    roles: [
      Roles.admin,
      Roles.customer,
      Roles.imageTagger,
      Roles.manager,
      Roles.operator,
      Roles.owner,
      Roles.readOnly,
      Roles.sme,
      Roles.technician,
    ],
    component: HelpPage,
  },
};
