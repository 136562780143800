import { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useApi } from 'hooks/use-api';
import { User } from 'types';
import { Notify } from 'services/notify';

export const useUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const newUser = Object.assign({
    customClaims: { role: '' },
    email: '',
    password: '',
    providerData: [{ displayName: '' }],
  });
  const [selectedUser, setSelectedUser] = useState<User>(newUser);

  const [{ data: users, loading: isUsersLoading, error: isError }, getUsers] = useApi({ url: '/users', manual: false });
  const [{ loading: isUpdateUserLoading }, updateUser] = useApi({ url: '/users' });
  const { addToast } = useToasts();

  const getUsersCb = useCallback(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    const yes = isUsersLoading || isUpdateUserLoading;
    setIsLoading(yes);
  }, [isUsersLoading, isUpdateUserLoading]);

  const selectUser = useCallback((user) => setSelectedUser(user), [setSelectedUser]);
  const deselectUser = useCallback(() => setSelectedUser(newUser), [newUser]);

  const saveUser = (user: Partial<User>) => {
    const isExisting = !!user.uid;
    const url = '/users' + (isExisting ? `/${user.uid}` : '');
    const method = isExisting ? 'PATCH' : 'POST';

    updateUser({ url, method, data: user })
      .then(() => getUsers())
      .then(() => {
        setSelectedUser(newUser);
        addToast('User saved successfully', { appearance: 'success', autoDismiss: true });
      })
      .catch(() => {
        addToast('Failed to save user.', { appearance: 'error', autoDismiss: true });
      });
  };

  const deleteUser = (userId: string) => {
    Notify.confirm({
      title: 'Delete user',
      text: 'Are you sure you want to proceed?',
      isDestructive: true,
    }).then(({ isDismissed }) => {
      if (isDismissed) return;
      updateUser({ url: `/users/${userId}`, method: 'DELETE' })
        .then(() => getUsers())
        .then(() => {
          setSelectedUser(newUser);
          addToast('User deleted successfully', { appearance: 'success', autoDismiss: true });
        })
        .catch(() => {
          addToast('Failed to delete user.', { appearance: 'error', autoDismiss: true });
        });
    });
  };

  return {
    deleteUser,
    deselectUser,
    getUsersCb,
    isError,
    isLoading,
    saveUser,
    selectedUser,
    selectUser,
    users,
  };
};
