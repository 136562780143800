import React, { FC, useCallback, useMemo } from 'react';

import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as CogIcon } from 'assets/icons/cog-double-3.svg';
import { ReactComponent as DayIcon } from 'assets/icons/weather-sun.svg';
import { ReactComponent as NightIcon } from 'assets/icons/weather-night-clear.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/app-window-warning.svg';

import { Button } from 'shared/button';
import { Card } from 'shared/card';
import { Checkbox } from 'shared/checkbox';
import { NumberInput } from 'shared/number-input';
import { BooleanInput } from 'shared/boolean-input';
import { Select } from 'shared/select';

import { Camera } from 'types';
import { CamProp, CamProps } from 'types/cam-props';
import { useDeviceConfiguration } from './use-device-configuration';
import { CameraStream } from './camera-stream';
import { enumCompare, enumRunning } from 'utils/enumCompare';

const styleTop6 = { top: '6rem' };

export const DeviceConfiguration = () => {
  const {
    allowEdit,
    cameras,
    camProps,
    devices,
    handleAllowEdit,
    isLoading,
    loadConfigForDay,
    loadConfigForNight,
    onCamPropChange,
    onMotorMovementDecrement,
    onMotorMovementIncrement,
    onMotorMovementIrCutOff,
    onStepSizeChange,
    psoc,
    saveConfigForDay,
    saveConfigForNight,
    selectedNodeForSelect,
    selectNode,
    startStream,
    stopStream,
  } = useDeviceConfiguration();

  const isCameras = cameras && cameras?.sensors && !!cameras?.sensors?.length;
  const devicesSorting = (d1: { deviceId: any }, d2: { deviceId: any }) => {
    const id1 = d1?.deviceId ?? '';
    const id2 = d2?.deviceId ?? '';
    if (id1 < id2) {
      return -1;
    }
    if (id1 > id2) {
      return 1;
    }
    return 0;
  };

  return (
    <div className="xl:flex">
      <div className="w-full xl:w-2/5 relative">
        <div className="sticky" style={styleTop6}>
          <CameraStream
            selectedNodeForSelect={selectedNodeForSelect}
            startStream={startStream}
            stopStream={stopStream}
          />
        </div>
      </div>
      <div className="w-full xl:w-3/5">
        <div className="pt-8 xl:pl-8 xl:pt-0">
          <div className="md:flex items-center justify-between mb-6">
            <div className="w-full md:w-2/3 mb-6 md:mb-0">
              <Select
                isDisabled={!cameras?.sensors?.length}
                onChange={selectNode}
                options={(!!devices?.devices?.length ? devices.devices : []).sort(devicesSorting).map((device: any) => {
                  const camera =
                    isCameras &&
                    !!device?.lastSensorId &&
                    cameras?.sensors.find((item: { id: any }) => Number(device.lastSensorId) === Number(item.id));

                  const value = device?.deviceId;
                  const label = `${value} (${
                    !camera ? '- no site -' : camera?.parameters?.nickname + ' - ' + camera?.id
                  })`;
                  const inProduction =
                    camera &&
                    !!camera?.parameters?.inProduction &&
                    !!camera?.lastState &&
                    enumCompare(camera?.lastState, enumRunning);
                  const isDisabled = inProduction && !allowEdit;

                  return {
                    value,
                    label,
                    isDisabled,
                    inProduction,
                  };
                })}
                placeholder="Select device"
                value={selectedNodeForSelect}
              />
              <p className="text-gray-500">
                <br />
                <em>
                  Device selection is disabled if the site is &quot;In production&quot; and in &quot;{enumRunning}
                  &quot; state. Allow editing of all devices:
                </em>
                <span className="inline-block ml-3 inline-checkbox">
                  <Checkbox value={allowEdit as boolean} onChange={handleAllowEdit} uid={'allowEdit'} label={''} />
                </span>
              </p>
            </div>
            {selectedNodeForSelect && camProps && (
              <div className="w-full md:w-2/3 flex justify-end">
                <div>
                  <Button icon={<DayIcon />} fill="outline" color="blue-700" onClick={loadConfigForDay}>
                    Load day config
                  </Button>
                </div>
                <div className="ml-3">
                  <Button icon={<NightIcon />} fill="outline" color="blue-700" onClick={loadConfigForNight}>
                    Load night config
                  </Button>
                </div>
              </div>
            )}
          </div>

          {allowEdit && selectedNodeForSelect?.inProduction && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-1" role="alert">
              <strong className="font-bold">Beware!</strong>
              <span className="block sm:inline ml-2">
                This site/device site is &quot;In production&quot; and in &quot;{enumRunning}&quot; state.
              </span>
            </div>
          )}

          {selectedNodeForSelect && (
            <Card title="Configuration" icon={<CogIcon />}>
              {!camProps && isLoading && <Loading className="text-gray-500 w-16 my-20 mx-auto" />}
              {!camProps && !isLoading && (
                <div className="text-gray-600 text-center p-20">
                  <WarningIcon className="w-10 mx-auto mb-6" />
                  Unable to load config
                </div>
              )}
              {camProps && (
                <CameraPropsForm
                  isLoading={isLoading}
                  onCamPropChange={onCamPropChange}
                  onMotorMovementDecrement={onMotorMovementDecrement}
                  onMotorMovementIncrement={onMotorMovementIncrement}
                  onMotorMovementIrCutOff={onMotorMovementIrCutOff}
                  onStepSizeChange={onStepSizeChange}
                  props={camProps}
                  psocVersion={psoc.version}
                  saveConfigForDay={saveConfigForDay}
                  saveConfigForNight={saveConfigForNight}
                />
              )}
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

type CameraPropsForm = {
  isLoading: boolean;
  onCamPropChange: Function;
  onMotorMovementDecrement: Function;
  onMotorMovementIncrement: Function;
  onMotorMovementIrCutOff: Function;
  onStepSizeChange: Function;
  props: CamProps;
  psocVersion: string;
  saveConfigForDay: () => void;
  saveConfigForNight: () => void;
};
const CameraPropsForm: FC<CameraPropsForm> = ({
  isLoading,
  onCamPropChange,
  onMotorMovementDecrement,
  onMotorMovementIncrement,
  onMotorMovementIrCutOff,
  onStepSizeChange,
  props,
  psocVersion,
  saveConfigForDay,
  saveConfigForNight,
}) => (
  <form className="text-sm">
    <div className="relative md:flex p-8 md:-mx-4">
      {isLoading && (
        <div className="bg-white bg-opacity-50 absolute top-0 right-0 bottom-0 left-0 z-20">
          <Loading className="text-gray-500 w-16 my-20 mx-auto" />
        </div>
      )}
      <div className="w-full md:w-1/3 md:mx-4 mb-6 md:mb-0 pb-6 md:pb-0 border-b md:border-none">
        <div className="mb-6">
          <fieldset>
            <NumberInputForCamProp prop={props.motorMovementStepSize} onChange={onStepSizeChange} />
          </fieldset>
        </div>
        <div className="flex justify-between mb-6 -mx-4">
          <fieldset className="mx-4">
            <NumberInputForMotorMovement
              label="Zoom"
              onDecrement={onMotorMovementDecrement}
              onIncrement={onMotorMovementIncrement}
              step={props.motorMovementStepSize.val as number}
            />
          </fieldset>
          <fieldset className="mx-4">
            <NumberInputForMotorMovement
              label="Focus"
              onDecrement={onMotorMovementDecrement}
              onIncrement={onMotorMovementIncrement}
              step={props.motorMovementStepSize.val as number}
            />
          </fieldset>
        </div>
        <div className="flex justify-between mb-6 -mx-4">
          <fieldset className="mx-4">
            <NumberInputForMotorMovement
              label="Iris"
              onDecrement={onMotorMovementDecrement}
              onIncrement={onMotorMovementIncrement}
              step={props.motorMovementStepSize.val as number}
            />
          </fieldset>
          <fieldset className="mx-4">
            <BooleanInput label="IR Cut-off" onChange={onMotorMovementIrCutOff} />
          </fieldset>
        </div>
        <Divider />

        <CheckboxForCamProp prop={props.gainAuto} onChange={onCamPropChange} />
        <CheckboxForCamProp prop={props.exposureAuto} onChange={onCamPropChange} />
        <CheckboxForCamProp prop={props.exposureAutoLowerLimit} onChange={onCamPropChange} />
        <Divider />

        <CheckboxForCamProp prop={props.strobeEnable} onChange={onCamPropChange} />
        <CheckboxForCamProp prop={props.strobePolarity} onChange={onCamPropChange} />
        <CheckboxForCamProp prop={props.strobeExposure} onChange={onCamPropChange} />
        <Divider />

        <CheckboxForCamProp prop={props.highlightReduction} onChange={onCamPropChange} />
        <CheckboxForCamProp prop={props.offsetAutoCenter} onChange={onCamPropChange} />
        <CheckboxForCamProp prop={props.autoFunctionsRoiControl} onChange={onCamPropChange} />
        <Divider />

        <CheckboxForCamProp prop={props.triggerMode} onChange={onCamPropChange} />
        <CheckboxForCamProp prop={props.triggerGlobalResetRelease} onChange={onCamPropChange} />
        <Divider />

        <CheckboxForCamProp prop={props.reverseX} onChange={onCamPropChange} />
        <CheckboxForCamProp prop={props.reverseY} onChange={onCamPropChange} />
        <CheckboxForCamProp prop={props.imxLowLatencyMode} onChange={onCamPropChange} />
      </div>

      <div className="w-full md:w-2/3 md:mx-4 md:border-l md:pl-8">
        <div className="sm:flex sm:-mx-4">
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.brightness} onChange={onCamPropChange} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.gain} onChange={onCamPropChange} />
          </fieldset>
        </div>
        <Divider />

        <div className="sm:flex sm:-mx-4">
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.gainAutoLowerLimit} onChange={onCamPropChange} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.gainAutoUpperLimit} onChange={onCamPropChange} />
          </fieldset>
        </div>
        {(props.gainAutoLowerLimit || props.gainAutoUpperLimit) && <Divider />}

        <div className="sm:flex sm:-mx-4">
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.exposureTime || props.exposure} onChange={onCamPropChange} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.exposureAutoReference} onChange={onCamPropChange} />
          </fieldset>
        </div>
        <Divider />

        <div className="sm:flex sm:-mx-4">
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.exposureAutoLowerLimit} onChange={onCamPropChange} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.exposureAutoUpperLimit} onChange={onCamPropChange} />
          </fieldset>
        </div>
        <Divider />

        <div className="sm:flex sm:-mx-4">
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.strobeDuration} onChange={onCamPropChange} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.strobeDelay} onChange={onCamPropChange} />
          </fieldset>
        </div>
        <Divider />

        <div className="sm:flex sm:-mx-4">
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <SelectForCamProp prop={props.triggerPolarity} onChange={onCamPropChange} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <SelectForCamProp prop={props.triggerExposureMode} onChange={onCamPropChange} />
          </fieldset>
        </div>
        <Divider />

        <div className="sm:flex sm:-mx-4">
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.triggerDelay} onChange={onCamPropChange} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <SelectForCamProp prop={props.autoFunctionsRoiPreset} onChange={onCamPropChange} />
          </fieldset>
        </div>
        <Divider />

        <div className="sm:flex sm:-mx-4">
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.gpOut} onChange={onCamPropChange} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.gpio} onChange={onCamPropChange} />
          </fieldset>
        </div>
        <Divider />

        <div className="sm:flex sm:-mx-4">
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.offsetX} onChange={onCamPropChange} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6 sm:mb-0">
            <NumberInputForCamProp prop={props.offsetY} onChange={onCamPropChange} />
          </fieldset>
        </div>
      </div>
    </div>
    <div className="sm:flex items-center justify-between bg-gray-100 border-t border-gray-300 p-4">
      <div className="text-xs uppercase mb-4 sm:mb-0 text-right md:text-left">PSOC Version: {psocVersion || 'N/A'}</div>
      <div className="flex justify-end">
        <div>
          <Button icon={<DayIcon />} fill="solid" color="blue-700" onClick={saveConfigForDay}>
            Save day config
          </Button>
        </div>
        <div className="ml-3">
          <Button icon={<NightIcon />} fill="solid" color="blue-700" onClick={saveConfigForNight}>
            Save night config
          </Button>
        </div>
      </div>
    </div>
  </form>
);

const Divider = () => <div className="border-b border-dashed my-6"></div>;

type NumberInputForMotorMovement = { label: string; onDecrement: Function; onIncrement: Function; step: number };
const NumberInputForMotorMovement: FC<NumberInputForMotorMovement> = ({ label, onDecrement, onIncrement, step }) => (
  <NumberInput
    label={label}
    onDecrement={useCallback(() => onDecrement(label), [onDecrement, label])}
    onIncrement={useCallback(() => onIncrement(label), [onIncrement, label])}
    showInput={false}
    step={step}
  />
);

type NumberInputForCamProp = { prop: CamProp; showInput?: boolean; onChange: Function; debounce?: number };
const NumberInputForCamProp: FC<NumberInputForCamProp> = ({ prop, showInput = true, onChange, debounce = 1000 }) => {
  const handleChange = useCallback((val) => prop && onChange(prop.label, val), [onChange, prop]);

  return prop ? (
    <NumberInput
      debounce={debounce}
      label={prop.label}
      max={prop.max}
      min={prop.min}
      onChange={handleChange}
      showInput={showInput}
      step={prop.step}
      value={prop.val as number}
    />
  ) : null;
};

const CheckboxForCamProp: FC<{ prop: CamProp; onChange: Function }> = ({ prop, onChange }) => {
  return prop ? <Checkbox uid={prop.label} label={prop.label} value={prop.val as boolean} onChange={onChange} /> : null;
};

const SelectForCamProp: FC<{ prop: CamProp; onChange: Function }> = ({ prop, onChange }) => (
  <>
    <label className="block mb-1">{prop.label}</label>
    <Select
      value={useMemo(() => ({ value: prop.val as string, label: prop.val as string }), [prop.val])}
      options={(prop.options || []).map((value) => ({ value, label: value }))}
      onChange={useCallback((opt) => onChange(prop.label, opt.value), [onChange, prop.label])}
    />
  </>
);
