export const directions = [
  'N',
  'S',
  'E',
  'W',
  'NE',
  'NW',
  'SW',
  'SE',
  'NNE',
  'ENE',
  'NNW',
  'WNW',
  'SSE',
  'ESE',
  'SSW',
  'WSW',
];

const ranges: { [key: string]: { min: number; max: number } } = {
  N: { min: 0, max: 15 },
  N2: { min: 345, max: 360 },
  NNE: { min: 15, max: 30 },
  NE: { min: 30, max: 60 },
  ENE: { min: 60, max: 75 },
  E: { min: 75, max: 105 },
  ESE: { min: 105, max: 120 },
  SE: { min: 120, max: 150 },
  SSE: { min: 150, max: 165 },
  S: { min: 165, max: 195 },
  SSW: { min: 195, max: 210 },
  SW: { min: 210, max: 240 },
  WSW: { min: 240, max: 255 },
  W: { min: 255, max: 285 },
  WNW: { min: 285, max: 300 },
  NW: { min: 300, max: 330 },
  NNW: { min: 330, max: 345 },
};

const isBetween = (min: number, max: number) => (n: number) => min <= n && n < max;
export const isValidDeg = (deg: number) => !(isNaN(deg) || deg < 0 || deg > 360);

export const degToDir = (deg: number) => {
  if (!isValidDeg) return 'invalid';

  for (const dir in ranges) {
    const { min, max } = ranges[dir];
    if (isBetween(min, max)(deg)) {
      return dir === 'N2' ? 'N' : dir;
    }
  }

  return 'invalid';
};

export function convertDegToDMS(value: number | string, lng?: boolean): string {
  const abs = Math.abs(Number(value));
  const num = Math.floor(abs);
  const min = ((abs - num) * 60) | 0;
  const sec = Math.round(((abs - num) * 3600 - min * 60) * 1000) / 1000;
  const pos = Number(value) < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N';

  return `${num}°${min}'${sec}"${pos}`;
}
