import React, { FC, useCallback, useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/navigation-arrows-right.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/expand-5.svg';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-button-left.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/controls-pause.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/controls-play.svg';
import { ReactComponent as RightIcon } from 'assets/icons/arrow-button-right.svg';
import { ReactComponent as ShrinkIcon } from 'assets/icons/shrink-4.svg';

const styleCenter = { top: '50%', transform: 'translateY(-50%)' };
const classesButton = 'absolute text-white bg-black bg-opacity-0 hover:bg-opacity-25 p-4 z-10';

const emptyFn = () => ({});

type Gallery = {
  canSkipToEnd?: boolean;
  images: { thumbnail: string; original: string; data?: any }[];
  onSlide?: Function;
};
export const Gallery: FC<Gallery> = ({ images, onSlide = emptyFn, canSkipToEnd = false }) => {
  const [length, setLength] = useState(0);
  const [galleryRef, setGalleryRef] = useState<ImageGallery>();

  useEffect(() => {
    if (images.length === length) return;
    onSlide(images[0].data , 0);
    setLength(images.length);
  }, [images, length, onSlide]);

  const handleSlideChange = useCallback(
    (idx) => {
      onSlide(images[idx].data, idx);
    },
    [images, onSlide],
  );

  const renderLeftNav = useCallback((onClick, disabled) => {
    return (
      <button className={classesButton + ' left-0'} disabled={disabled} onClick={onClick} style={styleCenter}>
        <LeftIcon className="w-6" />
      </button>
    );
  }, []);

  const renderRightNav = useCallback((onClick, disabled) => {
    return (
      <button className={classesButton + ' right-0'} disabled={disabled} onClick={onClick} style={styleCenter}>
        <RightIcon className="w-6" />
      </button>
    );
  }, []);

  const renderPlayPauseButton = useCallback((onClick, isPlaying) => {
    return (
      <button className={classesButton + ' left-0 top-0'} onClick={onClick}>
        {isPlaying && <PauseIcon className="w-5" />}
        {!isPlaying && <PlayIcon className="w-5" />}
      </button>
    );
  }, []);

  const renderFullscreenButton = useCallback((onClick, isFullscreen) => {
    return (
      <button className={classesButton + ' right-0 top-0'} onClick={onClick}>
        {isFullscreen && <ShrinkIcon className="w-5" />}
        {!isFullscreen && <ExpandIcon className="w-5" />}
      </button>
    );
  }, []);

  const regCb = useCallback((ref) => setGalleryRef(ref), []);

  const skipToEnd = useCallback(() => {
    galleryRef?.slideToIndex(images.length - 1);
  }, [galleryRef, images.length]);

  return (
    <>
      {canSkipToEnd && (
        <div className="flex justify-end p-4">
          <div className="flex text-xs text-gray-600 hover:text-blue-700 cursor-pointer" onClick={skipToEnd}>
            <ArrowRightIcon className="w-3 mr-2" />
            Skip to the end
          </div>
        </div>
      )}
      <ImageGallery
        items={images}
        lazyLoad={true}
        onSlide={handleSlideChange}
        ref={regCb}
        renderFullscreenButton={renderFullscreenButton}
        renderLeftNav={renderLeftNav}
        renderPlayPauseButton={renderPlayPauseButton}
        renderRightNav={renderRightNav}
        showIndex={true}
        thumbnailPosition="left"
      />
    </>
  );
};
