const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

type coords = { lat: number; lng: number };
export const getDistanceBetweenLatLng = ({ lat: lat1, lng: lng1 }: coords, { lat: lat2, lng: lng2 }: coords) => {
  const R = 6371; // Earth's radius in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};
