import React from 'react';
import { Link } from 'react-router-dom';
import { DefaultRoute } from 'constants/routes';

export const Logo = () => {
  return (
    <Link className="font-body font-bold text-lg text-gray-600 tracking-widest uppercase" to={DefaultRoute.path}>
      R<span className="text-gray-800">ai</span>lState
    </Link>
  );
};
