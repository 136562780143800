import React, { FC, MouseEventHandler } from 'react';

import { Logo } from 'shared/logo';

import PatternImage from 'assets/images/pattern.svg';
import { ReactComponent as GoogleIcon } from 'assets/images/google-icon.svg';

const bgStyle = { backgroundImage: `url(${PatternImage})`, backgroundRepeat: 'repeat' };

export const AuthLayout: FC = ({ children }) => (
  <div className="font-body tracking-wide text-gray-700 bg-gray-200 min-h-screen" style={bgStyle}>
    <div className="relative block py-10 md:py-20">
      <div className="container max-w-md mx-auto px-4">
        <div className="w-full bg-white border border-gray-400 mb-6">
          <div className="flex-auto p-6 lg:p-8">
            <div className="flex justify-center">
              <Logo />
            </div>
            <hr className="my-6" />
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const GoogleButton: FC<{ onClick: MouseEventHandler }> = ({ onClick }) => (
  <div
    className="w-full flex items-center justify-center border border-gray-400 px-6 py-3 rounded cursor-pointer mb-6"
    onClick={onClick}
  >
    <GoogleIcon className="w-5 mr-3" />
    Continue with Google
  </div>
);

export const Divider = () => (
  <div className="text-center pt-2 mb-8">
    <span className="relative font-display text-sm text-gray-500 bg-white tracking-wider px-3">OR</span>
    <div className="border-b border-dashed border-gray-400 -mt-3"></div>
  </div>
);
