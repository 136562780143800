import React, { FC, useState, useMemo } from 'react';
import { GoogleMap, useLoadScript, DrawingManager } from '@react-google-maps/api';

import _debounce from 'lodash/debounce';
import _merge from 'lodash/merge';

import { Environment } from 'environment';
import { useMediaQuery } from 'hooks/use-media-query';

import { ReactComponent as Loading } from 'assets/images/loading.svg';

import { DEFAULT_MAP_CENTER, DEFAULT_MAP_BOUNDS, DEFAULT_MAP_ZOOM } from 'constants/defaults';

import { getDistanceBetweenLatLng } from 'utils/get-distance';

// Types
import { CallbackFn, EmptyCallbackFn } from 'types';
const libraries: ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[] = ['drawing'];

type Map = {
  canDrawRectangle?: boolean;
  onChange?: EmptyCallbackFn | ((e: any, cb: Function) => void);
  onDblClick?: CallbackFn;
  onDrawRectangle?: CallbackFn;
  options?: any;
};
export const Map: FC<Map> = ({
  canDrawRectangle = false,
  onChange = () => {
    return;
  },
  onDblClick = () => {
    return;
  },
  onDrawRectangle = () => {
    return;
  },
  options = {},
  children,
}) => {
  const env = new Environment();
  const isMobile = useMediaQuery('(max-width: 640px)');

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: env.googleApiKey,
    libraries,
  });

  const [map, setMap] = useState<any>();
  const [center, setCenter] = useState(DEFAULT_MAP_CENTER);
  const [bounds, setBounds] = useState(DEFAULT_MAP_BOUNDS);
  const [zoom, setZoom] = useState(DEFAULT_MAP_ZOOM);
  const [hasBoundsChanged, setHasBoundsChanged] = useState(false);

  const containerStyle = useMemo(() => ({ width: '100%', height: isMobile ? '400px' : '600px' }), [isMobile]);
  options = _merge({ clickableIcons: false, mapTypeControl: false, streetViewControl: false }, options);

  const drawingOptions = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: ['rectangle'],
      position: 2, // TOP_CENTER
    },
  };

  const handleLoad: CallbackFn = (_map) => {
    setMap(_map);
    onChange(bounds, () => setHasBoundsChanged(false));
  };

  const onBoundsChangedDebounced = () => {
    const onBoundsChangedCb: EmptyCallbackFn = () => {
      const newBounds = map.getBounds().toJSON();
      setBounds(newBounds);

      const newCenter = map.getCenter().toJSON();
      const changeInDistance = getDistanceBetweenLatLng(center, newCenter);

      const newZoom = map.getZoom();
      const hasZoomChanged = newZoom !== zoom;

      if (!changeInDistance && !hasZoomChanged) return;

      const isSmallChangeInDistance = changeInDistance < 100;
      setHasBoundsChanged(isSmallChangeInDistance);

      setCenter(newCenter);
      setZoom(newZoom);

      if (!isSmallChangeInDistance || hasZoomChanged) onChange(newBounds, () => setHasBoundsChanged(false));
    };
    return _debounce(onBoundsChangedCb, 750);
  };

  const triggerChange = () => onChange(bounds, () => setHasBoundsChanged(false));

  const renderMap = () => (
    <div className="relative">
      {hasBoundsChanged && (
        <div className="left-0 top-0 absolute p-3 z-10">
          <div className="text-sm bg-white shadow cursor-pointer py-3 px-4" onClick={triggerChange}>
            Search this area
          </div>
        </div>
      )}
      <GoogleMap
        center={center}
        mapContainerStyle={containerStyle}
        onBoundsChanged={onBoundsChangedDebounced()}
        onDblClick={onDblClick}
        onLoad={handleLoad}
        options={options}
        zoom={zoom}
      >
        {canDrawRectangle && <DrawingManager onRectangleComplete={onDrawRectangle} options={drawingOptions} />}
        {children}
      </GoogleMap>
    </div>
  );

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <Loading className="text-gray-500 w-16 mx-auto my-20" />;
};
