import { useState, useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

import _find from 'lodash/find';
import _uniq from 'lodash/uniq';

import { useApi } from 'hooks/use-api';

import { useUser } from 'auth.provider';

import { Notify } from 'services/notify';

import { DEFAULT_LIMIT, DEFAULT_SEGMENT_LIMIT, DEFAULT_MAP_BOUNDS } from 'constants/defaults';

import { stringifyMapBounds } from 'utils/stringify-map-bounds';

// Types
import { Camera, SegmentGroup, MapBounds } from 'types';

export const useSegmentClusters = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const { isReadyOnly = false } = useUser();

  const [mapBounds, setMapBounds] = useState(stringifyMapBounds(DEFAULT_MAP_BOUNDS));

  const [selectedSegments, setSelectedSegments] = useState<number[]>([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState<{ lat: number; lng: number }[]>([]);

  const [groupSegments, setGroupSegments] = useState<number[]>([]);
  const [segmentsWithCamera, setSegmentsWithCamera] = useState<number[]>([]);

  const [{ data: segments, loading: isSegmentsLoading }, getSegments] = useApi({ url: '/segments' });
  const [{ data: cameras, loading: isCamerasLoading }, getCameras] = useApi({ url: '/cameras' });
  const [{ data: segmentGroups, loading: isSegmentGroupsLoading }, getSegmentGroups] = useApi({
    url: '/segmentGroups',
  });
  const [{ loading: isSegmentGroupLoading }, updateSegmentGroups] = useApi({
    url: '/segmentGroups',
  });

  const newSegmentGroup = Object.assign({
    capacity: '',
    coordinates: [],
    label: '',
    owners: '',
    segments: [],
    subDiv: '',
    tcs: '',
    tracks: '',
  });
  const [selectedSegmentGroup, setSelectedSegmentGroup] = useState<Partial<SegmentGroup>>(newSegmentGroup);

  useEffect(() => {
    const yes = isSegmentsLoading || isCamerasLoading || isSegmentGroupsLoading || isSegmentGroupLoading;
    setIsLoading(yes);
  }, [isSegmentsLoading, isCamerasLoading, isSegmentGroupsLoading, isSegmentGroupLoading]);

  useEffect(() => {
    const segmentGroupsData = segmentGroups?.data;
    if (!segmentGroupsData) return;

    let _groupSegments: number[] = [];
    let _segmentsWithCamera: number[] = [];

    segmentGroupsData.forEach(({ segments = [], hasCamera = [] }: { segments: number[]; hasCamera: number[] }) => {
      _groupSegments = _groupSegments.concat(segments);
      if (hasCamera) _segmentsWithCamera = _segmentsWithCamera.concat(segments);
    });

    setGroupSegments(_groupSegments);
    setSegmentsWithCamera(_segmentsWithCamera);
  }, [segmentGroups]);

  const getData = (bounds: MapBounds = DEFAULT_MAP_BOUNDS, cb: Function = () => ({})) => {
    const bbox = stringifyMapBounds(bounds);
    setMapBounds(bbox);
    getCameras({ params: { limit: DEFAULT_LIMIT, bbox } });
    getSegments({ params: { limit: DEFAULT_SEGMENT_LIMIT, bbox } });
    getSegmentGroups({ params: { limit: DEFAULT_SEGMENT_LIMIT, bbox } });
    cb();
  };

  const getPositionForCamera = (camera: Camera) => {
    return { lat: camera.lat, lng: camera.lng };
  };

  const getCameraNameById = useCallback(
    (id) => {
      if (!cameras?.data?.length) return 'Not found';
      const cam = _find(cameras.data, { id });
      return cam ? cam.nickname : 'Not found';
    },
    [cameras],
  );

  const selectSegmentGroupById = useCallback(
    (id) => {
      if (!segmentGroups?.data?.length) return;
      const sg = _find(segmentGroups.data, { id: parseInt(id) });
      setSelectedSegmentGroup(sg);
    },
    [segmentGroups],
  );

  const deselectSegmentGroup = useCallback(() => {
    setSelectedSegments([]);
    setSelectedCoordinates([]);
    setSelectedSegmentGroup(newSegmentGroup);
  }, [newSegmentGroup]);

  const saveSegmentGroup = useCallback(
    (sg) => {
      const isExisting = !!sg.id;
      const url = '/segmentGroups' + (isExisting ? `/${sg.id}` : '');
      const method = isExisting ? 'PATCH' : 'POST';
      const existingSegments = selectedSegmentGroup?.segments ? selectedSegmentGroup.segments : [];
      const segments = _uniq(selectedSegments.concat(existingSegments));
      const coordinates = selectedSegmentGroup?.coordinates || [];

      if (!sg.leftCameraId && !sg.rightCameraId) {
        return addToast('Please select at least one side of the camera', { appearance: 'error', autoDismiss: true });
      } else if (!segments.length) {
        return addToast('Please select at least one segment', { appearance: 'error', autoDismiss: true });
      } else if (!sg.label || !sg.subDiv || !sg.tcs || !sg.tracks || !sg.capacity || !sg.owners) {
        return addToast('Please complete form to proceed', { appearance: 'error', autoDismiss: true });
      }

      const data = {
        ...sg,
        segments,
        coordinates: selectedCoordinates.concat(coordinates),
      };

      updateSegmentGroups({ url, method, data })
        .then(() => getSegmentGroups({ params: { limit: DEFAULT_SEGMENT_LIMIT, bbox: mapBounds } }))
        .then(() => {
          setSelectedSegments([]);
          setSelectedCoordinates([]);
          if (!isExisting) setSelectedSegmentGroup(newSegmentGroup);
          addToast('Segment cluster saved successfully', { appearance: 'success', autoDismiss: true });
        })
        .catch(() => {
          addToast('Failed to save segment cluster.', { appearance: 'error', autoDismiss: true });
        });
    },
    [
      addToast,
      getSegmentGroups,
      mapBounds,
      newSegmentGroup,
      selectedCoordinates,
      selectedSegmentGroup,
      selectedSegments,
      updateSegmentGroups,
    ],
  );

  const selectSegment = useCallback(
    (seg) => {
      if (isReadyOnly) return;
      setSelectedSegments(selectedSegments.concat([seg.objectid]));
      setSelectedCoordinates(selectedCoordinates.concat(seg.coordinates));
    },
    [isReadyOnly, selectedSegments, selectedCoordinates],
  );

  const deleteSegmentGroup = (id: number) => {
    Notify.confirm({
      title: 'Delete segment cluster',
      text: 'Are you sure you want to proceed?',
      isDestructive: true,
    }).then(({ isDismissed }) => {
      if (isDismissed) return;
      updateSegmentGroups({ url: `/segmentGroups/${id}`, method: 'DELETE' })
        .then(() => getSegmentGroups({ params: { limit: DEFAULT_SEGMENT_LIMIT, bbox: mapBounds } }))
        .then(() => {
          deselectSegmentGroup();
          addToast('Segment cluster deleted successfully', { appearance: 'success', autoDismiss: true });
        })
        .catch(() => {
          addToast('Failed to delete segment cluster.', { appearance: 'error', autoDismiss: true });
        });
    });
  };

  return {
    cameras,
    deleteSegmentGroup,
    deselectSegmentGroup,
    getCameraNameById,
    getData,
    getPositionForCamera,
    groupSegments,
    isLoading,
    isReadyOnly,
    saveSegmentGroup,
    segmentGroups,
    segments,
    segmentsWithCamera,
    selectedSegmentGroup,
    selectedSegments,
    selectSegment,
    selectSegmentGroupById,
  };
};
