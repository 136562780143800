import { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import _camelCase from 'lodash/camelCase';

import { useApi } from 'hooks/use-api';
import { SelectOptionType } from 'shared/select';

import { CamProps } from 'types/cam-props';
import { Notify } from 'services/notify';

const DEFAULT_STEP_SIZE_VAL = 10;

const transformCamProps = (props: { [key: string]: any }) => {
  const camProps: CamProps = {};
  for (const label in props) {
    const key = _camelCase(label.replace(/ *\([^)]*\) */g, ''));
    camProps[key] = props[label];
    camProps[key].label = label;
    camProps[key].options = props[label].enum_entries;
  }
  camProps.motorMovementStepSize = {
    label: 'Motor movement - step size',
    val: DEFAULT_STEP_SIZE_VAL,
    min: 0,
    max: 20,
    default: 10,
    step: 1,
  };
  return camProps;
};

export const useDeviceConfiguration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNodeForSelect, setSelectedNodeForSelect] = useState<SelectOptionType>();
  const [camProps, setCamProps] = useState<CamProps>();
  const [stepSize, setStepSize] = useState(DEFAULT_STEP_SIZE_VAL);
  const { addToast } = useToasts();

  // API
  const [{ data: cameras, loading: isCamerasLoading }] = useApi({
    url: '/infrastructure/sensors',
    isApiV3: true,
    manual: false,
  });
  const [{ data: devices, loading: isDevicesLoading }] = useApi({
    url: '/infrastructure/devices',
    isApiV3: true,
    manual: false,
  });
  const [{ data: camPropsData, loading: isCameraPropsLoading }, getCameraProps] = useApi({
    url: '/camprops',
    isHardwareApi: true,
  });

  const [{ data: psoc, loading: isVersionLoading }, getVersion] = useApi({
    url: '/psocinfo',
    isHardwareApi: true,
  });

  const [{ loading: isStreamLoading }, getHardware] = useApi({ url: '/', isHardwareApi: true });
  const [{ loading: isConfigLoading }, putHardware] = useApi({ url: '/', method: 'PUT', isHardwareApi: true });

  useEffect(() => {
    const yes =
      isCameraPropsLoading ||
      isCamerasLoading ||
      isConfigLoading ||
      isDevicesLoading ||
      isStreamLoading ||
      isVersionLoading;
    setIsLoading(yes);
  }, [isCamerasLoading, isDevicesLoading || isCameraPropsLoading, isConfigLoading, isStreamLoading, isVersionLoading]);

  useEffect(() => {
    if (camPropsData) setCamProps(transformCamProps(camPropsData));
  }, [camPropsData]);

  const selectNode = useCallback(
    (node) => {
      setSelectedNodeForSelect(node);
      getCameraProps({ url: `/camprops/${node.value}/` }).catch(() =>
        addToast(`Failed to get camera props.`, { appearance: 'error', autoDismiss: true }),
      );
      getVersion({ url: `/psocinfo/${node.value}/` }).catch(() =>
        addToast(`Failed to get PSOC version.`, { appearance: 'error', autoDismiss: true }),
      );
    },
    [getCameraProps, getVersion, addToast],
  );

  const putConfig = useCallback(
    (action, type) => {
      if (!selectedNodeForSelect || !type) return;
      putHardware({ url: `/loadconfig/${action}config/${selectedNodeForSelect.value}/${type}/` })
        .then(() =>
          addToast(`Completed ${action} ${type} config successfully!`, {
            appearance: 'success',
            autoDismiss: true,
          }),
        )
        .catch(() => {
          addToast(`Failed to ${action} ${type} config.`, { appearance: 'error', autoDismiss: true });
        });
    },
    [addToast, putHardware, selectedNodeForSelect],
  );

  const DAY_CONFIG = 'day';
  const LOAD_CONFIG = 'load';
  const NIGHT_CONFIG = 'night';
  const SAVE_CONFIG = 'save';

  const loadConfigForDay = useCallback(() => putConfig(LOAD_CONFIG, DAY_CONFIG), [putConfig]);
  const loadConfigForNight = useCallback(() => putConfig(LOAD_CONFIG, NIGHT_CONFIG), [putConfig]);

  const saveConfigForDay = useCallback(() => putConfig(SAVE_CONFIG, DAY_CONFIG), [putConfig]);
  const saveConfigForNight = useCallback(() => putConfig(SAVE_CONFIG, NIGHT_CONFIG), [putConfig]);

  const getStream = useCallback(
    (nodeId, action) => {
      return getHardware({ url: `/${action}stream/${nodeId}/` })
        .then(() =>
          addToast(`Action ${action} stream was successful!`, {
            appearance: 'success',
            autoDismiss: true,
          }),
        )
        .catch(() => {
          addToast(`Failed to ${action} stream.`, { appearance: 'error', autoDismiss: true });
        });
    },
    [addToast, getHardware],
  );

  const startStream = useCallback((nodeId) => getStream(nodeId, 'start'), [getStream]);
  const stopStream = useCallback((nodeId, cb) => getStream(nodeId, 'stop').then(cb), [getStream]);

  const onStepSizeChange = useCallback((_id, size) => setStepSize(size), []);

  const moveMotor = useCallback(
    (key, val) => {
      if (!selectedNodeForSelect) return;
      putHardware({ url: `/movemotors/${selectedNodeForSelect.value}/`, data: { [key]: val } }).then(
        ({ data: { ok } }) => {
          addToast(`Motor movement for ${key} was ${ok ? '' : 'un'}successful!`, {
            appearance: ok ? 'success' : 'error',
            autoDismiss: true,
          });
        },
      );
    },
    [addToast, putHardware, selectedNodeForSelect],
  );

  const onMotorMovementDecrement = useCallback((id) => moveMotor(id.toLowerCase(), -stepSize), [moveMotor, stepSize]);
  const onMotorMovementIncrement = useCallback((id) => moveMotor(id.toLowerCase(), stepSize), [moveMotor, stepSize]);

  const IR_CUTOFF_KEY = 'ir_cutoff';
  const onMotorMovementIrCutOff = useCallback((val) => moveMotor(IR_CUTOFF_KEY, val ? 1 : 0), [moveMotor]);

  const putCamProps = useCallback(
    (key, val) => {
      if (!selectedNodeForSelect) return;
      putHardware({ url: `/camprops/${selectedNodeForSelect.value}/`, data: { [key]: val } })
        .then(({ data }) => {
          if (data[key].hasOwnProperty('error')) {
            addToast('Failed to update configuration.', { appearance: 'error', autoDismiss: true });
          } else {
            addToast(`Configuration updated successfully!`, { appearance: 'success', autoDismiss: true });
          }
        })
        .catch(() => {
          addToast('Failed to update configuration.', { appearance: 'error', autoDismiss: true });
        });
    },
    [addToast, putHardware, selectedNodeForSelect],
  );

  const onCamPropChange = useCallback((key, val) => putCamProps(key, val), [putCamProps]);

  const [allowEdit, setAllowEdit] = useState(false);
  const handleAllowEdit = (): void => {
    const change = !allowEdit;
    setAllowEdit(change);
    if (change) {
      addToast('Edit configuration of all devices (including in production) is enabled.', {
        appearance: 'warning',
        autoDismiss: true,
      });
    }
  };

  return {
    allowEdit,
    cameras,
    camProps,
    devices,
    handleAllowEdit,
    isLoading,
    loadConfigForDay,
    loadConfigForNight,
    onCamPropChange,
    onMotorMovementDecrement,
    onMotorMovementIncrement,
    onMotorMovementIrCutOff,
    onStepSizeChange,
    psoc,
    saveConfigForDay,
    saveConfigForNight,
    selectedNodeForSelect,
    selectNode,
    startStream,
    stopStream,
  };
};
