import React, { FC } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import { AuthRoutes, AppRoutes, DefaultRoute } from 'constants/routes';

import { AuthRoute } from 'shared/auth-route';

import { AuthProvider } from 'auth.provider';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

const RedirectToLogin: FC<FallbackProps> = ({ resetErrorBoundary }) => {
  resetErrorBoundary();
  return <Redirect to={DefaultRoute.path} />;
};

export const AppRouter = () => {
  return (
    <Router>
      <ErrorBoundary FallbackComponent={RedirectToLogin}>
        <AuthProvider>
          <ToastProvider>
            <Switch>
              <AuthProvider>
                <Routes />
              </AuthProvider>
            </Switch>
          </ToastProvider>
        </AuthProvider>
      </ErrorBoundary>
    </Router>
  );
};

const SwitchAppRoutes = () => {
  const routes = [];
  for (const key in AppRoutes) {
    const route = AppRoutes[key];
    routes.push(<AuthRoute path={route.path} component={route.component} requiredRoles={route.roles} key={key} />);
  }
  return <Switch>{routes}</Switch>;
};

const Routes = () => (
  <Switch>
    <Route exact path={DefaultRoute.path} component={DefaultRoute.component} />
    <Route exact path={AuthRoutes.login.path} component={AuthRoutes.login.component} />
    <Route exact path={AuthRoutes.resetPassword.path} component={AuthRoutes.resetPassword.component} />
    <SwitchAppRoutes />
  </Switch>
);
