export enum Roles {
  admin = 'admin',
  customer = 'data_customer',
  imageTagger = 'image_tagger',
  manager = 'umler_data_manager',
  operator = 'operator',
  owner = 'system_owner',
  readOnly = 'read_only',
  thirdPartyAuditor = 'third_party_auditor',
  sme = 'sme',
  technician = 'technician',
}

export const RoleList = [
  Roles.admin,
  Roles.customer,
  Roles.imageTagger,
  Roles.manager,
  Roles.operator,
  Roles.owner,
  Roles.readOnly,
  Roles.thirdPartyAuditor,
  Roles.sme,
  Roles.technician,
];
