import React, { FC, useCallback } from 'react';

import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/close.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pencil-1.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/multiple-neutral-2.svg';
import { Card } from 'shared/card';
import { User } from 'types';
import { useUsers } from './use-users';
import { UserForm } from './user-form';
import { AppLayout } from '../app.layout';

export const UsersPage = () => {
  const {
    deleteUser,
    deselectUser,
    getUsersCb,
    isError,
    isLoading,
    saveUser,
    selectedUser,
    selectUser,
    users,
  } = useUsers();

  return (
    <AppLayout name="Users">
      <div className="container max-w-5xl mx-auto px-4">
        <div className="mb-8">
          <UserForm
            isLoading={isLoading}
            onClear={deselectUser}
            onDelete={deleteUser}
            onSubmit={saveUser}
            user={selectedUser}
          />
        </div>
        <Card title="Users" icon={<UsersIcon />}>
          {isLoading && <Loading className="text-gray-500 w-16 my-20 mx-auto" />}
          {!isLoading && !!users?.length && (
            <div className="overflow-x-scroll">
              <table className="table table-auto w-full">
                <thead>
                  <tr className="bg-gray-100 border-b text-xs">
                    <th className="p-4">Name</th>
                    <th className="p-4">Role</th>
                    <th className="p-4">Tier</th>
                    <th className="p-4">Email</th>
                    <th className="p-4"></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user: User) => (
                    <UserRow user={user} selectUser={selectUser} deleteUser={deleteUser} key={user.uid} />
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {!isLoading && !users?.length && !isError && <div className="text-center p-10">No users found.</div>}
          {!isLoading && isError && (
            <div className="text-center p-10">
              <div className="mb-4">Something went wrong! Please try again.</div>
              <span className="text-blue-700 border-b cursor-pointer" onClick={getUsersCb}>
                Try again
              </span>
            </div>
          )}
        </Card>
      </div>
    </AppLayout>
  );
};

const UserRow: FC<{ user: User; selectUser: Function; deleteUser: Function }> = ({ user, selectUser, deleteUser }) => {
  const handleSelect = useCallback(() => selectUser(user), [selectUser, user]);
  const handleDelete = useCallback(() => deleteUser(user.uid), [deleteUser, user.uid]);

  return (
    <tr className="text-sm even:bg-gray-100 even:bg-opacity-50 border-b last:border-none" key={user.uid}>
      <td className="p-4 whitespace-no-wrap">
        {user.providerData.length && user.providerData[0].displayName ? user.providerData[0].displayName : '-'}
      </td>
      <td className="p-4 whitespace-no-wrap italic">{user.customClaims?.role || '-'}</td>
      <td className="p-4 whitespace-no-wrap italic">{user.customClaims && user.customClaims.tier === "2" ? user.customClaims.tier : "1"}</td>
      <td className="p-4 whitespace-no-wrap">{user.email}</td>
      <td className="p-4 whitespace-no-wrap">
        <div className="flex items-center justify-end">
          <div className="flex items-center text-blue-700 cursor-pointer mr-6" onClick={handleSelect}>
            <EditIcon className="w-3 mr-2" />
            <span className="mt-1">Edit</span>
          </div>
          <div className="flex items-center text-red-800 cursor-pointer" onClick={handleDelete}>
            <DeleteIcon className="w-3 mr-2" />
            <span className="mt-1">Delete</span>
          </div>
        </div>
      </td>
    </tr>
  );
};
