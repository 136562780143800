import { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useApi } from 'hooks/use-api';
import { Customer } from 'types';
import { Notify } from 'services/notify';

export const useCustomers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const newCustomer = Object.assign({ name: '', email: '' });
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>(newCustomer);

  const [{ data: customers, loading: isCustomersLoading, error: isError }, getCustomers] = useApi({
    url: '/customers',
    manual: false,
  });
  const [{ loading: isUpdateCustomerLoading }, updateCustomer] = useApi({ url: '/customers' });
  const { addToast } = useToasts();

  const getCustomersCb = useCallback(() => {
    getCustomers();
  }, [getCustomers]);

  useEffect(() => {
    const yes = isCustomersLoading || isUpdateCustomerLoading;
    setIsLoading(yes);
  }, [isCustomersLoading, isUpdateCustomerLoading]);

  const selectCustomer = useCallback((customer) => setSelectedCustomer(customer), [setSelectedCustomer]);
  const deselectCustomer = useCallback(() => setSelectedCustomer(newCustomer), [newCustomer]);

  const saveCustomer = (customer: Partial<Customer>) => {
    const isExisting = !!customer.id;
    const url = '/customers' + (isExisting ? `/${customer.id}` : '');
    const method = isExisting ? 'PATCH' : 'POST';

    updateCustomer({ url, method, data: customer })
      .then(() => getCustomers())
      .then(() => {
        setSelectedCustomer(newCustomer);
        addToast('Customer saved successfully', { appearance: 'success', autoDismiss: true });
      })
      .catch(() => {
        addToast('Failed to save customer.', { appearance: 'error', autoDismiss: true });
      });
  };

  const deleteCustomer = (id: number) => {
    Notify.confirm({
      title: 'Delete customer',
      text: 'Are you sure you want to proceed?',
      isDestructive: true,
    }).then(({ isDismissed }) => {
      if (isDismissed) return;
      updateCustomer({ url: `/customers/${id}`, method: 'DELETE' })
        .then(() => getCustomers())
        .then(() => {
          setSelectedCustomer(newCustomer);
          addToast('Customer deleted successfully', { appearance: 'success', autoDismiss: true });
        })
        .catch(() => {
          addToast('Failed to delete customer.', { appearance: 'error', autoDismiss: true });
        });
    });
  };

  return {
    customers,
    deleteCustomer,
    deselectCustomer,
    getCustomersCb,
    isError,
    isLoading,
    saveCustomer,
    selectCustomer,
    selectedCustomer,
  };
};
