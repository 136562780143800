import React, { FC, Fragment, memo, useCallback } from 'react';
import { Marker, OverlayView } from '@react-google-maps/api';
import DefaultMarker from 'assets/images/markers/default.svg';

// Types
import { CallbackFn } from 'types';

const styleMarkerLabel = {
  textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white',
};

type MarkerIcon = { url?: string; scaledSize?: { height: number; width: number }; anchor?: { x: number; y: number } };

type Markers = {
  data: any[];
  getPosition: Function;
  hasLabels?: boolean;
  icon?: MarkerIcon;
  iconWhenInactive?: MarkerIcon;
  iconWhenSelected?: MarkerIcon;
  isInactive?: Function;
  isSelected?: Function;
  labelProp?: string;
  onClick?: CallbackFn;
};

const MarkersFn: FC<Markers> = ({
  data,
  getPosition,
  hasLabels = false,
  icon = {},
  iconWhenInactive = {},
  iconWhenSelected = {},
  isInactive = () => {
    return false;
  },
  isSelected = () => {
    return false;
  },
  labelProp,
  onClick = () => {
    return;
  },
}) => {
  const onClickCb = (marker: any) => () => onClick(marker);

  const DEFAULT_ICON_SIZE = { height: 44, width: 44 };

  const getIcon = (marker: any) => {
    const _icon = { url: DefaultMarker, scaledSize: DEFAULT_ICON_SIZE };
    return {
      ..._icon,
      ...icon,
      ...(isInactive(marker) ? iconWhenInactive : {}),
      ...(isSelected(marker) ? iconWhenSelected : {}),
    };
  };

  const getPixelPositionOffset = useCallback((x: number, y: number) => ({ x: -(x / 2), y: y + 1 }), []);

  const content = (data || []).map((marker, idx) => {
    return (
      <Fragment key={`marker-${idx}-${marker.id}`}>
        <Marker onClick={onClickCb(marker)} position={getPosition(marker)} icon={getIcon(marker)} />
        {hasLabels && labelProp && (
          <OverlayView
            position={getPosition(marker)}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            <div className="font-bold text-xs text-center text-black z-10" style={styleMarkerLabel}>
              {marker[labelProp]}
            </div>
          </OverlayView>
        )}
      </Fragment>
    );
  });

  return <>{content}</>;
};

export const Markers = memo(MarkersFn);
