export const DEFAULT_LIMIT = 1000;
export const DEFAULT_SEGMENT_LIMIT = 4000;
export const DEFAULT_IMAGE_LIMIT = 4000;

export const DEFAULT_MAP_ZOOM = 8;
export const DEFAULT_MAP_CENTER = { lat: 49.986548, lng: -121.519071 };
export const DEFAULT_MAP_BOUNDS = {
  south: 48.915275631670866,
  west: -123.63943232812498,
  north: 51.03448159314372,
  east: -119.39870967187498,
};
