export const CAR_TYPES = [
  'Box Car',
  'Centerbeam Flatcar',
  'Covered Hopper Large',
  'Covered Hopper Small',
  'Covered Hopper',
  'Equipped Gondola',
  'Equipped Hopper',
  'General Service Flatcar',
  'Gondola',
  'Hopper - General Service',
  'Intermodal Car',
  'Locomotive',
  'Passenger Car',
  'Stack Car - Containers',
  'Stack Car',
  'Tank Car',
  'Vehicular Flatcar',
];
