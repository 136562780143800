import React, { FC } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as Loading } from 'assets/images/loading.svg';

import { CameraPlacement } from './camera-placement';
import { EventImages } from './event-images';
import { SampleImages } from './sample-images';

import { useDashboard } from './use-operator-dashboard';
import { AppLayout } from '../app.layout';

export const OperatorDashboardPage = () => {
  const {
    cameraConfig,
    carType,
    carTypeOptions,
    deleteCamera,
    devices,
    deviceStates,
    getData,
    getImageSeriesFn,
    getImagesFn,
    getPositionForCamera,
    getSampleImagesFn,
    getSelectedIndex,
    humanize,
    images,
    imageSeries,
    imageSeriesDailySummary,
    imageSeriesInfo,
    isCameraConfigLoading,
    isImageSeriesDailySummaryLoading,
    isImageSeriesInfoLoading,
    isImageSeriesLoading,
    isImagesLoading,
    isLoading,
    isLoadPanorama,
    isPanoramaImagesLoading,
    isReadyOnly,
    isSampleImagesLoading,
    isSegmentsLoading,
    isSensorLoading,
    isUpdateCameraConfigLoading,
    isUpdateCameraLoading,
    newCameraList,
    onMapDblClick,
    onSaveComment,
    onStateSubmit,
    panoramaImages,
    regions,
    sampleImages,
    saveCamera,
    saveCameraConfig,
    segments,
    selectCamera,
    selectCarTypeFromSelect,
    selectedCamera,
    selectedCameraForSelect,
    selectedImageSeriesId,
    selectedPanelIndex,
    sensorDetail,
    sensors,
    sensorStates,
    setIsLoadPanorama,
    setSelectedImageSeriesId,
    timezones,
  } = useDashboard();

  return (
    <AppLayout name="Site dashboard" hasTabs={true}>
      <Tabs selectedIndex={selectedPanelIndex} onSelect={getSelectedIndex} forceRenderTabPanel={true}>
        <TabList>
          <Tab>Site placement</Tab>
          <Tab disabled={!selectedCamera?.id}>Event images</Tab>
          <Tab disabled={!selectedCamera?.id}>Sample images</Tab>
          {selectedCamera && selectedCamera?.id && (
            <div className="hidden md:flex md:items-center md:ml-auto">
              <SelectedCameraName text={selectedCamera.nickname + ` (${selectedCamera.id})`} />
            </div>
          )}
        </TabList>

        <div className="p-6 md:p-8">
          {selectedCamera && selectedCamera?.id && (
            <div className="flex items-center md:hidden mb-8">
              <SelectedCameraName text={selectedCamera.nickname + ` (${selectedCamera.id})`} />
            </div>
          )}
          <TabPanel>
            <CameraPlacement
              cameras={sensors}
              devices={devices}
              deleteCamera={deleteCamera}
              deviceStates={deviceStates}
              getData={getData}
              getPositionForCamera={getPositionForCamera}
              humanize={humanize}
              isLoading={!isSegmentsLoading && isLoading}
              isReadyOnly={isReadyOnly}
              isSegmentsLoading={isSegmentsLoading}
              isUpdateCameraLoading={isSensorLoading || isUpdateCameraLoading}
              newCameraList={newCameraList}
              onMapDblClick={onMapDblClick}
              onStateSubmit={onStateSubmit}
              regions={regions}
              saveCamera={saveCamera}
              segments={segments}
              selectCamera={selectCamera}
              selectedCamera={selectedCamera}
              selectedCameraForSelect={selectedCameraForSelect}
              sensorDetail={sensorDetail}
              sensorStates={sensorStates}
              timezones={timezones}
            />
          </TabPanel>

          <TabPanel>
            {!selectedCamera && <Loading className="text-gray-500 w-16 mx-auto my-20" />}
            {selectedCamera && (
              <EventImages
                cameraId={selectedCamera.id}
                cameraOrientation={selectedCamera.orientation}
                carType={carType}
                carTypeOptions={carTypeOptions}
                getImages={getImagesFn}
                getImageSeries={getImageSeriesFn}
                images={images}
                imageSeries={imageSeries?.data}
                imageSeriesDailySummary={imageSeriesDailySummary}
                imageSeriesInfo={imageSeriesInfo}
                panoramaImages={panoramaImages}
                isImageSeriesLoading={
                  isImageSeriesInfoLoading || isImageSeriesLoading || isImageSeriesDailySummaryLoading
                }
                isImagesLoading={isImagesLoading}
                isLoadPanorama={isLoadPanorama}
                isPanoramaImagesLoading={isPanoramaImagesLoading}
                onSaveComment={onSaveComment}
                selectCarTypeFromSelect={selectCarTypeFromSelect}
                selectedImageSeriesId={selectedImageSeriesId}
                setIsLoadPanorama={setIsLoadPanorama}
                setSelectedImageSeriesId={setSelectedImageSeriesId}
              />
            )}
          </TabPanel>
          <TabPanel>
            {!selectedCamera && <Loading className="text-gray-500 w-16 mx-auto my-20" />}
            {selectedCamera && (
              <SampleImages
                cameraId={selectedCamera.id}
                getSampleImages={getSampleImagesFn}
                isSampleImagesLoading={isSampleImagesLoading}
                sampleImages={sampleImages?.data}
              />
            )}
          </TabPanel>
        </div>
      </Tabs>
    </AppLayout>
  );
};

const SelectedCameraName: FC<{ text: string }> = ({ text }) => (
  <>
    <div className="text-gray-500 w-4">
      <CameraIcon />
    </div>
    <div className="text-sm mx-3 pt-1">
      <strong>{text}</strong>
    </div>
  </>
);
