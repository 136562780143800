import mqtt from 'mqtt';
import { Environment } from 'environment';

const TOPIC_PREFIX = 'camstream/capture/';

export class Mqtt {
  private _isConnected = false;
  private _client: mqtt.MqttClient;

  constructor(env: Environment, successCallback: Function, errorCallback: Function) {
    this._client = mqtt.connect(env.mqttUrl, env.mqttOptions);

    this._client.on('connect', () => {
      this._isConnected = true;
      successCallback();
    });

    this._client.on('error', () => {
      this._isConnected = false;
      this._client.end();
      errorCallback();
    });
  }

  static configure(successCallback: Function, errorCallback: Function) {
    const env = new Environment();
    return new Mqtt(env, successCallback, errorCallback);
  }

  private _arrayBufferToBase64 = (buffer: Uint8Array) => {
    const str = Array.prototype.map.call(buffer, (ch) => String.fromCharCode(ch)).join('');
    return btoa(str);
  };

  public subscribe = (node: string, errorCallback: Function) => {
    if (!this._isConnected) return;
    this._client.subscribe(TOPIC_PREFIX + node, (err) => err && errorCallback(err));
  };

  public onMessage = (callback: Function) => {
    this._client.on('message', (_topic, buffer: Uint8Array) => {
      callback(`data:image/jpeg;base64,${this._arrayBufferToBase64(buffer)}`);
    });
  };

  public unsubscribe = (node: string, callback: Function) => {
    this._client.unsubscribe(TOPIC_PREFIX + node, callback);
  };

  public end = () => {
    this._client.end();
  };
}
