import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { Header } from './header';

const stylePaddingTop60 = { paddingTop: '60px' };

export const AppLayout: FC<{ name: string; hasTabs?: boolean }> = ({ children, name, hasTabs = false }) => {
  return (
    <div className="font-body tracking-wide text-gray-700 bg-gray-200 min-h-screen" style={stylePaddingTop60}>
      <Header />
      <Helmet>
        <title>{name} &bull; RailState</title>
      </Helmet>
      <div className={'bg-gray-300 p-6' + (hasTabs ? '' : ' border-b border-gray-400')}>{name}</div>
      <div className={'relative flex-auto' + (hasTabs ? '' : ' p-6 md:p-8')}>{children}</div>
    </div>
  );
};
