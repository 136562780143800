import React, { FC, useCallback, useState, useMemo, FormEvent, useEffect } from 'react';
import { Card } from 'shared/card';
import { ReactComponent as EditIcon } from 'assets/icons/pencil-1.svg';
import { Select, SelectOptionType } from 'shared/select';
import moment from 'moment';
import useFormFields from '@usereact/use-form-fields';
import { Link } from 'react-router-dom';

export const StateItem: FC<{
  record: any;
  getCameraStatusLabel: Function;
  showSensorID: boolean;
  last: boolean;
}> = ({ record, getCameraStatusLabel, showSensorID, last }) => {
  const classes =
    'text-sm border-b last:border-none ' + (!last ? 'even:bg-gray-100 even:bg-opacity-50' : 'bg-blue-100');
  return (
    <tr className={classes} key={record.id}>
      <td className="p-4 whitespace-no-wrap">
        {record?.timestamp ? moment.unix(record.timestamp).format('yyyy-MM-DD HH:mm:ss') : ''}
      </td>
      <td className="p-4 whitespace-no-wrap">
        {!!record?.sensorState ? getCameraStatusLabel(record.sensorState.toLowerCase()) : ''}
      </td>
      <td className="p-4 whitespace-no-wrap">
        {showSensorID ? (
          !!record?.sensorId ? (
            <Link className="text-blue-700 cursor-pointer" to={`/operator-dashboard/${record?.sensorId}`}>
              {record?.sensorId}
            </Link>
          ) : (
            ''
          )
        ) : !!record?.deviceId ? (
          <Link className="text-blue-700 cursor-pointer" to={`/device-dashboard/${record?.deviceId}`}>
            {record?.deviceId}
          </Link>
        ) : (
          ''
        )}
      </td>
      <td className="p-4 whitespace-no-wrap">
        {!!record?.deviceState ? getCameraStatusLabel(record.deviceState.toLowerCase()) : ''}
      </td>
      <td className="p-4 whitespace-no-wrap">{record.info || ''}</td>
    </tr>
  );
};

export const StateUpdatesList: FC<{
  getCameraStatusLabel: Function;
  isLoading: boolean;
  sensorDetail: any;
  selectedDevice: any | null;
}> = ({ getCameraStatusLabel, isLoading, sensorDetail, selectedDevice }) => {
  const showSensorID = selectedDevice?.stateUpdates && selectedDevice?.stateUpdates.length > 0;
  const deviceId = selectedDevice && selectedDevice?.deviceId;
  const deviceStateUpdates =
    sensorDetail?.stateUpdates && sensorDetail?.stateUpdates.length > 0
      ? sensorDetail.stateUpdates
      : showSensorID
      ? selectedDevice.stateUpdates
      : null;
  const deviceStateLength = deviceStateUpdates?.length;

  return (
    <div className="overflow-x-scroll">
      <table className="table table-auto w-full">
        <thead>
          <tr className="bg-gray-100 border-b text-xs">
            <th className="p-4">Date</th>
            <th className="p-4">Site state</th>
            <th className="p-4">{showSensorID ? 'Site ID' : 'Device ID'}</th>
            <th className="p-4">Device state</th>
            <th className="p-4">Information</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading && deviceStateUpdates ? (
            deviceStateUpdates.map((record: any, index: React.Key) => {
              if (!deviceId || record?.deviceId === deviceId) {
                return (
                  <StateItem
                    key={index}
                    record={record}
                    getCameraStatusLabel={getCameraStatusLabel}
                    showSensorID={showSensorID}
                    last={deviceStateLength === Number(index) + 1}
                  />
                );
              } else {
                return false;
              }
            })
          ) : (
            <tr className="bg-gray-100 border-b text-xs">
              <td className="w-full flex justify-center" colSpan={5}>
                - no records -
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export const ChangeState: FC<{
  deviceDetail?: any;
  devices?: any;
  deviceStates: any;
  humanize: Function;
  isLoading: boolean;
  isReadyOnly: boolean;
  onStateSubmit?: Function;
  selectedCamera: any;
  sensorDetail?: any;
  sensors?: any;
  sensorStates: any;
}> = ({
  deviceDetail,
  devices,
  deviceStates,
  humanize,
  isLoading,
  isReadyOnly,
  onStateSubmit,
  selectedCamera,
  sensorDetail,
  sensors,
  sensorStates,
}) => {
  const deviceStatesList: SelectOptionType[] = !deviceDetail ? [{ value: '', label: '- empty -' }] : [];
  if (deviceStates && deviceStates?.states) {
    deviceStates.states.map((record: string) => {
      deviceStatesList.push({ value: record, label: humanize(record.toLowerCase()) });
      return record;
    });
  }

  const [deviceState, setDeviceState] = useState<SelectOptionType>();
  const onDeviceStateChange = useCallback((v) => setDeviceState(v), [setDeviceState]);
  const deviceStateOptions = useMemo(() => deviceStatesList, [deviceStatesList]);

  const sensorStatesList: SelectOptionType[] = deviceDetail ? [{ value: '', label: '- empty -' }] : [];
  if (sensorStates && sensorStates?.states) {
    sensorStates.states.map((record: string) => {
      sensorStatesList.push({ value: record, label: humanize(record.toLowerCase()) });
      return record;
    });
  }
  const [sensorState, setSensorState] = useState<SelectOptionType>();
  const onSensorStateChange = useCallback((v) => setSensorState(v), [setSensorState]);
  const sensorStateOptions = useMemo(() => sensorStatesList, [sensorStatesList]);

  const deviceOptionsList: any[] = !deviceDetail ? [{ value: '', label: '- empty -' }] : [];
  if (!isLoading) {
    sensorDetail?.stateUpdates &&
      sensorDetail?.stateUpdates?.length > 0 &&
      sensorDetail.stateUpdates.map((record: any) => {
        if (
          record?.deviceId &&
          (!deviceOptionsList?.length || !deviceOptionsList.find((v) => record?.deviceId === v?.value))
        ) {
          deviceOptionsList.push({ value: record?.deviceId, label: record?.deviceId });
        }
        return record;
      });

    devices &&
      devices?.devices?.length > 0 &&
      devices.devices.map((record: any) => {
        if (record?.deviceId && !deviceOptionsList.find((v) => record?.deviceId === v?.value)) {
          deviceOptionsList.push({ value: record?.deviceId, label: record?.deviceId });
        }
        return record;
      });
  }

  const deviceOptions = useMemo(() => deviceOptionsList, [deviceOptionsList]);
  const [selectedDevice, setSelectedDevice] = useState<SelectOptionType>();
  const onSelectedDeviceChange = useCallback((v) => setSelectedDevice(v), [setSelectedDevice]);

  const SensorsList = [{ value: '', label: '- empty -' }];
  if (!isLoading && sensors && sensors?.sensors) {
    deviceDetail.stateUpdates.map((record: { sensorId: any }) => {
      if (!!record?.sensorId && !SensorsList.find((v) => Number(record?.sensorId) === Number(v?.value))) {
        const item = sensors.sensors.find((v: { id: any }) => Number(record?.sensorId) === Number(v?.id));
        const nickname = item && !!item?.parameters?.nickname ? item.parameters?.nickname : '';
        SensorsList.push({
          value: record?.sensorId,
          label: nickname ? `${nickname} (${record?.sensorId})` : record?.sensorId,
        });
      }
      return record;
    });

    sensors.sensors.map((record: any) => {
      if (record?.id && !SensorsList.find((v) => Number(record?.id) === Number(v?.value))) {
        const nickname = !!record?.parameters?.nickname ? record.parameters?.nickname : '';
        SensorsList.push({ value: record?.id, label: nickname ? `${nickname} (${record?.id})` : record?.id });
      }
      return record;
    });
  }

  const [selectedSensor, setSelectedSensor] = useState<SelectOptionType>();
  const sensorsOptions = useMemo(() => SensorsList, [SensorsList]);
  const onSelectedSensor = useCallback((v) => setSelectedSensor(v), [setSelectedSensor]);

  const { values, fields, setValue } = useFormFields({
    info: '',
    sensorId: '',
    deviceId: '',
  });

  useEffect(() => {
    setValue('info', '');
    setValue('sensorId', selectedCamera?.id || '');
    setValue('deviceId', deviceDetail?.deviceId || '');
  }, [deviceDetail, selectedCamera, setValue]);

  const currentSenzor = selectedCamera?.id || sensorDetail?.id || null;

  useEffect(() => {
    let defaultDevice = { value: '', label: '- empty -' };
    let defaultDeviceState = { value: '', label: '- empty -' };
    let defaultSensor = { value: '', label: '- empty -' };
    let defaultSensorState = { value: '', label: '- empty -' };

    const stateUpdates = !!deviceDetail?.stateUpdates
      ? deviceDetail.stateUpdates
      : !!selectedCamera?.stateUpdates
      ? selectedCamera.stateUpdates
      : [];
    stateUpdates.map((record: { sensorId: any; sensorState: any; deviceState: any; deviceId: any }) => {
      defaultDevice = {
        value: record?.deviceId || '',
        label: record?.deviceId || '- empty -',
      };
      defaultSensor = {
        value: record?.sensorId || '',
        label: record?.sensorId || '- empty -',
      };
      defaultDeviceState = {
        value: record?.deviceState || '',
        label: !!record?.deviceState ? humanize(record.deviceState.toLowerCase()) : '- empty -',
      };
      defaultSensorState = {
        value: record?.sensorState || '',
        label: !!record?.sensorState ? humanize(record.sensorState.toLowerCase()) : '- empty -',
      };
      return record;
    });

    if (stateUpdates?.length <= 0 && currentSenzor) {
      defaultSensor = {
        value: currentSenzor || '',
        label: currentSenzor || '- empty -',
      };
    }
    setDeviceState(defaultDeviceState);
    setSelectedDevice(defaultDevice);
    setSelectedSensor(defaultSensor);
    setSensorState(defaultSensorState);
  }, [
    currentSenzor,
    deviceDetail,
    humanize,
    selectedCamera,
    setDeviceState,
    setSelectedDevice,
    setSelectedSensor,
    setSensorState,
  ]);

  const handleStateSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      onStateSubmit &&
        onStateSubmit({
          ...values,
          deviceId: selectedDevice && !!selectedDevice?.value ? selectedDevice.value : null,
          deviceState: deviceState?.value || null,
          lastState: deviceState?.value || null,
          sensorId: selectedSensor && !!selectedSensor?.value ? selectedSensor.value : selectedCamera?.id || null,
          sensorState: sensorState?.value,
          timestamp: Date.now(),
        });
    },
    [values, selectedDevice, deviceState, selectedCamera, selectedSensor, sensorState, onStateSubmit],
  );

  return (
    <div className="w-full relative mt-6">
      <Card icon={<EditIcon />} title="Change state">
        <form className="text-sm" onSubmit={handleStateSubmit}>
          <div className="pt-4 px-4">
            {(!!deviceDetail || !!sensorDetail?.id) && (
              <div className="block sm:flex">
                <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
                  <label className="block mb-1">Site ID</label>
                  <Select
                    isReadyOnly={isReadyOnly}
                    onChange={onSelectedSensor}
                    options={sensorsOptions}
                    value={selectedSensor}
                  />
                </fieldset>
                <fieldset className="w-full sm:w-1/2 mb-6">
                  <label className="block mb-1">Site state</label>
                  <Select
                    isReadyOnly={isReadyOnly}
                    onChange={onSensorStateChange}
                    options={sensorStateOptions}
                    value={sensorState}
                  />
                </fieldset>
              </div>
            )}

            {(!!deviceDetail || !!sensorDetail?.id) && (
              <div className="block sm:flex">
                <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
                  <label className="block mb-1">Device ID</label>
                  {deviceDetail ? (
                    <input
                      className="p-3 placeholder-gray-400 border border-gray-400 w-full pr-0 bg-gray-100"
                      disabled={true}
                      placeholder="Device ID"
                      type="text"
                      value={deviceDetail?.deviceId || ''}
                    />
                  ) : (
                    <Select
                      isReadyOnly={isReadyOnly}
                      onChange={onSelectedDeviceChange}
                      options={deviceOptions}
                      value={selectedDevice}
                    />
                  )}
                </fieldset>
                <fieldset className="w-full sm:w-1/2 mb-6">
                  <label className="block mb-1">Device state</label>
                  <Select
                    isReadyOnly={isReadyOnly}
                    onChange={onDeviceStateChange}
                    options={deviceStateOptions}
                    value={deviceState}
                  />
                </fieldset>
              </div>
            )}

            <div className="block sm:flex">
              <fieldset className="w-full sm:w-1/1 mb-6 pr-0 mr-0">
                <label className="block mb-1">Comment (optional)</label>
                <input
                  className="p-3 placeholder-gray-400 border border-gray-400 w-full pr-0"
                  placeholder="Information"
                  readOnly={isReadyOnly}
                  type="text"
                  {...fields.info}
                />
              </fieldset>
            </div>
            <p className="pb-3">
              {!!deviceDetail && (
                <p className="mt-2 mb-2">
                  <em>Note: If you change the Site ID you also have to change the state of the previous Site ID</em>
                </p>
              )}
              {!deviceDetail && (
                <p className="mt-2 mb-2">
                  <em>Note: If you change the Device ID you also have to change the state of the previous Device ID</em>
                </p>
              )}
            </p>
          </div>
          {!isReadyOnly && (
            <div className="flex justify-end bg-gray-100 border-t border-gray-300 p-4">
              <input
                className="bg-blue-700 rounded text-white px-4 py-2 cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
                disabled={
                  isLoading ||
                  (deviceDetail && !!deviceDetail?.deviceId && deviceState?.value === '') ||
                  (!deviceDetail && selectedDevice && selectedDevice?.value !== '' && deviceState?.value === '') ||
                  (!deviceDetail && selectedDevice && selectedDevice?.value === '' && deviceState?.value !== '') ||
                  (selectedSensor && selectedSensor?.value !== '' && sensorState?.value === '') ||
                  (selectedSensor && selectedSensor?.value === '' && sensorState?.value !== '')
                }
                type="submit"
                value={isLoading ? 'Please wait...' : 'Save'}
              />
            </div>
          )}
          <p></p>
        </form>
      </Card>
    </div>
  );
};
