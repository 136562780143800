/* eslint-disable @typescript-eslint/camelcase */
import React, { FC, useCallback, useState, useMemo, useEffect, FormEvent } from 'react';
import { Card } from 'shared/card';
import { Checkbox } from 'shared/checkbox';
import { illuminatorEnums, coolerEnums, psocEnums } from '../../../constants/site-device';
import { Parameters } from 'types';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/information-circle.svg';
import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as StatusIcon } from 'assets/icons/information-circle.svg';
import { Select, SelectOptionType } from 'shared/select';
import { StateUpdatesList, ChangeState } from './camera-status';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import useFormFields from '@usereact/use-form-fields';

export const DevicesDashboard: FC<{
  architectures: any | null;
  cameras?: any;
  devices: any;
  humanize: Function;
  isDeviceUpdate: boolean;
  isLoading: boolean;
  isReadyOnly: boolean;
  onDeviceSubmit: Function;
  selectedCamera: any | null;
  selectedDevice: any | null;
  setSelectedDevice: Function;
}> = ({
  architectures,
  cameras,
  devices,
  humanize,
  isDeviceUpdate,
  isLoading,
  isReadyOnly,
  onDeviceSubmit,
  selectedCamera,
  selectedDevice,
  setSelectedDevice,
}) => {
  const selectDevice = useCallback(
    (device: any | null) => {
      setSelectedDevice(device);
    },
    [setSelectedDevice],
  );

  return (
    <div className="xl:flex">
      <div className="w-full xl:w-1/2 relative camera-list-map">
        <Tabs defaultIndex={0} forceRenderTabPanel={true}>
          <TabList>
            <Tab>List of devices</Tab>
          </TabList>
          <TabPanel>
            {isLoading && (
              <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                <Loading className="text-gray-500 w-16 mx-auto my-20" />
              </div>
            )}
            <DevicesList
              devices={devices}
              selectedCamera={selectedCamera}
              selectDevice={selectDevice}
              selectedDevice={selectedDevice}
              humanize={humanize}
              showAll={false}
            />
          </TabPanel>
        </Tabs>
      </div>
      <div className="w-full xl:w-1/2">
        <div className="pt-8 xl:pl-8 xl:pt-0">
          <DevicesDetail
            architectures={architectures}
            deviceStates={null}
            humanize={humanize}
            isDeviceUpdate={isDeviceUpdate}
            isLoading={isLoading}
            isReadyOnly={isReadyOnly}
            onDeviceSubmit={onDeviceSubmit}
            selectedCamera={selectedCamera}
            selectedDevice={selectedDevice}
            sensors={cameras}
            sensorStates={null}
          />
        </div>
      </div>
    </div>
  );
};

export const DevicesItem: FC<{
  device: any;
  selectDevice: Function;
  humanize: Function;
}> = ({ device, selectDevice, humanize }) => {
  const handleSelect = useCallback(() => selectDevice(device), [selectDevice, device]);
  const [hoverStyle, setHoverStyle] = useState('');

  const eventHandlers = useMemo(
    () => ({
      onClick() {
        handleSelect();
        window.scrollTo(0, 0);
      },
      onMouseOver() {
        setHoverStyle(' cursor-pointer text-blue-700 ');
      },
      onMouseOut() {
        setHoverStyle('');
      },
    }),
    [handleSelect],
  );

  return (
    <tr
      className={'text-sm border-b even:bg-gray-100 even:bg-opacity-50 last:border-none' + hoverStyle}
      key={device.device_id}
      {...eventHandlers}
    >
      <td className="p-4 whitespace-no-wrap">{device?.deviceId || ''}</td>
      <td className="p-4 whitespace-no-wrap">{device?.parameters?.architecture.toLowerCase() || ''}</td>
      <td className="p-4 whitespace-no-wrap">{device?.lastState ? humanize(device.lastState.toLowerCase()) : ''}</td>
      <td className="p-4 whitespace-no-wrap">
        <div className="flex items-center justify-end">
          <div className="flex items-center text-blue-700 cursor-pointer mr-6">
            <span className="mt-1">Select</span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export const DevicesList: FC<{
  devices: any;
  selectedCamera: any;
  selectDevice: Function;
  selectedDevice: any;
  humanize: Function;
  showAll: boolean;
}> = ({ devices, selectedCamera, selectDevice, selectedDevice, humanize, showAll = false }) => {
  const currentDevices: any[] = showAll && devices?.devices && devices?.devices?.length > 0 ? devices.devices : [];
  if (devices && devices?.devices && selectedCamera && selectedCamera?.stateUpdates) {
    devices?.devices.map((device: any, key: React.Key) => {
      if (selectedCamera?.stateUpdates.find((rec: { deviceId: any }) => rec?.deviceId === device?.deviceId)) {
        if (currentDevices?.length <= 0 && !selectedDevice?.deviceId) {
          selectDevice(device);
        }
        currentDevices.push(device);
      }
    });
  }

  return (
    <div className="overflow-x-scroll">
      <table className="table table-auto w-full">
        <thead>
          <tr className="bg-gray-100 border-b text-xs">
            <th className="p-4">Device ID</th>
            <th className="p-4">Architecture</th>
            <th className="p-4">Current state</th>
            <th className="p-4">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {currentDevices && currentDevices?.length > 0 ? (
            currentDevices.map((device: any, key: React.Key) => (
              <DevicesItem key={key} device={device} selectDevice={selectDevice} humanize={humanize} />
            ))
          ) : (
            <tr className="bg-gray-100 border-b text-xs">
              <td className="w-full flex justify-center" colSpan={3}>
                - no records -
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export const DevicesDetailForm: FC<{
  architectures: any;
  isDeviceUpdate: boolean;
  isLoading: boolean;
  isReadyOnly: boolean;
  onDeviceSubmit: Function;
  selectedDevice: any;
  humanize: Function;
}> = ({ architectures, isLoading, isDeviceUpdate, isReadyOnly, selectedDevice, onDeviceSubmit, humanize }) => {
  const { values, fields, setValue } = useFormFields({
    deviceId: '',
    architecture: '',
    sshTunnelPort: '',
    imei: '',
    lanMac: '',
    serialNumber: '',
    simNumber: '',
    info: '',
    hardwareCamera: '',
  });

  const isLoadingOrEmpty = isLoading || !selectedDevice?.deviceId;

  const architectureList: SelectOptionType[] =
    architectures && architectures?.architectures
      ? architectures.architectures.map((v: string) => {
          return { value: v, label: v };
        })
      : [];

  const architectureValue = !!selectedDevice?.parameters?.architecture
    ? selectedDevice?.parameters?.architecture
    : null;

  const [architecture, setArchitecture] = useState<SelectOptionType>();
  const onArchitectureChange = useCallback((v) => setArchitecture(v), [setArchitecture]);
  const architectureOptions = useMemo(() => architectureList, [architectureList]);

  const [battery, setBattery] = useState<boolean>();
  const onBatteryChange = useCallback((_, v) => setBattery(v), []);

  const illuminatorList: SelectOptionType[] = [{ label: '- empty -', value: '' }].concat(illuminatorEnums);
  const [illuminator, setIlluminator] = useState<SelectOptionType>();
  const onIlluminatorChange = useCallback((v) => setIlluminator(v), [setIlluminator]);
  const illuminatorOptions = useMemo(() => illuminatorList, [illuminatorList]);

  const coolerList: SelectOptionType[] = [{ label: '- empty -', value: '' }].concat(coolerEnums);
  const [cooler, setCooler] = useState<SelectOptionType>();
  const onCoolerChange = useCallback((v) => setCooler(v), [setCooler]);
  const coolerOptions = useMemo(() => coolerList, [coolerList]);

  const psocList: SelectOptionType[] = [{ label: '- empty -', value: '' }].concat(psocEnums);
  const [psoc, setPsoc] = useState<SelectOptionType>();
  const onPsocChange = useCallback((v) => setPsoc(v), [setPsoc]);
  const psocOptions = useMemo(() => psocList, [psocList]);

  useEffect(() => {
    setValue('deviceId', String(selectedDevice?.deviceId || ''));
    setValue('sshTunnelPort', String(selectedDevice?.parameters?.sshTunnelPort || ''));
    setValue('imei', String(selectedDevice?.parameters?.teltonika?.imei || ''));
    setValue('lanMac', String(selectedDevice?.parameters?.teltonika?.lanMac || ''));
    setValue('serialNumber', String(selectedDevice?.parameters?.teltonika?.serialNumber || ''));
    setValue('simNumber', String(selectedDevice?.parameters?.teltonika?.simNumber || ''));
    setValue('info', String(selectedDevice?.parameters?.info || ''));

    const hardwareData = selectedDevice?.parameters?.hardwareComponents
      ? JSON.parse(selectedDevice?.parameters?.hardwareComponents)
      : {};

    if (hardwareData?.battery !== undefined) {
      setBattery(hardwareData?.battery && String(hardwareData?.battery).toLocaleLowerCase() === 'true');
    }
    setValue('hardwareCamera', String(hardwareData?.camera || ''));

    setIlluminator(
      hardwareData?.illuminator_configuration && hardwareData?.illuminator_configuration !== ''
        ? { value: hardwareData?.illuminator_configuration, label: hardwareData?.illuminator_configuration }
        : { value: '', label: '- empty -' },
    );
    setCooler(
      hardwareData?.cooler && hardwareData?.cooler !== ''
        ? { value: hardwareData?.cooler, label: hardwareData?.cooler }
        : { value: '', label: '- empty -' },
    );
    setPsoc(
      hardwareData?.psoc_type && hardwareData?.psoc_type !== ''
        ? { value: hardwareData?.psoc_type, label: hardwareData?.psoc_type }
        : { value: '', label: '- empty -' },
    );

    const architectureDefault =
      architectureValue &&
      architectures &&
      architectures?.architectures &&
      architectures?.architectures.includes(architectureValue)
        ? { value: architectureValue, label: architectureValue }
        : null;

    setArchitecture(architectureDefault || { value: '', label: '' });
  }, [setValue, setIlluminator, setCooler, setPsoc, selectedDevice, architectures, architectureValue]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      const newHardware: Parameters = {};

      if (values['hardwareCamera'] !== undefined) {
        newHardware.camera = values['hardwareCamera'];
      }
      if (battery !== undefined) {
        newHardware.battery = battery && battery === true;
      }
      if (illuminator !== undefined) {
        newHardware.illuminator_configuration = String(illuminator?.value || '');
      }
      if (cooler !== undefined) {
        newHardware.cooler = String(cooler?.value || '');
      }
      if (psoc !== undefined) {
        newHardware.psoc_type = String(psoc?.value || '');
      }

      const newHardwareConverted = JSON.stringify(newHardware);

      e.preventDefault();
      onDeviceSubmit({
        ...values,
        architecture: architecture?.value || null,
        hardwareComponents: newHardwareConverted === '' || newHardwareConverted === '{}' ? null : newHardwareConverted,
      });
    },
    [values, onDeviceSubmit, architecture, battery, illuminator, cooler, psoc],
  );
  const deviceIdClass = !!selectedDevice?.deviceId
    ? 'p-3 placeholder-gray-400 border border-gray-400 w-full pr-0 bg-gray-100'
    : 'p-3 placeholder-gray-400 border border-gray-400 w-full';

  return (
    <form className="text-sm" onSubmit={handleSubmit}>
      <div className="pt-4 px-4">
        <div className="block sm:flex">
          <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
            <label className="block mb-1">Device ID</label>
            <input className={deviceIdClass} disabled={true} placeholder="Device ID" type="text" {...fields.deviceId} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 mb-6">
            <label className="block mb-1">Architecture</label>
            <Select
              options={architectureOptions}
              value={architecture}
              onChange={onArchitectureChange}
              isReadyOnly={isReadyOnly}
            />
          </fieldset>
        </div>
        <div className="block sm:flex">
          <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
            <label className="block mb-1">SSH tunnel port</label>
            <input
              type="text"
              className="p-3 placeholder-gray-400 border border-gray-400 w-full"
              placeholder="SSH tunnel port"
              readOnly={isReadyOnly}
              {...fields.sshTunnelPort}
            />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 mb-6">
            <label className="block mb-1">Teltonika IMEI</label>
            <input
              type="text"
              className="p-3 placeholder-gray-400 border border-gray-400 w-full"
              placeholder="Teltonika IMEI"
              readOnly={isReadyOnly}
              {...fields.imei}
            />
          </fieldset>
        </div>

        <div className="block sm:flex">
          <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
            <label className="block mb-1">Teltonika LAN MAC</label>
            <input
              type="text"
              className="p-3 placeholder-gray-400 border border-gray-400 w-full"
              placeholder="Teltonika LAN MAC"
              readOnly={isReadyOnly}
              {...fields.lanMac}
            />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 mb-6">
            <label className="block mb-1">Teltonika serial number</label>
            <input
              type="text"
              className="p-3 placeholder-gray-400 border border-gray-400 w-full"
              placeholder="Teltonika serial number"
              readOnly={isReadyOnly}
              {...fields.serialNumber}
            />
          </fieldset>
        </div>

        <div className="block sm:flex">
          <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
            <label className="block mb-1">Teltonika SIM number</label>
            <input
              type="text"
              className="p-3 placeholder-gray-400 border border-gray-400 w-full"
              placeholder="Teltonika SIM number"
              readOnly={isReadyOnly}
              {...fields.simNumber}
            />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 mb-6">
            <label className="block mb-1">Description (optional)</label>
            <input
              type="text"
              className="p-3 placeholder-gray-400 border border-gray-400 w-full"
              placeholder="Information"
              readOnly={isReadyOnly}
              {...fields.info}
            />
          </fieldset>
        </div>

        {!!selectedDevice?.lastState && (
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
              <label className="block mb-1">Current state</label>
              <input
                className="p-3 placeholder-gray-400 border border-gray-400 w-full pr-0 bg-gray-100"
                disabled={true}
                placeholder="Current state"
                type="text"
                value={!!selectedDevice?.lastState ? humanize(selectedDevice.lastState.toLowerCase()) : ''}
              />
            </fieldset>
            <fieldset className="w-full sm:w-1/2 mb-6"></fieldset>
          </div>
        )}

        <p className="pt-2 pb-3 text-left">
          <strong>Hardware:</strong>
        </p>
        <hr className="pb-4" />

        <div className="block sm:flex">
          <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
            <label className="block mb-4">Battery</label>
            <Checkbox
              isReadyOnly={isReadyOnly}
              label="Battery"
              onChange={onBatteryChange}
              uid="battery"
              value={battery || false}
            />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 mb-6">
            <label className="block mb-1">Illuminator configuration</label>
            <Select
              options={illuminatorOptions}
              value={illuminator}
              onChange={onIlluminatorChange}
              isReadyOnly={isReadyOnly}
            />
          </fieldset>
        </div>

        <div className="block sm:flex">
          <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
            <label className="block mb-1">Cooler</label>
            <Select options={coolerOptions} value={cooler} onChange={onCoolerChange} isReadyOnly={isReadyOnly} />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 mb-6">
            <label className="block mb-1">PSoC</label>
            <Select options={psocOptions} value={psoc} onChange={onPsocChange} isReadyOnly={isReadyOnly} />
          </fieldset>
        </div>

        <div className="block sm:flex">
          <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
            <label className="block mb-1">Camera</label>
            <input
              type="text"
              className="p-3 placeholder-gray-400 border border-gray-400 w-full"
              placeholder="Camera"
              readOnly={isReadyOnly}
              {...fields.hardwareCamera}
            />
          </fieldset>
          <fieldset className="w-full sm:w-1/2 mb-6"></fieldset>
        </div>
      </div>

      <div className="flex justify-end bg-gray-100 border-t border-gray-300 p-4">
        {!isReadyOnly && (
          <input
            className="bg-blue-700 rounded text-white px-4 py-2 cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
            type="submit"
            disabled={isLoadingOrEmpty}
            value={isDeviceUpdate ? 'Saving...' : 'Save'}
          />
        )}
      </div>
    </form>
  );
};

export const DevicesDetail: FC<{
  architectures: any;
  deviceStates: any | null;
  humanize: Function;
  isDeviceUpdate: boolean;
  isLoading: boolean;
  isReadyOnly: boolean;
  onDeviceSubmit: Function;
  onStateSubmit?: Function;
  selectedCamera: any;
  selectedDevice: any;
  sensors?: any;
  sensorStates: any | null;
}> = ({
  architectures,
  deviceStates,
  humanize,
  isDeviceUpdate,
  isLoading,
  isReadyOnly,
  onDeviceSubmit,
  onStateSubmit,
  selectedCamera,
  selectedDevice,
  sensors,
  sensorStates,
}) => {
  const isSensorDetail = selectedCamera && selectedCamera?.stateUpdates && selectedCamera?.stateUpdates.length > 0;

  return (
    <>
      {!(selectedDevice && selectedDevice?.deviceId) && (
        <div className="bg-blue-100 border border-blue-400 text-blue-600 p-6">
          <div className="flex items-center mb-4">
            <InfoIcon className="w-5 mr-3" />
            <div className="font-medium">Instructions</div>
          </div>
          <div className="text-sm">
            <div>Please select a device from the list.</div>
          </div>
        </div>
      )}

      {selectedDevice && selectedDevice?.deviceId && (
        <Card icon={<CameraIcon />} title="Device detail">
          {isLoading && (
            <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
              <Loading className="text-gray-500 w-16 mx-auto my-20" />
            </div>
          )}
          <DevicesDetailForm
            architectures={architectures}
            humanize={humanize}
            isDeviceUpdate={isDeviceUpdate}
            isLoading={isLoading}
            isReadyOnly={isReadyOnly}
            onDeviceSubmit={onDeviceSubmit}
            selectedDevice={selectedDevice}
          />
        </Card>
      )}

      {(isSensorDetail || !!selectedDevice?.stateUpdates) && (
        <>
          <br />
          <Card icon={<StatusIcon />} title={'History of state updates'}>
            {isLoading && (
              <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                <Loading className="text-gray-500 w-16 mx-auto my-20" />
              </div>
            )}
            <StateUpdatesList
              getCameraStatusLabel={humanize}
              isLoading={false}
              selectedDevice={selectedDevice}
              sensorDetail={selectedCamera}
            />
          </Card>
          <div>
            {isLoading && (
              <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                <Loading className="text-gray-500 w-16 mx-auto my-20" />
              </div>
            )}
            <ChangeState
              deviceDetail={selectedDevice}
              deviceStates={deviceStates}
              humanize={humanize}
              isLoading={isLoading}
              isReadyOnly={isReadyOnly}
              onStateSubmit={onStateSubmit}
              selectedCamera={selectedCamera}
              sensors={sensors}
              sensorStates={sensorStates}
            />
          </div>
        </>
      )}
    </>
  );
};
