import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Card } from 'shared/card';

import DefCarTypesImage from 'assets/images/help/def-car-types.png';
import DefDateSelectImage from 'assets/images/help/def-date-select.png';
import DefSubdivisionImage from 'assets/images/help/def-subdivision.png';

import FaqSensorSegmentImage from 'assets/images/help/faq-sensor-segment.png';

import { AppLayout } from '../app.layout';

export const HelpPage = () => {
  return (
    <AppLayout name="Help" hasTabs={true}>
      <Tabs forceRenderTabPanel={true}>
        <TabList>
          <Tab>FAQs</Tab>
          <Tab>Definitions</Tab>
        </TabList>
        <div className="p-6 md:p-8">
          <TabPanel>
            <div className="container max-w-5xl mx-auto px-4">
              <div className="mb-6">
                <Card title="Where do I click to get information about the segment?">
                  <div className="text-sm leading-loose p-6">
                    <div className="mb-2">
                      The map at the left shows the segments. The ends of each segment are defined by the camera icons.
                    </div>
                    <div>
                      Click anywhere on the bold rail line between the two camera icons defining the segment you are
                      interested in to show the train data for that segment.
                    </div>
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="What trains appear in the list?">
                  <div className="text-sm leading-loose p-6">
                    You will see all trains observed by the RailState sensor at either end of the segment that enter or
                    exit that segment for the date that you selected.
                    {/* TODO: Insert video or some other graphic */}
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="How do I select another date?">
                  <div className="text-sm leading-loose p-6">
                    <div className="mb-2">
                      The default date is today (the date you logged on). For today, the display will show all trains
                      seen by the sensor at either end of the segment from midnight to to the time you make the date
                      selection. LOCAL TIME of the sensor. For example, if you are on the site located in BC at 11AM
                      PST, you will see all trains that moved through the segment from midnight through 11AM PST.
                    </div>
                    <div>
                      If you select a day other than today you will see all trains that passed through the segment from
                      midnight until 1159 PM LOCAL time.
                    </div>
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Will every train on the list have completed a trip through the segment?">
                  <div className="text-sm leading-loose p-6">
                    <div className="mb-3">
                      No. The train list will include all trains that completed a trip through the segment and any
                      trains that are still in the segment.
                    </div>
                    <div className="mb-2 font-medium">Example:</div>
                    {/* TODO: Insert image */}
                    <div className="mb-2">
                      Some segments are only a few miles long and a train could pass through the segment in less than
                      one hour. Other segments are longer and it could take a day for a train to pass through the whole
                      segment. If you want to determine which trains have passed through the segment, click on adjacent
                      segments and look for the same Train IDs
                    </div>
                    <div className="italic">
                      This will eventually be a new feature - search for all trains still in the segment, i.e. the
                      sensors are A -------------- B, the search will return all trains that passed A but not B and vice
                      versa.
                    </div>
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Can a sensor be part of more than one segment?">
                  <div className="text-sm leading-loose p-6">
                    <div className="mb-3">Yes.</div>
                    <div className="mb-2 font-medium">Example:</div>
                    <img
                      alt="Example: Can a sensor be part of more than one segment?"
                      className="w-1/4 max-w-full bg-gray-100 border p-2 mb-4"
                      src={FaqSensorSegmentImage}
                    />
                    <div className="mb-2">
                      Sensor B is part of segment A-B, B-C and B-D. A train passing B going toward C or D would not be
                      reported in either segment until it passes the sensor at the other end of the segment.
                    </div>
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="How are the sensors named (e.g. McCraken, Chilliwack)?">
                  <div className="text-sm leading-loose p-6">
                    Those are the names of the nearest rail station. The sensors are not necessarily located at the
                    precise physical location of the station.
                  </div>
                </Card>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="container max-w-5xl mx-auto px-4">
              <div className="mb-6">
                <Card title="RailState Node">
                  <div className="text-sm leading-loose p-6">
                    <div className="mb-4">
                      RailState nodes are proprietary devices placed along, but well off, the railway right of way to
                      capture information about passing trains. Each Node contains a camera, lens, computer chip and
                      other components. They capture an image of each locomotive and car in each train along with speed
                      and direction of the train.
                    </div>
                    <div>
                      The Node then processes the images through a number of proprietary computer vision algorithms to
                      identify from the images, the car numbers, locomotive numbers, car types, and other information
                      about the train. The data is then transmitted to RailState’s server where it is further processed
                      then displayed on RailState website. Generally, data from a train are visible on the RailState
                      website within 2 hours of when it passes a RailState sensor.
                    </div>
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Car Types, Car Number (or Car Mark)">
                  <div className="text-sm leading-loose p-6">
                    Every car operating on the railway system in North America is identified by a unique car number. The
                    car number is a series of from one to four letters and from one to six numbers located at a specific
                    place on the car depending on the car type. Every locomotive is identified by a locomotive number
                    from one to four numbers located at several places on the locomotive.
                    <img
                      alt="Car Types, Car Number (or Car Mark)"
                      className="w-1/2 max-w-full bg-gray-100 border p-2 mt-4"
                      src={DefCarTypesImage}
                    />
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Rail Station">
                  <div className="text-sm leading-loose p-6">
                    Rail Station is the railway designated name of a location on a rail line. Stations are official
                    locations from which cars can be billed or to which cars can be consigned.
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Owner">
                  <div className="text-sm leading-loose p-6">
                    The line owner is the name of the railway company that owns or operates the track on which the
                    segment is located.
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Subdivision">
                  <div className="text-sm leading-loose p-6">
                    Railways divide their rail lines into divisions and subdivisions. A subdivision is defined by a rail
                    station at each end. They can be varying lengths. For example the RailState segment selected in the
                    example is owned/operated by the CN Railway. It is part of the CN Yale Subdivision which extends
                    between BC stations Boston Bar and Vancouver, a distance of 131.8 miles.
                    <img
                      alt="Subdivision"
                      className="w-1/2 max-w-full bg-gray-100 border p-2 mt-4"
                      src={DefSubdivisionImage}
                    />
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Segment Capacity in Trains Per Day">
                  <div className="text-sm leading-loose p-6">
                    <div className="mb-3">
                      The number of trains that can pass through a given segment of track is determined primarily by:
                    </div>
                    <ul className="list-disc list-outside pl-6">
                      <li className="mb-4">
                        <div className="mb-2">
                          <span className="font-medium">Number of main tracks</span> - the more main tracks, the higher
                          the capacity
                        </div>
                        <div>
                          Most sections of the North American railway system are single tracked with sidings located in
                          various places for trains to meet and pass each other. Most of the heaviest volume segments
                          are double tracked so trains can pass or meet each other without stopping. Some sections of
                          the railway system have three or four main tracks.
                        </div>
                      </li>
                      <li className="mb-4">
                        <div className="mb-2">
                          <span className="font-medium">Traffic control system</span>
                        </div>
                        <div className="mb-2">
                          The control system maintains safe spacing between trains on the same track. There are three
                          main types of traffic control systems.
                        </div>
                        <ul className="list-disc list-outside pl-6">
                          <li className="mb-4">
                            <div className="mb-2">
                              <span className="font-medium">
                                Centralized Traffic Control (CTC) and Traffic Control System (TCS).
                              </span>{' '}
                              These are the most advanced traffic control technology. These systems are usually found on
                              lines with the highest traffic density. They use electrical circuits in the tracks to
                              monitor the location of trains allowing them to be controlled remotely by a dispatcher
                              (rail traffic controller in Canada). A given segment of track can handle the most trains
                              with CTC because it allows trains to be spaced closer together. The signal system keeps
                              trains from entering sections of track already occupied by another train.
                            </div>
                            <div>
                              A new technology, Positive Train Control (PTC), provides additional protection. The
                              locomotive has electronic technology that integrates with a system along the track and
                              central computers to monitor the train’s movement. If the train crew does not respond
                              properly to a signal indication, the PTC will automatically stop the train.
                            </div>
                          </li>
                          <li className="mb-4">
                            <span className="font-medium">Automated Block Signals (ABS).</span> A block is a designated
                            section of track. The length of a block is based on the average length of trains and the
                            distance required to stop the train safely. ABS systems have a signal at each end of the
                            block. When a train enters a block, the signals at both ends signal stop (e.g. red). When
                            the train exits the block, the signal turns yellow telling the following train that the
                            block is unoccupied and it can proceed safely into that block but must be prepared to stop
                            before the next block signal. ABS signals are determined by occupancy of the block and are
                            not controlled by dispatchers. In ABS systems, trains are spaced farther apart, providing
                            less capacity than under CTC.
                          </li>
                          <li className="mb-4">
                            <div className="mb-2">
                              <span className="font-medium">Track Warrant Control (no signals).</span> The train crew
                              receives verbal instructions via radio from the dispatcher with authority to occupy a
                              given “block” of track. When the crew reaches the end of the block, they must stop, report
                              their location and request authority to proceed into the next block. Speeds are lower in
                              non-signal territory and trains must be kept farther apart. TWC is usually present on the
                              lightest density lines.
                            </div>
                            <div>
                              There are variations on these traffic control systems in place on different railways but
                              these represent the primary types of traffic control systems.
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        There are other factors that affect capacity of a given segment of track including the number
                        and length of sidings where trains can meet or pass each other, capacity of yards and terminals,
                        the track speed, mix of train types (different train types may have different speed limits) and,
                        for single track segments, whether the track is used in both directions or handles traffic
                        primarily on one direction.
                      </li>
                    </ul>
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Percentage of Capacity">
                  <div className="text-sm leading-loose p-6">
                    <div className="mb-2">
                      The RailState estimate of a segment capacity in trains per day is determined by the number of main
                      tracks and the traffic control system. Other factors are accounted for if we have additional
                      information about a segment.{' '}
                    </div>
                    <div>
                      The percentage of capacity = the percent of capacity consumed by the total trains passing through
                      the segment on the chosen day.
                    </div>
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Date Select">
                  <div className="text-sm leading-loose p-6">
                    Click in the Date box and a small calendar will pop up. Choose the date you want to see. You can
                    also just type a date in the box mm/dd/yy.
                    <img
                      alt="Date Select"
                      className="w-1/2 max-w-full bg-gray-100 border p-2 mt-4"
                      src={DefDateSelectImage}
                    />
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Train Activity Summary">
                  <div className="text-sm leading-loose p-6">
                    The bottom section on the right shows the train activity for the selected date and information about
                    each train.
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Train Types">
                  <div className="text-sm leading-loose p-6">
                    <div className="mb-4">
                      Train types are determined by the predominant car type in the train. If 50% or more of the cars in
                      the train are:
                    </div>
                    <table className="table table-auto border text-xs mb-4">
                      <thead>
                        <tr className="bg-gray-100 border-b">
                          <th className="px-4 py-2 border-r">Predominant Car Type</th>
                          <th className="px-4 py-2 border-r">RailState Train Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-b">
                          <td className="px-4 py-2 border-r">Stack car</td>
                          <td className="px-4 py-2 border-r">Intermodal</td>
                        </tr>
                        <tr className="border-b">
                          <td className="px-4 py-2 border-r">
                            Large covered hoppers (not bearing car mark of a potash shipper
                          </td>
                          <td className="px-4 py-2 border-r">Grain unit train</td>
                        </tr>
                        <tr className="border-b">
                          <td className="px-4 py-2 border-r">
                            Large covered hoppers bearing marks of a potash shipper
                          </td>
                          <td className="px-4 py-2 border-r">Potash unit train</td>
                        </tr>
                        <tr className="border-b">
                          <td className="px-4 py-2 border-r">Tank car</td>
                          <td className="px-4 py-2 border-r">Petroleum unit train</td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2 border-r">Vehicular flat car</td>
                          <td className="px-4 py-2 border-r">Automotive</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="mb-2 font-medium">
                      Trains that contain less than 50% of one car type are labeled Manifest.
                    </div>
                    <div className="mb-2">
                      <span className="font-medium">Unit trains</span> are trains of the same commodity generally moving
                      from one origin to one destination.
                    </div>
                    <div className="mb-2">
                      <span className="font-medium">Intermodal trains</span> contain railcars carrying containers or
                      trailers on flatcars. These trains move between railroad intermodal terminals where containers are
                      brought in by truck and loaded on the railcar.
                    </div>
                    <div className="mb-2">
                      <span className="font-medium">Manifest trains</span> contain a variety of car types. Railway
                      customers shipping less than unit train quantities of railcars (single car or multiple car
                      shipments also known as manifest shipments) will move in manifest service. This generally means
                      cars are picked up by a local train, moved to a hub, combined with other shippers’ cars into a
                      train which moves to another hub where cars are broken down and delivered by a local train. Some
                      manifest shipments may move on more than one manifest train. Manifest trains can sometimes pick up
                      cars or set off cars enroute.
                    </div>
                    <div>Sometimes a unit train will carry cars other than the predominant car type.</div>
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Timetable Direction">
                  <div className="text-sm leading-loose p-6">
                    Railways combine information about each subdivision in a region into an employee timetable for that
                    region. The timetable lists the stations in the subdivision, the railway milepost location for each
                    station, the location and length of sidings, information about the traffic control system and other
                    details required by employees for operating over that subdivision. For each subdivision the
                    timetable also lists the direction on the subdivision, North-South or East-West. The railroad
                    timetable direction isn’t always the same as the map direction. When the railway describes the
                    direction of a train, they are referring to the timetable direction. RailState references to train
                    direction also mean timetable direction.
                  </div>
                </Card>
              </div>
              <div className="mb-6">
                <Card title="Velocity">
                  <div className="text-sm leading-loose p-6">
                    This is the speed in MPH that the train was moving passing the sensor entering the segment.
                  </div>
                </Card>
              </div>
            </div>
          </TabPanel>
        </div>
      </Tabs>
    </AppLayout>
  );
};
