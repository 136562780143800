import React from 'react';
import DatePicker from 'react-datepicker';

import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as PictureIcon } from 'assets/icons/picture-stack-landscape.svg';
import { ReactComponent as PictureWarningIcon } from 'assets/icons/image-file-warning.svg';

import { Card } from 'shared/card';
import { Gallery } from 'shared/gallery';
import { Select } from 'shared/select';

// Types
import { Camera } from 'types';

import { DATEPICKER_FORMAT } from 'constants/formats';

import { useLegacyImages } from './use-legacy-images';
import { AppLayout } from '../app.layout';

const styleTop6 = { top: '6rem' };
const styleMinHeight250 = { minHeight: '250px' };
const styleMaxWidth200 = { maxWidth: '200px' };
const styleMinWidth550 = { minWidth: '550px' };

export const LegacyImagesPage = () => {
  const {
    cameras,
    endDate,
    images,
    imageSort,
    imageSortOptions,
    isLoading,
    onDateChange,
    selectCameraFromSelect,
    selectedCamera,
    selectedCameraForSelect,
    selectImageSortFromSelect,
    startDate,
    submitForm,
  } = useLegacyImages();

  return (
    <AppLayout name="Legacy images">
      <div className="xl:flex">
        <div className="w-full xl:w-3/5 relative">
          <div className="sticky" style={styleTop6}>
            <Card title="Images" icon={<PictureIcon />}>
              <div style={styleMinHeight250}>
                {isLoading && (
                  <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                    <Loading className="text-gray-500 w-16 mx-auto my-20" />
                  </div>
                )}
                {!isLoading && !images.length && (
                  <div className="text-gray-600 text-center p-20">
                    <PictureWarningIcon className="w-10 mx-auto mb-6" />
                    {selectedCamera ? 'No images' : 'Select camera'}
                  </div>
                )}
                {selectedCamera && !!images.length && (
                  <>
                    <div className="flex items-center justify-end text-xs p-4">
                      <div className="text-gray-600 mr-3">Sort by</div>
                      <div className="relative w-full z-20" style={styleMaxWidth200}>
                        <Select
                          onChange={selectImageSortFromSelect}
                          options={imageSortOptions}
                          placeholder="Sort by"
                          value={imageSort}
                        />
                      </div>
                    </div>
                    <Gallery images={images} />
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>

        {/* Details */}
        <div className="w-full xl:w-2/5" style={styleMinWidth550}>
          <div className="pt-8 xl:pl-8 xl:pt-0">
            <div className="relative mb-8 z-10">
              <Select
                isDisabled={!cameras?.data?.length}
                onChange={selectCameraFromSelect}
                options={(!!cameras?.data?.length ? cameras.data : []).map((camera: Camera) => ({
                  camera,
                  value: camera.id,
                  label: camera.nickname,
                }))}
                placeholder="Select camera"
                value={selectedCameraForSelect}
              />
            </div>
            {selectedCamera && (
              <Card title="Date range" icon={<CalendarIcon />}>
                <div className="p-4">
                  <DatePicker
                    dateFormat={DATEPICKER_FORMAT}
                    disabledKeyboardNavigation
                    endDate={endDate}
                    inline
                    monthsShown={2}
                    onChange={onDateChange}
                    openToDate={startDate}
                    selectsRange
                    startDate={startDate}
                  />
                </div>
                <div className="flex justify-end bg-gray-100 border-t border-gray-300 p-4">
                  <input
                    className="text-sm bg-blue-700 rounded text-white px-4 py-2 cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
                    disabled={isLoading}
                    type="submit"
                    value={isLoading ? 'Please wait...' : 'Apply date range'}
                    onClick={submitForm}
                  />
                </div>
              </Card>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
