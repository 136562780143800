import React from 'react';
import { Logo } from 'shared/logo';
import PatternImage from 'assets/images/pattern.svg';
import { ReactComponent as Loading } from 'assets/images/loading.svg';

const bgStyle = { backgroundImage: `url(${PatternImage})`, backgroundRepeat: 'repeat' };

export const SplashScreen = () => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-200" style={bgStyle}>
    <Loading className="text-gray-500 w-16 mb-8" />
    <Logo />
  </div>
);
