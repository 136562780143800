import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DatePicker from 'react-datepicker';

import { ReactComponent as Loading } from 'assets/images/loading.svg';

import { ReactComponent as CalendarAltIcon } from 'assets/icons/calendar-3.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DatabaseSearchIcon } from 'assets/icons/database-search-alternate.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/expand-horizontal-3.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-1.svg';
import { ReactComponent as PictureIcon } from 'assets/icons/picture-stack-landscape.svg';
import { ReactComponent as PictureWarningIcon } from 'assets/icons/image-file-warning.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/button-refresh-arrows.svg';

import { Card } from 'shared/card';
import { Gallery } from 'shared/gallery';
import { Select, SelectOptionType } from 'shared/select';
import { Table } from 'shared/table';

import { EventImageInfo } from './event-image-info';

import moment from 'moment';
import { DATEPICKER_FORMAT, DATETIME_FORMAT } from 'constants/formats';
import { Comment } from 'types';

const styleTop6 = { top: '6rem' };
const styleMinHeight250 = { minHeight: '250px' };

type EventImages = {
  cameraId: number;
  cameraOrientation: number;
  carType: SelectOptionType | undefined;
  carTypeOptions: SelectOptionType[];
  getImages: Function;
  getImageSeries: Function;
  images: any[];
  imageSeries: any[];
  imageSeriesDailySummary: any[];
  imageSeriesInfo: { eventCount: string; imageCount: string };
  isImageSeriesLoading: boolean;
  isImagesLoading: boolean;
  isPanoramaImagesLoading: boolean;
  panoramaImages: any[];
  selectCarTypeFromSelect: Function;
  onSaveComment: (commentData: Comment, user?: any) => void;
  isLoadPanorama?: boolean;
  setIsLoadPanorama: Function;
  selectedImageSeriesId: string;
  setSelectedImageSeriesId: Function;
};
export const EventImages: FC<EventImages> = ({
  cameraId,
  cameraOrientation,
  carType,
  carTypeOptions,
  getImages,
  getImageSeries,
  images,
  imageSeries,
  imageSeriesDailySummary,
  imageSeriesInfo,
  isImageSeriesLoading,
  isImagesLoading,
  isPanoramaImagesLoading,
  panoramaImages,
  selectCarTypeFromSelect,
  isLoadPanorama,
  setIsLoadPanorama,
  selectedImageSeriesId,
  setSelectedImageSeriesId,
  onSaveComment,
}) => {
  const defaultDate = moment().toDate();
  const [date, setDate] = useState(defaultDate);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImageIdx, setSelectedImageIdx] = useState();
  const [selectedPanoramasIndex, setSelectedPanoramasIndex] = useState(0);

  const onDateChange = useCallback((d: Date) => {
    setDate(d);
    localStorage.removeItem('date');
    localStorage.setItem('date', JSON.stringify(d));
  }, []);

  const handleTodayDate = useCallback(() => {
    setDate(moment().toDate());
  }, []);

  const selectImage = useCallback((image, idx) => {
    setSelectedImage(image);
    setSelectedImageIdx(idx);
  }, []);

  const getImageSeriesCb = useCallback(() => {
    const start = moment(date).startOf('day').valueOf();
    const end = moment(date).endOf('day').valueOf();
    getImageSeries(cameraId, start, end);
  }, [cameraId, date, getImageSeries]);

  useEffect(() => {
    if (!imageSeries?.length) return;
    if (!selectedImageSeriesId) setSelectedImageSeriesId(String(imageSeries[0].sightingId));
  }, [imageSeries, selectedImageSeriesId, setSelectedImageSeriesId]);

  useEffect(() => {
    if (!cameraId) return;
    getImageSeriesCb();
  }, [cameraId, getImageSeriesCb]);

  useEffect(() => {
    const d = localStorage.getItem('date') ?? '';
    if (d) {
      const newDate = JSON.parse(d);
      setDate(new Date(newDate));
    }
  }, []);

  const handleRefresh = useCallback(
    (e) => {
      e.preventDefault();
      getImageSeriesCb();
    },
    [getImageSeriesCb],
  );

  const handleSelect = useCallback(
    (sightingId) => {
      setSelectedImageSeriesId(sightingId);
      getImages(cameraId, sightingId);
    },
    [cameraId, getImages, setSelectedImageSeriesId],
  );

  const onSelectPanoramsIdx = (i: number, l: number) => {
    setSelectedPanoramasIndex(i);
  };
  const onloadImage = () => {
    setIsLoadPanorama(false);
  };

  return (
    <div className="xl:flex">
      <div className="w-full xl:w-7/12 relative">
        <div className="sticky" style={styleTop6}>
          <Tabs forceRenderTabPanel={true} className="react-tabs react-tabs--transparent">
            <TabList>
              <Tab>Images</Tab>
              <Tab>Panorama</Tab>
            </TabList>
            <TabPanel>
              <Card title="Images" icon={<PictureIcon />}>
                <div style={styleMinHeight250}>
                  {(isImagesLoading || isImageSeriesLoading) && (
                    <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                      <Loading className="text-gray-500 w-16 mx-auto my-20" />
                    </div>
                  )}
                  {!isImagesLoading && !isImageSeriesLoading && (!imageSeries?.length || !images?.length) && (
                    <div className="text-gray-600 text-center p-20">
                      <PictureWarningIcon className="w-10 mx-auto mb-6" />
                      No images
                    </div>
                  )}
                  {!!imageSeries?.length && !!images?.length && (
                    <>
                      <Gallery
                        images={images.map((i, idx) => ({
                          thumbnail: i.previewImageUrl,
                          original: i.fullImageUrl,
                          data: i,
                        }))}
                        onSlide={selectImage}
                        canSkipToEnd={true}
                      />
                      {selectedImage && (
                        <EventImageInfo
                          image={selectedImage}
                          cameraOrientation={cameraOrientation}
                          onSaveComment={onSaveComment}
                          selectedImageSeriesId={selectedImageSeriesId}
                          selectedImageIdx={selectedImageIdx}
                          imageSeries={imageSeries}
                          isComment={true}
                        />
                      )}
                    </>
                  )}
                </div>
              </Card>
            </TabPanel>
            <TabPanel>
              {!isPanoramaImagesLoading &&
                !isLoadPanorama &&
                !isImageSeriesLoading &&
                (!imageSeries?.length || !panoramaImages.length) && (
                  <Card title={`Panorama 1`} icon={<PictureIcon />}>
                    {!isPanoramaImagesLoading &&
                      !isImageSeriesLoading &&
                      (!imageSeries?.length || !panoramaImages.length) && (
                        <div className="text-gray-600 text-center p-20">
                          <PictureWarningIcon className="w-10 mx-auto mb-6" />
                          No images
                        </div>
                      )}
                  </Card>
                )}
              {(isPanoramaImagesLoading || isImageSeriesLoading) && (
                <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                  <Loading className="text-gray-500 w-16 mx-auto my-20" />
                </div>
              )}

              {panoramaImages && panoramaImages.length > 0 && (
                <Tabs
                  forceRenderTabPanel={true}
                  selectedIndex={selectedPanoramasIndex}
                  onSelect={onSelectPanoramsIdx}
                  className="react-tabs react-tabs--transparent"
                >
                  <TabList>
                    {panoramaImages.map((v, i) => {
                      return <Tab key={i}>Panorama {i + 1}</Tab>;
                    })}
                  </TabList>
                  {panoramaImages.map((v, i) => {
                    return (
                      <TabPanel key={i}>
                        <Card title={`Panorama ${i + 1}`} icon={<PictureIcon />}>
                          <div style={styleMinHeight250}>
                            {((v && isLoadPanorama) || isImageSeriesLoading) && (
                              <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                                <Loading className="text-gray-500 w-16 mx-auto my-20" />
                              </div>
                            )}
                            {!isPanoramaImagesLoading && !isImageSeriesLoading && (!imageSeries?.length || !v?.length) && (
                              <div className="text-gray-600 text-center p-20">
                                <PictureWarningIcon className="w-10 mx-auto mb-6" />
                                No images
                              </div>
                            )}
                            {!!imageSeries?.length && !!v?.length && (
                              <div className="flex overflow-x-auto">
                                {v.map((i: any, idx: any) => (
                                  <img
                                    src={i}
                                    key={idx}
                                    alt={i}
                                    onLoad={() => {
                                      idx === 0 && onloadImage();
                                    }}
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        </Card>
                      </TabPanel>
                    );
                  })}
                </Tabs>
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <div className="w-full xl:w-5/12">
        <div className="pt-8 xl:pl-8 xl:pt-0">
          <div className="mb-8">
            <Card title="Selected date" icon={<CalendarIcon />}>
              <div className="flex items-center p-4">
                <DatePicker selected={date} onChange={onDateChange} dateFormat={DATEPICKER_FORMAT} />
                {!isImageSeriesLoading && (
                  <div className="flex items-center justify-between w-full text-gray-600 cursor-pointer p-4">
                    <div className="text-sm" onClick={handleTodayDate}>
                      Today
                    </div>
                    <RefreshIcon className="w-4" onClick={handleRefresh} />
                  </div>
                )}
              </div>
            </Card>
          </div>

          <div className="mb-8">
            <Card title="Filter by car type" icon={<FilterIcon />}>
              <div className="p-4 z-20">
                <Select
                  isClearable={true}
                  onChange={selectCarTypeFromSelect}
                  options={carTypeOptions}
                  placeholder="Select car type"
                  value={carType}
                />
              </div>
            </Card>
          </div>

          <div className="mb-8">
            <Card title="Detected events" icon={<CalendarAltIcon />}>
              {!isImageSeriesLoading && imageSeriesInfo && !!imageSeries?.length && (
                <>
                  <InfoTable date={date} info={imageSeriesInfo} />
                  <EventsTable
                    events={imageSeries}
                    onRowSelect={handleSelect}
                    selectedEventId={selectedImageSeriesId}
                  />
                </>
              )}
              {!isImageSeriesLoading && !imageSeries?.length && (
                <div className="text-center text-gray-600 p-8">
                  <DatabaseSearchIcon className="w-6 mx-auto mb-2" />
                  <div className="text-xs font-medium">No events</div>
                </div>
              )}
              {(isImageSeriesLoading || !(imageSeriesInfo && imageSeries)) && (
                <div className="p-8">
                  <Loading className="text-gray-500 w-10 mx-auto" />
                </div>
              )}
            </Card>
          </div>

          <Card title="Distribution of train sizes" icon={<ExpandIcon />}>
            {!isImageSeriesLoading && !!imageSeriesDailySummary?.length && (
              <>
                <DailySummaryTable data={imageSeriesDailySummary} />
              </>
            )}
            {!isImageSeriesLoading && !imageSeries?.length && (
              <div className="text-center text-gray-600 p-8">
                <DatabaseSearchIcon className="w-6 mx-auto mb-2" />
                <div className="text-xs font-medium">No daily summary</div>
              </div>
            )}
            {(isImageSeriesLoading || !imageSeriesDailySummary) && (
              <div className="p-8">
                <Loading className="text-gray-500 w-10 mx-auto" />
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

const InfoTable: FC<{ date: Date; info: { eventCount: string; imageCount: string } }> = ({ date, info }) => {
  const headers = useMemo(
    () => ({
      date: 'Date',
      eventCount: 'Unique detected events',
      imageCount: 'Total images',
    }),
    [],
  );

  const data = useMemo(
    () => [
      {
        id: null,
        date: moment(date).endOf('day').format(DATETIME_FORMAT),
        eventCount: info.eventCount,
        imageCount: info.imageCount,
      },
    ],
    [date, info],
  );

  return <Table data={data} headers={headers} />;
};

type EventsTable = { events: any[]; onRowSelect: Function; selectedEventId: string };
const EventsTable: FC<EventsTable> = ({ events, onRowSelect, selectedEventId }) => {
  const headers = useMemo(
    () => ({
      date: 'Date',
      trainId: 'Train ID',
      sightingId: 'Sighting ID',
      railways: 'Railway',
      railRoadDirection: 'Timetable direction',
      locomotivesCount: 'Locomotives',
      cars: 'Cars',
      trainType: 'Train Type',
      direction: 'Direction',
      speed: 'Speed',
      warning: 'Warning',
    }),
    [],
  );

  const data = useMemo(() => {
    return events.map((event) => {
      return {
        id: event.sightingId,
        trainId: event.trainId,
        date: moment(event.start * 1000).format(DATETIME_FORMAT),
        sightingId: event.sightingId,
        railways: event.railways,
        railRoadDirection: event.railRoadDirection,
        locomotivesCount: event.locomotivesCount,
        cars: event.cars,
        trainType: event.trainType,
        direction: event.direction,
        speed: event.speed,
        warning: event.info,
      };
    });
  }, [events]);

  return (
    <Table
      canPaginate={true}
      data={data}
      hasTopBorder={true}
      headers={headers}
      onRowSelect={onRowSelect}
      selectedEventId={selectedEventId}
    />
  );
};

type DailySummaryTable = { data: any[] };
const DailySummaryTable: FC<DailySummaryTable> = ({ data }) => {
  const headers = useMemo(
    () => ({
      bin: 'Bin',
      count: 'Count',
    }),
    [],
  );
  data = data.map((i, id) => ({ ...i, id }));

  return <Table canPaginate={true} data={data} headers={headers} />;
};
