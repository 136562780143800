import React from 'react';

import { DeviceConfiguration } from './device-configuration';
import { AppLayout } from '../app.layout';

export const DeviceConfigurationPage = () => {
  return (
    <AppLayout name="Device configuration">
      <DeviceConfiguration />
    </AppLayout>
  );
};
