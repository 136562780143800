import { useState, useEffect, useCallback } from 'react';

import _sortBy from 'lodash/sortBy';

import { useApi } from 'hooks/use-api';

import { SelectOptionType } from 'shared/select';

import moment from 'moment';
import { DEFAULT_IMAGE_LIMIT } from 'constants/defaults';

// Types
import { Camera } from 'types';

export const useLegacyImages = () => {
  const [isLoading, setIsLoading] = useState(false);

  const defaultStartDate = moment().subtract(2, 'days').toDate();
  const defaultEndDate = moment().toDate();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const [selectedCamera, setSelectedCamera] = useState<Camera | null>();
  const [selectedCameraForSelect, setSelectedCameraForSelect] = useState<SelectOptionType & { camera: Camera }>();
  const [{ data: cameras, loading: isCamerasLoading }] = useApi({ url: '/cameras', manual: false });

  const [{ data: imagesRes, loading: isImagesLoading }, getImages] = useApi({
    url: '/images',
  });

  const [images, setImages] = useState<{ thumbnail: string; original: string; description: string }[]>([]);

  const imageSortOptions = [
    { label: 'Created', value: '' },
    { label: 'Camera run ID', value: 'camera_run_id' },
    { label: 'Series index', value: 'series_index' },
  ];
  const [imageSort, setImageSort] = useState(imageSortOptions[0]);
  const selectImageSortFromSelect = useCallback((option) => setImageSort(option), []);

  useEffect(() => {
    if (!imagesRes?.data?.length) return;
    let data = _sortBy(imagesRes.data, imageSort.value ? ['created_at', imageSort.value] : 'created_at');
    data = data.map((i) => ({
      thumbnail: i.previewImageUrl,
      original: i.fullImageUrl,
    }));
    setImages(data);
  }, [imagesRes, imageSort]);

  useEffect(() => {
    const yes = isCamerasLoading || isImagesLoading;
    setIsLoading(yes);
  }, [isCamerasLoading, isImagesLoading]);

  const getImagesFn = useCallback(
    (cameraId) => {
      if (cameraId) {
        getImages({
          params: {
            limit: DEFAULT_IMAGE_LIMIT,
            imageCategory: 'trains',
            cameraId: cameraId,
            start: startDate.getTime() / 1000,
            end: endDate.getTime() / 1000,
          },
        });
      }
    },
    [getImages, startDate, endDate],
  );

  const selectCamera = useCallback(
    (camera: Camera) => {
      setSelectedCamera(camera);
      setSelectedCameraForSelect({ camera, value: camera.id, label: camera.nickname });
      getImagesFn(camera.id);
    },
    [setSelectedCamera, setSelectedCameraForSelect, getImagesFn],
  );

  const selectCameraFromSelect = useCallback(({ camera }) => selectCamera(camera), [selectCamera]);

  const onDateChange = useCallback(([start, end]) => {
    setStartDate(start);
    setEndDate(end);
  }, []);

  const submitForm = useCallback(() => {
    if (selectedCamera) getImagesFn(selectedCamera.id);
  }, [selectedCamera, getImagesFn]);

  return {
    cameras,
    endDate,
    images,
    imageSort,
    imageSortOptions,
    isLoading,
    onDateChange,
    selectCameraFromSelect,
    selectedCamera,
    selectedCameraForSelect,
    selectImageSortFromSelect,
    startDate,
    submitForm,
  };
};
