import { useState, useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useApi } from 'hooks/use-api';

import { useUser } from 'auth.provider';

import { Notify } from 'services/notify';
import { SelectOptionType } from 'shared/select';

import { DEFAULT_LIMIT, DEFAULT_IMAGE_LIMIT, DEFAULT_SEGMENT_LIMIT, DEFAULT_MAP_BOUNDS } from 'constants/defaults';
import { CAR_TYPES } from 'constants/car-types';

import { stringifyMapBounds } from 'utils/stringify-map-bounds';

// Types
import { Camera, CameraConfig, MapBounds, Comment } from 'types';
import { useParams } from 'react-router-dom';

export const useDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const { isReadyOnly = false } = useUser();

  const [mapBounds, setMapBounds] = useState(stringifyMapBounds(DEFAULT_MAP_BOUNDS));
  const [selectedPanelIndex, setSelectedPanelIndex] = useState(0);

  const [newCameraList, setNewCameraList] = useState<Camera[]>([]);
  const [selectedCamera, setSelectedCamera] = useState<Camera | null>();
  const [selectedCameraForSelect, setSelectedCameraForSelect] = useState<SelectOptionType & { camera: Camera }>();
  const [panoramaImages, setPanoramaImages] = useState([]);
  const [images, setImages] = useState<any>([]);
  const [isLoadPanorama, setIsLoadPanorama] = useState(false);
  const [selectedImageSeriesId, setSelectedImageSeriesId] = useState('');
  const [selectedDevice, setSelectedDevice] = useState({});

  const [{ data: segments, loading: isSegmentsLoading }, getSegments] = useApi({ url: '/segments' });
  const [{ data: cameras, loading: isCamerasLoading }, getCameras] = useApi({ url: '/cameras' });
  const [{ data: updatedCamera, loading: isUpdateCameraLoading }, updateCamera] = useApi({
    url: '/infrastructure/sensors',
    method: 'POST',
    isApiV3: true,
  });
  const [{ loading: isUpdateCameraLoadingV2 }, updateCameraV2] = useApi({
    url: '/cameras',
    method: 'PATCH',
  });
  const [{ data: cameraConfig, loading: isCameraConfigLoading }, getCameraConfig] = useApi({ url: '/cameras' });
  const [{ loading: isUpdateCameraConfigLoading }, updateCameraConfig] = useApi({ url: '/cameras' });
  const [{ data: imageSeries, loading: isImageSeriesLoading }, getImageSeries] = useApi({
    url: '/imageSeries',
  });
  const [{ data: imageSeriesInfo, loading: isImageSeriesInfoLoading }, getImageSeriesInfo] = useApi({
    url: '/imageSeries',
  });
  const [{ data: regions, loading: isRegionsLoading }, getRegions] = useApi({
    url: '/infrastructure/administrative_regions',
    isApiV3: true,
  });
  const [{ data: timezones, loading: isTimezonesLoading }, getTimezones] = useApi({
    url: '/infrastructure/timezones',
    isApiV3: true,
  });
  const [{ data: sensors, loading: isSensorsLoading }, getSensors] = useApi({
    url: '/infrastructure/sensors',
    isApiV3: true,
  });
  const [{ data: sensorDetail, loading: isSensorLoading }, getSensor] = useApi({
    url: '/infrastructure/sensors',
    isApiV3: true,
  });
  const [{ data: devices, loading: isDevicesLoading }, getDevices] = useApi({
    url: '/infrastructure/devices',
    isApiV3: true,
  });
  const [{ loading: isStateUpdate }, updateStateUpdate] = useApi({
    url: '/infrastructure/state_updates',
    method: 'PUT',
    isApiV3: true,
  });
  const [{ loading: isDeviceUpdate }, updateDevice] = useApi({
    url: '/infrastructure/devices',
    method: 'PUT',
    isApiV3: true,
  });
  const [{ data: deviceStates }, getDeviceStates] = useApi({
    url: '/infrastructure/device_states',
    method: 'GET',
    isApiV3: true,
  });
  const [{ data: sensorStates }, getSensorStates] = useApi({
    url: '/infrastructure/sensor_states',
    method: 'GET',
    isApiV3: true,
  });
  const [{ data: architectures }, getArchitectures] = useApi({
    url: '/infrastructure/architectures',
    method: 'GET',
    isApiV3: true,
  });
  const [
    { data: imageSeriesDailySummary, loading: isImageSeriesDailySummaryLoading },
    getImageSeriesDailySummary,
  ] = useApi({
    url: '/imageSeries/dailySummary',
  });
  const [{ data: imagesData, loading: isImagesLoading }, getImages] = useApi({
    url: '/images',
  });
  const [{ data: panoramaImagesData, loading: isPanoramaImagesLoading }, getPanoramaImages] = useApi({
    url: '/images/panorama',
  });
  const [{ data: sampleImages, loading: isSampleImagesLoading }, getSampleImages] = useApi({
    url: '/images/sample',
  });
  const [{ data: updatedComment }, updateComment] = useApi({
    url: '/carSight/feedback',
  });

  const carTypeOptions = CAR_TYPES.map((value) => ({ value, label: value }));
  const [carType, setCarType] = useState<SelectOptionType>();
  const selectCarTypeFromSelect = useCallback((option) => setCarType(option), []);

  useEffect(() => {
    const yes = isCamerasLoading || isDevicesLoading || isRegionsLoading || isSensorsLoading || isTimezonesLoading;
    setIsLoading(yes);
  }, [isCamerasLoading, isRegionsLoading, isTimezonesLoading, isSensorsLoading, isDevicesLoading]);

  useEffect(() => {
    setPanoramaImages(panoramaImagesData || []);
    if (panoramaImagesData && panoramaImagesData.length > 0) {
      setIsLoadPanorama(true);
    }
  }, [panoramaImagesData]);
  useEffect(() => {
    setImages(imagesData?.data || []);
  }, [imagesData]);

  const getData = (bounds: MapBounds = DEFAULT_MAP_BOUNDS, cb: Function = () => ({})) => {
    const bbox = stringifyMapBounds(bounds);
    setMapBounds(bbox);
    // getCameras({ params: { limit: DEFAULT_LIMIT, bbox } });
    getSegments({ params: { limit: DEFAULT_SEGMENT_LIMIT, bbox } });
    cb();
  };

  useEffect(() => {
    getCameras();
    getDevices();
    getDeviceStates();
    getRegions();
    getSensors();
    getSensorStates();
    getArchitectures();
    getTimezones();
  }, [
    getArchitectures,
    getCameras,
    getDevices,
    getDeviceStates,
    getRegions,
    getSensors,
    getSensorStates,
    getTimezones,
  ]);

  const selectCamera = useCallback(
    (camera: Camera | null, getDetail = true) => {
      setSelectedCamera(camera);
      setSelectedDevice({});
      setSelectedCameraForSelect(
        camera && camera?.id ? { camera, value: camera.id, label: `${camera.nickname} (${camera.id})` } : undefined,
      );
      localStorage.removeItem('cameraData');
      localStorage.setItem('cameraData', JSON.stringify(camera));

      if (!!camera?.id && getDetail) {
        getCameraConfig({ url: `/cameras/${camera.id}/configs` });
        getSensor({ url: `/infrastructure/sensors/${camera.id}` });
      }
    },
    [setSelectedCamera, setSelectedCameraForSelect, getCameraConfig, getSensor],
  );

  const onMapDblClick = (e: Partial<{ latLng: { toJSON: Function } }>) => {
    if (!e) return;
    const newCamera = Object.assign({ nickname: '', distance: 0, orientation: 0 }, e.latLng?.toJSON());

    setSelectedCameraForSelect(undefined);
    selectCamera(newCamera);
    setSelectedDevice({});
    setNewCameraList([newCamera]);
  };

  useEffect(() => {
    if (sensorDetail && Number(sensorDetail?.id) === Number(selectedCamera?.id)) {
      const newCamera = sensorDetail.parameters;
      newCamera.id = sensorDetail.id;
      newCamera.stateUpdates = sensorDetail?.stateUpdates;
      newCamera.status = selectedCamera?.status;
      selectCamera(newCamera, false);
    }
  }, [sensorDetail, selectedCamera, selectCamera]);

  const { siteID } = useParams();
  useEffect(() => {
    if (cameras && cameras?.data && sensors && sensors?.sensors) {
      sensors.sensors.map((item: { id: any; status: any; parameters: any }) => {
        const sameItem = cameras.data.find((c: { id: any }) => Number(c?.id) === Number(item?.id));
        item.parameters.status = sameItem?.status || null;
        item = Object.assign(item, item.parameters);
        return item;
      });
      sensors['data'] = sensors.sensors;

      if (siteID) {
        const openSite = sensors['data'].find((v: { id: any }) => Number(v?.id) === Number(siteID));
        if (openSite) {
          selectCamera(openSite);
        } else {
          localStorage.removeItem('cameraData');
        }
        window.history.pushState({}, '', '/operator-dashboard');
      }
    }
  }, [cameras, sensors, siteID, selectCamera]);

  useEffect(() => {
    const cameraData = localStorage.getItem('cameraData') ?? '';
    const selectedIdx = localStorage.getItem('SelectedPanelIndex') ?? '';
    if (cameraData) {
      const camData = JSON.parse(cameraData);
      if (camData && camData?.id) {
        selectCamera(camData);
      } else {
        localStorage.removeItem('cameraData');
      }
    }
    if (selectedIdx) {
      const newIndex = JSON.parse(selectedIdx);
      setSelectedPanelIndex(newIndex);
    }
    return () => {
      localStorage.clear();
    };
  }, [selectCamera]);

  const getPositionForCamera = (camera: Camera) => {
    return { lat: camera.lat, lng: camera.lng };
  };

  const humanize = (str: string) => {
    if (!str) return;
    const output = str.replace(/_/g, ' ');
    return output.substring(0, 1).toUpperCase() + output.substring(1);
  };

  useEffect(() => {
    if (updatedCamera) selectCamera(updatedCamera);
  }, [updatedCamera, selectCamera]);

  const saveCamera = (camera: Camera | null) => {
    const isExisting = !!camera?.id;
    const url = '/infrastructure/sensors' + (isExisting ? `/${camera?.id}` : '');
    const method = isExisting ? 'PUT' : 'POST';
    let newId: any = null;

    const data = {
      nickname: camera?.nickname || null,
      lat: camera?.lat ? Number(camera.lat) : null,
      lng: camera?.lng ? Number(camera.lng) : null,
      orientation: camera?.orientation ? Number(camera.orientation) : 0,
      leftDirection: camera?.leftDirection || null,
      uploadUri: camera?.uploadUri || null,
      inProduction: camera?.inProduction || false,
      railways: camera?.railways || null,
      administrativeRegion: camera?.administrativeRegion
        ? { id: camera?.administrativeRegion, country: '', state: '' }
        : null,
      timezone: camera?.timezone ? { id: camera?.timezone, timezone: '' } : null,
      numTracks: camera?.numTracks !== null ? Number(camera?.numTracks) : null,
      trackDistanceMeters: camera?.trackDistanceMeters !== null ? Number(camera?.trackDistanceMeters) : null,
      customerSensorCode: camera?.customerSensorCode || null,
    };

    updateCamera({
      url,
      method,
      data: isExisting ? data : { parameters: data, initialState: { sensorState: 'PLANNED' } },
    })
      .then((result) => {
        newId = result?.data && result?.data?.id > 0 ? result.data.id : null;

        const dataV2 = {
          id: isExisting ? camera?.id : null,
          lat: camera?.lat ? Number(camera.lat) : null,
          lng: camera?.lng ? Number(camera.lng) : null,
          distance: Number(camera?.distance) || null,
          nickname: camera?.nickname || null,
          orientation: camera?.orientation ? Number(camera.orientation) : 0,
          isConfigAllowed: camera?.isConfigAllowed || null,
          railways: camera?.railways || null,
          leftDirection: camera?.leftDirection || null,
          status: camera?.status || null,
        };

        return camera?.status || !isExisting
          ? updateCameraV2({
              url: `/cameras${camera?.id ? '/' + camera.id : ''}`,
              method: isExisting ? 'PATCH' : 'POST',
              data: dataV2,
            }).catch((error) => {
              const message = 'Failed to save into api/v2.' + (error ? ' ' + String(error) : '');
              addToast(message, { appearance: 'error', autoDismiss: true });
              console.error(message);
            })
          : null;
      })
      .then(() => getSensors())
      .then(() => getCameras({ params: { limit: DEFAULT_LIMIT, bbox: mapBounds } }))
      .then(() => {
        setNewCameraList([]);
        selectCamera(newId ? Object.assign({}, camera, { id: newId }) : camera);
        addToast('Saved successfully', { appearance: 'success', autoDismiss: true });
      })
      .catch((error) => {
        const message = 'Failed to save.' + (error ? ' ' + String(error) : '');
        addToast(message, { appearance: 'error', autoDismiss: true });
        console.error(message);
      });
  };

  const onStateSubmit = (record: any) => {
    const url = '/infrastructure/state_updates';
    const method = 'POST';

    const data = {
      id: null,
      sensorId: record.sensorId,
      deviceId: record.deviceId,
      sensorState: record?.sensorState || null,
      deviceState: record?.deviceState || null,
      info: record?.info || null,
    };

    updateStateUpdate({ url, method, data })
      .then(() => selectCamera(selectedCamera || null))
      .then(() => getSensors())
      .then(() => getCameras({ params: { limit: DEFAULT_LIMIT, bbox: mapBounds } }))
      .then(() => {
        setNewCameraList([]);
        addToast('Saved successfully', { appearance: 'success', autoDismiss: true });
      })
      .catch((error) => {
        const message = 'Failed to save.' + (error ? ' ' + String(error) : '');
        addToast(message, { appearance: 'error', autoDismiss: true });
        console.error(message);
      });
  };

  const onDeviceSubmit = (record: any) => {
    if (!record?.deviceId) {
      addToast('Failed to save device.', { appearance: 'error', autoDismiss: true });
      return;
    }

    const url = `/infrastructure/devices/${record?.deviceId}`;
    const method = 'PUT';
    const data = {
      architecture: record?.architecture || null,
      sshTunnelPort: Number(record?.sshTunnelPort) || null,
      teltonika: {
        imei: record?.imei || null,
        lanMac: record?.lanMac || null,
        serialNumber: record?.serialNumber || null,
        simNumber: record?.simNumber || null,
      },
      info: record?.info || null,
      hardwareComponents: record?.hardwareComponents || null,
    };

    updateDevice({ url, method, data })
      .then(() => getDevices())
      .then(() => getSensors())
      .then(() => getCameras({ params: { limit: DEFAULT_LIMIT, bbox: mapBounds } }))
      .then(() => {
        setNewCameraList([]);
        addToast('Saved successfully', { appearance: 'success', autoDismiss: true });
      })
      .catch(() => {
        addToast('Failed to save.', { appearance: 'error', autoDismiss: true });
      });
  };

  const deleteCamera = (cameraId: number) => {
    Notify.confirm({
      title: 'Delete record',
      text: 'Are you sure you want to proceed?',
      isDestructive: true,
    }).then(({ isDismissed }) => {
      if (isDismissed) return;
      updateCamera({ url: `/infrastructure/sensors/${cameraId}`, method: 'DELETE' })
        .then(() => getDevices())
        .then(() => getSensors())
        .then(() => getCameras({ params: { limit: DEFAULT_LIMIT, bbox: mapBounds } }))
        .then(() => {
          selectCamera(null);
          addToast('Deleted successfully', { appearance: 'success', autoDismiss: true });
        })
        .catch(() => {
          addToast('Failed to delete.', { appearance: 'error', autoDismiss: true });
        });
    });
  };

  const saveCameraConfig = (uid: number, data: CameraConfig[]) => {
    updateCameraConfig({ url: `/cameras/${uid}/configs`, method: 'PATCH', data })
      .then(() => {
        addToast('Configuration saved successfully', { appearance: 'success', autoDismiss: true });
      })
      .catch(() => {
        addToast('Failed to save configuration.', { appearance: 'error', autoDismiss: true });
      });
  };

  const getImagesFn = useCallback(
    (cameraId, sightingId) => {
      getImages({
        params: {
          cameraId: cameraId,
          carType: carType?.value,
          limit: DEFAULT_IMAGE_LIMIT,
          sightingId: sightingId,
        },
      });

      setPanoramaImages([]);
      setIsLoadPanorama(true);
      getPanoramaImages({
        params: {
          cameraId: cameraId,
          carType: carType?.value,
          limit: DEFAULT_IMAGE_LIMIT,
          sightingId: sightingId,
        },
      });
    },
    [getImages, getPanoramaImages, carType],
  );

  const getImageSeriesFn = useCallback(
    (cameraId, start, end) => {
      if (!cameraId) {
        return;
      }

      const params = {
        cameraId,
        end,
        imageCategory: 'trains',
        limit: DEFAULT_IMAGE_LIMIT,
        start,
      };
      getImageSeriesInfo({ params: { ...params, type: 'eventInfo' } });
      getImageSeries({ params: params })
        .then(({ data: { data } }) => {
          if (data?.length) {
            setSelectedImageSeriesId(String(data[0].sightingId));
            getImagesFn(cameraId, data[0].sightingId);
          }
        })
        .catch((error) => {
          const message = 'Failed to load images.' + (error ? ' ' + String(error) : '');
          addToast(message, { appearance: 'error', autoDismiss: true });
          console.error(message);
        });

      getImageSeriesDailySummary({ params: { cameraId, start, end } });
    },
    [getImageSeriesInfo, getImageSeries, getImagesFn, getImageSeriesDailySummary, addToast],
  );

  const getSampleImagesFn = useCallback(
    (cameraId, start, end) => {
      getSampleImages({
        params: {
          cameraId: cameraId,
          end,
          limit: DEFAULT_IMAGE_LIMIT,
          start,
        },
      });
    },
    [getSampleImages],
  );
  const getSelectedIndex = useCallback((i, l) => {
    localStorage.removeItem('SelectedPanelIndex');
    localStorage.setItem('SelectedPanelIndex', JSON.stringify(i));
    setSelectedPanelIndex(i);
  }, []);
  const onSaveComment = (commentData: Comment, user?: any) => {
    if (commentData) {
      const url = '/carSight/feedback';
      const method = 'POST';
      updateComment({ url, method, data: commentData })
        .then(() => {
          addToast('Comment Added successfully', { appearance: 'success', autoDismiss: true });
        })
        .catch(() => {
          addToast('Failed to Add Comment', { appearance: 'error', autoDismiss: true });
        });
    }
    const Obj: any = images && images.length > 0 && images[parseInt(commentData?.idx)];
    if (Obj) {
      const f = Obj.comment ? Object.keys(Obj.comment).find((e) => e === user?.id) : '';
      if (f) {
        const newImage = images.map((v: any, i: number) => {
          if (i === parseInt(commentData.idx)) {
            v.comment[user.id].comment = commentData.comment;
          }
          return v;
        });
        setImages(newImage);
      } else {
        const newImage = images.map((v: any, i: number) => {
          if (i === parseInt(commentData.idx)) {
            const newObj = {
              comment: commentData.comment,
              email: user.email,
              name: user.displayName,
              timestamp: new Date(),
            };
            v.comment = {};
            v.comment[user.id] = newObj;
          }
          return v;
        });
        setImages(newImage);
      }
    }
  };

  return {
    architectures,
    cameraConfig,
    cameras,
    carType,
    carTypeOptions,
    deleteCamera,
    devices,
    deviceStates,
    getData,
    getImageSeriesFn,
    getImagesFn,
    getPositionForCamera,
    getSampleImagesFn,
    getSelectedIndex,
    humanize,
    images,
    imageSeries,
    imageSeriesDailySummary,
    imageSeriesInfo,
    isCameraConfigLoading,
    isDevicesLoading,
    isDeviceUpdate,
    isImageSeriesDailySummaryLoading,
    isImageSeriesInfoLoading,
    isImageSeriesLoading,
    isImagesLoading,
    isLoading,
    isLoadPanorama,
    isPanoramaImagesLoading,
    isReadyOnly,
    isSampleImagesLoading,
    isSegmentsLoading,
    isSensorLoading,
    isStateUpdate,
    isUpdateCameraConfigLoading,
    isUpdateCameraLoading,
    isUpdateCameraLoadingV2,
    newCameraList,
    onDeviceSubmit,
    onMapDblClick,
    onSaveComment,
    onStateSubmit,
    panoramaImages,
    regions,
    sampleImages,
    saveCamera,
    saveCameraConfig,
    segments,
    selectCamera,
    selectCarTypeFromSelect,
    selectedCamera,
    selectedCameraForSelect,
    selectedDevice,
    selectedImageSeriesId,
    selectedPanelIndex,
    sensorDetail,
    sensors,
    sensorStates,
    setIsLoadPanorama,
    setSelectedDevice,
    setSelectedImageSeriesId,
    timezones,
    updatedComment,
  };
};
