import React, { FC, useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import { ReactComponent as Loading } from 'assets/images/loading.svg';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as PictureIcon } from 'assets/icons/picture-stack-landscape.svg';
import { ReactComponent as PictureWarningIcon } from 'assets/icons/image-file-warning.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/button-refresh-arrows.svg';

import { Card } from 'shared/card';
import { Gallery } from 'shared/gallery';

import { SampleImageMeta } from './sample-image-meta';

import moment from 'moment';
import { DATEPICKER_FORMAT } from 'constants/formats';

const styleTop6 = { top: '6rem' };
const styleMinHeight250 = { minHeight: '250px' };

type SampleImages = {
  cameraId: number;
  getSampleImages: Function;
  sampleImages: any[];
  isSampleImagesLoading: boolean;
};
export const SampleImages: FC<SampleImages> = ({ cameraId, getSampleImages, sampleImages, isSampleImagesLoading }) => {
  const defaultDate = moment().toDate();
  const [date, setDate] = useState(defaultDate);
  const [selectedImage, setSelectedImage] = useState();

  const onDateChange = useCallback((d: Date) => {
    setDate(d);
  }, []);

  const getSampleImagesCb = useCallback(() => {
    const start = moment(date).startOf('day').valueOf();
    const end = moment(date).endOf('day').valueOf();
    getSampleImages(cameraId, start, end);
  }, [cameraId, date, getSampleImages]);

  useEffect(() => {
    if (!cameraId) return;
    getSampleImagesCb();
  }, [cameraId, getSampleImagesCb]);

  const handleTodayDate = useCallback(() => {
    setDate(moment().toDate());
  }, []);

  const selectImage = useCallback((image) => {
    setSelectedImage(image);
  }, []);

  const handleRefresh = useCallback(
    (e) => {
      e.preventDefault();
      getSampleImagesCb();
    },
    [getSampleImagesCb],
  );

  return (
    <div className="xl:flex">
      <div className="w-full xl:w-3/5 relative">
        <div className="sticky" style={styleTop6}>
          <Card title="Images" icon={<PictureIcon />}>
            <div style={styleMinHeight250}>
              {isSampleImagesLoading && (
                <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                  <Loading className="text-gray-500 w-16 mx-auto my-20" />
                </div>
              )}
              {!isSampleImagesLoading && !sampleImages?.length && (
                <div className="text-gray-600 text-center p-20">
                  <PictureWarningIcon className="w-10 mx-auto mb-6" />
                  No images
                </div>
              )}
              {!!sampleImages?.length && (
                <>
                  <Gallery
                    images={sampleImages.map((i) => ({
                      thumbnail: i.previewImageUrl,
                      original: i.fullImageUrl,
                      data: i,
                    }))}
                    onSlide={selectImage}
                  />
                  {selectedImage && <SampleImageMeta image={selectedImage} />}
                </>
              )}
            </div>
          </Card>
        </div>
      </div>
      <div className="w-full xl:w-2/5">
        <div className="pt-8 xl:pl-8 xl:pt-0">
          <div className="mb-8">
            <Card title="Selected date" icon={<CalendarIcon />}>
              <div className="flex items-center p-4">
                <DatePicker selected={date} onChange={onDateChange} dateFormat={DATEPICKER_FORMAT} />
                {!isSampleImagesLoading && (
                  <div className="flex items-center justify-between w-full text-gray-600 cursor-pointer p-4">
                    <div className="text-sm" onClick={handleTodayDate}>
                      Today
                    </div>
                    <RefreshIcon className="w-4" onClick={handleRefresh} />
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
