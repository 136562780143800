import { useCallback, useState, useEffect } from 'react';

import _find from 'lodash/find';

import moment from 'moment';

import { useApi } from 'hooks/use-api';

import { SelectOptionType } from 'shared/select';

import { DEFAULT_IMAGE_LIMIT } from 'constants/defaults';

// Types
import { Camera } from 'types';

export const useUniqueTrainsDashboard = () => {
  const UNIQUE_TRAINS_LIMIT = 20;

  const defaultStartDate = moment().startOf('day').toDate();
  const defaultEndDate = moment().endOf('day').toDate();

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const [selectedTrainId, setSelectedTrainId] = useState();
  const [selectedSightingId, setSelectedSightingId] = useState('');
  const [uniqueTrainsPageNumber, setUniqueTrainsPageNumber] = useState(0);

  const [panoramaImages, setPanoramaImages] = useState([]);

  const [{ data: uniqueTrains, loading: isUniqueTrainsLoading }, getUniqueTrains] = useApi({
    url: 'trains/unique',
    manual: false,
    params: {
      end: endDate.getTime(),
      limit: UNIQUE_TRAINS_LIMIT,
      skip: uniqueTrainsPageNumber * UNIQUE_TRAINS_LIMIT,
      start: startDate.getTime(),
    },
  });

  const [{ data: trainSighting, loading: isTrainSightingLoading }, getTrainSighting] = useApi({
    url: 'trains/sighting',
  });

  const [{ data: images, loading: isImagesLoading }, getImages] = useApi({
    url: '/images',
  });
  const [{ data: panoramaImagesData, loading: isPanoramaImagesLoading }, getPanoramaImages] = useApi({
    url: '/images/panorama',
  });

  useEffect(() => {
    setPanoramaImages(panoramaImagesData?.images || []);
  }, [panoramaImagesData]);

  const [{ data: cameras }] = useApi({ url: '/cameras', manual: false });

  const [selectedCameraForSelect, setSelectedCameraForSelect] = useState<SelectOptionType & { camera: Camera }>();
  const selectCameraFromSelect = useCallback(
    ({ camera }) => {
      setUniqueTrainsPageNumber(0);
      setSelectedCameraForSelect({ camera, value: camera.id, label: camera.nickname });
    },
    [setSelectedCameraForSelect],
  );

  const getImagesFn = useCallback(
    (cameraId, sightingId) => {
      setPanoramaImages([]);
      setSelectedSightingId(sightingId);

      getImages({
        params: {
          cameraId: cameraId,
          limit: DEFAULT_IMAGE_LIMIT,
          sightingId: sightingId,
        },
      });

      getPanoramaImages({
        params: {
          cameraId: cameraId,
          limit: DEFAULT_IMAGE_LIMIT,
          sightingId: sightingId,
        },
      });
    },
    [getImages, getPanoramaImages],
  );

  const handleSightingSelect = useCallback(
    (sightingId) => {
      const cameraId = !!selectedCameraForSelect?.value ? selectedCameraForSelect?.value : null;

      setSelectedSightingId(sightingId);
      getImagesFn(cameraId, sightingId);
    },
    [selectedCameraForSelect, getImagesFn],
  );

  const handleTrainSelect = useCallback(
    (trainId) => {
      const cameraId = !!selectedCameraForSelect?.value ? selectedCameraForSelect?.value : null;
      setSelectedTrainId(trainId);
      getTrainSighting({ params: { trainId, cameraId } }).then((r) => {
        let data = r && r.data && r.data.data;
        if (data && data?.length) getImagesFn(cameraId, data[0].sightingId);
      });
    },
    [selectedCameraForSelect, getImagesFn, getTrainSighting],
  );

  const clearTrainSelection = useCallback(() => {
    setSelectedTrainId(undefined);
  }, []);

  const gotoNextPage = useCallback(() => {
    if (uniqueTrains?.data?.length < 20) return;
    setUniqueTrainsPageNumber(uniqueTrainsPageNumber + 1);
  }, [uniqueTrainsPageNumber, uniqueTrains]);

  const gotoPreviousPage = useCallback(() => {
    if (!uniqueTrainsPageNumber) return;
    setUniqueTrainsPageNumber(uniqueTrainsPageNumber - 1);
  }, [uniqueTrainsPageNumber]);

  const getCameraNameById = useCallback(
    (id) => {
      if (!cameras?.data?.length) return 'Not found';
      const cam = _find(cameras.data, { id });
      return cam ? cam.nickname : 'Not found';
    },
    [cameras],
  );

  useEffect(() => {
    const cameraId = !!selectedCameraForSelect?.value ? selectedCameraForSelect?.value : null;

    getUniqueTrains({
      params: {
        cameraId,
        end: endDate.getTime(),
        limit: UNIQUE_TRAINS_LIMIT,
        skip: uniqueTrainsPageNumber * UNIQUE_TRAINS_LIMIT,
        start: startDate.getTime(),
      },
    });

    if (selectedTrainId) {
      getTrainSighting({ params: { trainId: selectedTrainId, cameraId } }).then((r) => {
        let data = r && r.data && r.data.data;
        if (data && data?.length) getImagesFn(cameraId, data[0].sightingId);
      });
    }
  }, [
    endDate,
    getImagesFn,
    getTrainSighting,
    getUniqueTrains,
    selectedCameraForSelect,
    selectedTrainId,
    startDate,
    uniqueTrainsPageNumber,
  ]);

  const onStartDateChange = useCallback((date) => {
    setUniqueTrainsPageNumber(0);
    setStartDate(date);
  }, []);

  const onEndDateChange = useCallback((date) => {
    setUniqueTrainsPageNumber(0);
    setEndDate(date);
  }, []);

  return {
    cameras,
    clearTrainSelection,
    endDate,
    getCameraNameById,
    gotoNextPage,
    gotoPreviousPage,
    handleSightingSelect,
    handleTrainSelect,
    images,
    isImagesLoading,
    isPanoramaImagesLoading,
    isTrainSightingLoading,
    isUniqueTrainsLoading,
    onEndDateChange,
    onStartDateChange,
    panoramaImages,
    selectCameraFromSelect,
    selectedCameraForSelect,
    selectedSightingId,
    selectedTrainId,
    startDate,
    trainSighting,
    UNIQUE_TRAINS_LIMIT,
    uniqueTrains,
    uniqueTrainsPageNumber,
  };
};
