import React, { FC, useMemo, useState, useEffect, useCallback, FormEvent } from 'react';
import _map from 'lodash/map';
import { Card } from 'shared/card';
import { Checkbox } from 'shared/checkbox';
import { isValidDeg, degToDir, convertDegToDMS } from 'utils/deg-to-dir';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { Select, SelectOptionType } from 'shared/select';
import useFormFields from '@usereact/use-form-fields';

// Types
import { Camera } from 'types';

type CameraPlacementForm = {
  camera: Camera;
  getCameraStatusLabel: Function;
  isLoading: boolean;
  isReadyOnly: boolean;
  onDelete: Function;
  onSubmit: Function;
  regions: any;
  timezones: any;
};
export const CameraPlacementForm: FC<CameraPlacementForm> = ({
  camera,
  getCameraStatusLabel,
  isLoading,
  isReadyOnly,
  onDelete,
  onSubmit,
  regions,
  timezones,
}) => {
  const feetToMeters = 0.3048;
  const [id, setId] = useState<number | null>();

  const { values, fields, setValue } = useFormFields({
    distance: '',
    nickname: '',
    orientation: '',
    lat: '',
    lng: '',
    uploadUri: '',
    numTracks: '',
    trackDistanceMeters: '',
    customerSensorCode: '',
  });

  const railwayOptions = useMemo(
    () => [
      { value: 'CPRS', label: 'CPRS' },
      { value: 'CN', label: 'CN' },
      { value: 'BNSF', label: 'BNSF' },
      { value: 'UP', label: 'UP' },
      { value: 'CSXT', label: 'CSXT' },
      { value: 'NS', label: 'NS' },
      { value: 'KCS', label: 'KCS' },
      { value: 'IHB', label: 'IHB' },
      { value: 'SRY', label: 'SRY' },
    ],
    [],
  );

  const leftDirectionOptions = useMemo(
    () => [
      { value: 'Eastbound', label: 'Eastbound' },
      { value: 'Westbound', label: 'Westbound' },
      { value: 'Northbound', label: 'Northbound' },
      { value: 'Southbound', label: 'Southbound' },
    ],
    [],
  );
  const [railways, setRailways] = useState<SelectOptionType[]>([]);
  const onRailwaysChange = useCallback((v) => setRailways(v), []);

  const [leftDirection, setLeftDirection] = useState<SelectOptionType>();
  const onLeftDirectionChange = useCallback((v) => setLeftDirection(v), []);

  const [isConfigAllowed, setIsConfigAllowed] = useState<boolean>();
  // const onIsConfigAllowedChange = useCallback((_, v) => setIsConfigAllowed(v), []);

  const [inProduction, setInProduction] = useState<boolean>(!!camera?.inProduction);
  const oninProductionChange = useCallback((_, v) => setInProduction(v), []);

  const statusOptions = useMemo(
    () => [
      { value: 'active', label: 'Active' },
      { value: 'transmitting', label: 'Transmitting' },
      { value: 'connected', label: 'Connected' },
      { value: 'disconnected', label: 'Disconnected' },
      { value: 'operator_mode', label: 'Operator mode' },
    ],
    [],
  );
  const [status, setStatus] = useState<SelectOptionType>();
  const onStatusChange = useCallback((v) => setStatus(v), [setStatus]);

  const provinceList: SelectOptionType[] =
    regions && regions?.administrativeRegions
      ? regions.administrativeRegions.map((v: { id: number; country: string; state: string }) => {
          return { value: v.id, label: `${v.country} - ${v.state}` };
        })
      : [];

  const [administrativeRegion, setProvince] = useState<SelectOptionType>();
  const onProvinceChange = useCallback((v) => setProvince(v), [setProvince]);

  const timezoneList: SelectOptionType[] =
    timezones && timezones?.timezones
      ? timezones.timezones.map((v: { id: number; timezone: string }) => {
          return { value: v.id, label: v.timezone };
        })
      : [];

  const [timezone, setTimezone] = useState<SelectOptionType>();
  const onTimezoneChange = useCallback((v) => setTimezone(v), [setTimezone]);

  useEffect(() => {
    if (camera?.id && camera?.id === id) return;
    setId(camera?.id ? camera.id : null);

    setValue('distance', String(camera?.distance || ''));
    setValue('nickname', camera.nickname || '');
    setValue('orientation', String(camera?.orientation !== null ? camera?.orientation : ''));
    setValue('lat', String(camera?.lat !== null ? camera?.lat : ''));
    setValue('lng', String(camera?.lng !== null ? camera?.lng : ''));
    setValue('uploadUri', String(camera?.uploadUri || ''));
    setValue('numTracks', String(camera?.numTracks !== null ? camera?.numTracks : ''));
    setValue(
      'trackDistanceMeters',
      String(camera?.trackDistanceMeters !== null ? Number(camera?.trackDistanceMeters) / feetToMeters : ''),
    );
    setValue('customerSensorCode', String(camera?.customerSensorCode || ''));

    setIsConfigAllowed(!!camera?.isConfigAllowed);
    setInProduction(!!camera?.inProduction);

    setRailways(camera.railways ? camera.railways.split(',').map((value) => ({ value, label: value })) : []);
    setLeftDirection({ value: camera.leftDirection, label: camera.leftDirection });
    setStatus({ value: camera.status, label: getCameraStatusLabel(camera.status) });
  }, [camera, id, setValue, getCameraStatusLabel]);

  const provinceId = !!camera?.administrativeRegion?.id ? Number(camera?.administrativeRegion?.id) : null;
  const timezoneId = !!camera?.timezone?.id ? Number(camera?.timezone?.id) : null;

  useEffect(() => {
    if (!regions || !timezones) return;

    const provinceDefault =
      provinceId && regions && regions?.administrativeRegions
        ? regions?.administrativeRegions.find((v: { id: any }) => Number(v.id) === provinceId)
        : null;

    const timezoneDefault =
      timezoneId && timezones && timezones?.timezones
        ? timezones?.timezones.find((v: { id: any }) => Number(v.id) === timezoneId)
        : null;

    setProvince(
      provinceDefault
        ? { value: provinceDefault.id, label: `${provinceDefault.country} - ${provinceDefault.state}` }
        : { value: '', label: '' },
    );

    setTimezone(
      timezoneDefault ? { value: timezoneDefault.id, label: timezoneDefault.timezone } : { value: '', label: '' },
    );
  }, [regions, timezones, provinceId, timezoneId]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit({
        id,
        ...values,
        isConfigAllowed,
        inProduction,
        railways: _map(railways, 'value').join(','),
        leftDirection: leftDirection?.value,
        status: status?.value,
        administrativeRegion: administrativeRegion?.value,
        timezone: timezone?.value,
        trackDistanceMeters: values?.trackDistanceMeters ? Number(values?.trackDistanceMeters) * feetToMeters : null,
      });
    },
    [
      id,
      inProduction,
      isConfigAllowed,
      leftDirection,
      onSubmit,
      administrativeRegion,
      railways,
      status,
      timezone,
      values,
    ],
  );

  const handleDelete = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onDelete(id);
    },
    [id, onDelete],
  );
  const isOrientationValid = isValidDeg(Number(values.orientation));
  const isExisting = !!camera.id;
  const isDeleteAllowed = false;
  const lastState = camera?.stateUpdates && camera?.stateUpdates?.length > 0 ? camera.stateUpdates.slice(-1)[0] : null;

  return (
    <Card icon={<CameraIcon />} title={isExisting ? 'Site detail' : 'Add site'}>
      <form className="text-sm" onSubmit={handleSubmit}>
        <div className="pt-4 px-4">
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
              <label className="block mb-1">Site name</label>
              <input
                type="text"
                readOnly={isReadyOnly}
                className="p-3 placeholder-gray-400 border border-gray-400 w-full"
                placeholder="Site name"
                {...fields.nickname}
              />
            </fieldset>
            <fieldset className="w-full sm:w-1/2 mb-6">
              <label className="block mb-1">Legacy status</label>
              {!!camera?.status || !camera?.id ? (
                <Select options={statusOptions} value={status} onChange={onStatusChange} isReadyOnly={isReadyOnly} />
              ) : (
                <input
                  className="p-3 placeholder-gray-400 border border-gray-400 w-full pr-0 bg-gray-100"
                  disabled={true}
                  placeholder="- empty - "
                  type="text"
                  value=""
                />
              )}
            </fieldset>
          </div>
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
              <label className="block mb-1">Railways</label>
              <Select
                isMulti={true}
                isReadyOnly={isReadyOnly}
                onChange={onRailwaysChange}
                options={railwayOptions}
                value={railways}
              />
            </fieldset>
            <fieldset className="w-full sm:w-1/2 mb-6">
              Country and region
              <Select
                options={useMemo(() => provinceList, [provinceList])}
                value={administrativeRegion}
                onChange={onProvinceChange}
                isReadyOnly={isReadyOnly}
              />
            </fieldset>
          </div>
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
              <label className="flex items-center justify-between mb-1">
                Latitude
                <div className="text-xs text-gray-600">{values.lat && convertDegToDMS(values.lat)}</div>
              </label>
              <input
                type="number"
                step="0.0000001"
                readOnly={isReadyOnly}
                className={'p-3 placeholder-gray-400 border w-full'}
                placeholder="Latitude"
                {...fields.lat}
              />
            </fieldset>
            <fieldset className="w-full sm:w-1/2 mb-6">
              <label className="flex items-center justify-between mb-1">
                Longitude
                <div className="text-xs text-gray-600">{values.lng && convertDegToDMS(values.lng, true)}</div>
              </label>
              <input
                type="number"
                step="0.0000001"
                readOnly={isReadyOnly}
                className={'p-3 placeholder-gray-400 border w-full'}
                placeholder="Longitude"
                {...fields.lng}
              />
            </fieldset>
          </div>
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
              <label className="block mb-1">Left direction</label>
              <Select
                options={leftDirectionOptions}
                value={leftDirection}
                onChange={onLeftDirectionChange}
                isReadyOnly={isReadyOnly}
              />
              <div className="text-xs text-gray-600 italic mt-2">
                Movement Right to Left in site frame is railroad timetable direction
              </div>
            </fieldset>
            <fieldset className="w-full sm:w-1/2 mb-6">
              <label className="flex items-center justify-between mb-1">
                Orientation
                {values.orientation && (
                  <div className="text-xs text-gray-600">
                    {values.orientation}&deg; ({degToDir(Number(values.orientation))})
                  </div>
                )}
              </label>
              <input
                type="text"
                readOnly={isReadyOnly}
                className={
                  'p-3 placeholder-gray-400 border w-full ' +
                  (isOrientationValid ? 'border-gray-400' : 'border-red-400')
                }
                placeholder="Orientation"
                {...fields.orientation}
              />
            </fieldset>
          </div>
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
              Timezone
              <Select
                options={useMemo(() => timezoneList, [timezoneList])}
                value={timezone}
                onChange={onTimezoneChange}
                isReadyOnly={isReadyOnly}
              />
            </fieldset>
            <fieldset className="w-full sm:w-1/2 mb-6">
              <label className="block mb-1">Upload URI</label>
              <input
                type="text"
                readOnly={isReadyOnly}
                className={'p-3 placeholder-gray-400 border w-full'}
                placeholder="Upload URI"
                {...fields.uploadUri}
              />
            </fieldset>
          </div>
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
              <label className="block mb-1">Number of monitored railroad tracks</label>
              <input
                type="number"
                readOnly={isReadyOnly}
                className={'p-3 placeholder-gray-400 border w-full'}
                placeholder="Number of monitored railroad tracks"
                {...fields.numTracks}
              />
            </fieldset>
            <fieldset className="w-full sm:w-1/2 mb-6">
              <label className="flex items-center justify-between mb-1">
                Distance from tracks (in feet)
                <div className="text-xs text-gray-600">
                  ({Math.round(Number(values.trackDistanceMeters) * feetToMeters * 100) / 100} meters)
                </div>
              </label>
              <input
                type="number"
                readOnly={isReadyOnly}
                className={'p-3 placeholder-gray-400 border w-full'}
                placeholder="Distance from tracks (in feet)"
                {...fields.trackDistanceMeters}
              />
            </fieldset>
          </div>
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 mb-6">
              <label className="block mb-1">Customer sensor code</label>
              <input
                type="text"
                readOnly={isReadyOnly}
                className={'p-3 placeholder-gray-400 border w-full'}
                placeholder="Customer sensor code"
                {...fields.customerSensorCode}
              />
            </fieldset>
          </div>
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-3 mt-2">
              <Checkbox
                isReadyOnly={isReadyOnly}
                label="Running in the production mode"
                onChange={oninProductionChange}
                uid="inProduction"
                value={inProduction}
              />
            </fieldset>
            <fieldset className="w-full sm:w-1/2 mb-6"></fieldset>
          </div>

          {!!lastState && !!lastState.sensorState && (
            <div className="block sm:flex">
              <fieldset className="w-full sm:w-1/2 mr-0 sm:mr-6 mb-6">
                <label className="block mb-1">Current state</label>
                <input
                  className="p-3 placeholder-gray-400 border border-gray-400 w-full pr-0 bg-gray-100"
                  disabled={true}
                  placeholder="Current state"
                  type="text"
                  value={!!lastState?.sensorState ? getCameraStatusLabel(lastState.sensorState.toLowerCase()) : ''}
                />
              </fieldset>
              <fieldset className="w-full sm:w-1/2 mb-6"></fieldset>
            </div>
          )}
        </div>
        {!isReadyOnly && (
          <div className="flex justify-end bg-gray-100 border-t border-gray-300 p-4">
            {isDeleteAllowed && isExisting && (
              <div
                className={
                  'bg-transparent border border-red-700 rounded text-red-700 px-4 py-2 cursor-pointer ' +
                  'disabled:bg-gray-500 disabled:cursor-not-allowed mr-4'
                }
                onClick={handleDelete}
              >
                Delete camera
              </div>
            )}
            <input
              className="bg-blue-700 rounded text-white px-4 py-2 cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
              disabled={!isOrientationValid || isLoading}
              type="submit"
              value={isLoading ? 'Please wait...' : `${isExisting ? 'Update' : 'Add'} site`}
            />
          </div>
        )}
      </form>
    </Card>
  );
};
