import React, { FC } from 'react';

type CameraSnapshotMeta = { image: any };
export const CameraSnapshotMeta: FC<CameraSnapshotMeta> = ({ image: { cameraId, cameraName, metaJson } = {} }) => {
  if (!cameraId || !cameraName || !metaJson) return null;

  return (
    <div className="flex items-center justify-evenly text-sm leading-loose p-4">
      <div>
        Camera:{' '}
        <span className="font-medium">
          {cameraName} #{cameraId}
        </span>
      </div>
      <div>
        Exposure Time (us): <span className="font-medium">{metaJson.exposure_time_us}</span>
      </div>
      <div>
        Gain: <span className="font-medium">{metaJson.gain_db}</span>
      </div>
      <div>
        Illuminator Active: <span className="font-medium">{metaJson.illuminator_active ? 'Yes' : 'No'}</span>
      </div>
    </div>
  );
};
