import React, { FC, useCallback } from 'react';

import { ReactComponent as CheckIcon } from 'assets/icons/check-1.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const emptyFn = () => ({});

export const BooleanInput: FC<{ label: string; onChange: Function }> = ({ label, onChange = emptyFn }) => {
  const handleChange = useCallback(
    (e, val) => {
      e.preventDefault();
      onChange(val);
    },
    [onChange],
  );

  const handleNo = useCallback((e) => handleChange(e, false), [handleChange]);
  const handleYes = useCallback((e) => handleChange(e, true), [handleChange]);

  return (
    <>
      <label className="block mb-1">{label}</label>
      <div className="inline-flex border border-gray-400">
        <button className="p-4 border-r border-gray-400 cursor-pointer hover:text-red-700" onClick={handleNo}>
          <CloseIcon className="w-3" />
        </button>
        <button className="p-4 cursor-pointer hover:text-green-700" onClick={handleYes}>
          <CheckIcon className="w-3" />
        </button>
      </div>
    </>
  );
};
