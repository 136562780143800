import React, { FC, useCallback, useMemo, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DatePicker from 'react-datepicker';

import moment from 'moment';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DatabaseSearchIcon } from 'assets/icons/database-search-alternate.svg';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-button-left.svg';
import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as PictureIcon } from 'assets/icons/picture-stack-landscape.svg';
import { ReactComponent as PictureWarningIcon } from 'assets/icons/image-file-warning.svg';
import { ReactComponent as RightIcon } from 'assets/icons/arrow-button-right.svg';

import { Card } from 'shared/card';
import { Gallery } from 'shared/gallery';
import { Select } from 'shared/select';
import { Table } from 'shared/table';

import { useUniqueTrainsDashboard } from './use-unique-trains-dashboard';
import { AppLayout } from '../app.layout';

import { EventImageInfo } from '../operator-dashboard/event-image-info';

import { Camera } from 'types';

import { DATEPICKER_FORMAT, DATE_FORMAT, DATETIME_FORMAT } from 'constants/formats';

const emptyFn = () => ({});
const styleMaxWidth200 = { maxWidth: '200px' };
const styleMinHeight250 = { minHeight: '250px' };
const styleTop6 = { top: '6rem' };

export const UniqueTrainsDashboardPage = () => {
  const {
    cameras,
    clearTrainSelection,
    endDate,
    getCameraNameById,
    gotoNextPage,
    gotoPreviousPage,
    handleSightingSelect,
    handleTrainSelect,
    images,
    isImagesLoading,
    isPanoramaImagesLoading,
    isTrainSightingLoading,
    isUniqueTrainsLoading,
    onEndDateChange,
    onStartDateChange,
    panoramaImages,
    selectCameraFromSelect,
    selectedCameraForSelect,
    selectedSightingId,
    selectedTrainId,
    startDate,
    trainSighting,
    UNIQUE_TRAINS_LIMIT,
    uniqueTrains,
    uniqueTrainsPageNumber,
  } = useUniqueTrainsDashboard();

  const cameraDropdown = (
    <Select
      isDisabled={!cameras?.data?.length}
      onChange={selectCameraFromSelect}
      options={(!!cameras?.data?.length
        ? !!selectedCameraForSelect
          ? [{ id: 0, nickname: 'All' } as Camera].concat(cameras.data)
          : cameras.data
        : []
      ).map((camera: Camera) => ({
        camera,
        value: camera.id,
        label: camera.nickname,
      }))}
      placeholder="Select camera"
      value={selectedCameraForSelect}
    />
  );

  const [selectedImage, setSelectedImage] = useState();
  const selectImage = useCallback((image) => setSelectedImage(image), []);

  const totalPages = Math.ceil(uniqueTrains?.total / UNIQUE_TRAINS_LIMIT);

  return (
    <AppLayout name="Unique trains dashboard">
      {!selectedTrainId && (
        <div className="container max-w-5xl mx-auto px-4">
          <div className="flex items-center justify-between text-xs mb-4">
            <div className="flex">
              <div
                className={'flex ' + (!uniqueTrainsPageNumber ? 'text-gray-500 cursor-not-allowed' : 'cursor-pointer')}
                onClick={gotoPreviousPage}
              >
                <LeftIcon className="w-3 mr-1" />
                Previous
              </div>
              <div
                className={
                  'flex ml-4 ' +
                  (uniqueTrains?.data?.length < UNIQUE_TRAINS_LIMIT
                    ? 'text-gray-500 cursor-not-allowed'
                    : 'cursor-pointer')
                }
                onClick={gotoNextPage}
              >
                Next
                <RightIcon className="w-3 ml-1" />
              </div>
            </div>
            {!!uniqueTrains?.data?.length && !isUniqueTrainsLoading && (
              <div className="text-gray-700 mr-6">
                Total <span className="font-medium">{uniqueTrains?.total}</span>
                <span className="text-gray-400 mx-2">|</span>
                Page{' '}
                <span className="font-medium">
                  {uniqueTrainsPageNumber + 1} / {totalPages}
                </span>
              </div>
            )}
            {isUniqueTrainsLoading && <div className="text-gray-700 mr-6">Loading...</div>}
            <div className="flex items-center ml-3">
              <DatePicker
                customInput={
                  <div className="flex items-center border border-gray-400 bg-white py-3 px-4 cursor-pointer">
                    <CalendarIcon className="w-4 text-gray-500 mr-2" />
                    {startDate ? moment(startDate).format(DATE_FORMAT) : <span className="text-gray-500">Start</span>}
                  </div>
                }
                dateFormat={DATEPICKER_FORMAT}
                endDate={endDate}
                onChange={onStartDateChange}
                selected={startDate}
                startDate={startDate}
              />
              <span className="text-gray-500 mx-2">-</span>
              <DatePicker
                customInput={
                  <div className="flex items-center border border-gray-400 bg-white py-3 px-4 cursor-pointer">
                    <CalendarIcon className="w-4 text-gray-500 mr-2" />
                    {endDate ? moment(endDate).format(DATE_FORMAT) : <span className="text-gray-500">End</span>}
                  </div>
                }
                dateFormat={DATEPICKER_FORMAT}
                endDate={endDate}
                minDate={startDate}
                onChange={onEndDateChange}
                selected={endDate}
                startDate={startDate}
              />
            </div>
            <div className="relative w-full z-20" style={styleMaxWidth200}>
              {cameraDropdown}
            </div>
          </div>
          <Card title="Unique trains" icon={<PictureIcon />}>
            {!!uniqueTrains?.data?.length && !isUniqueTrainsLoading && (
              <TrainsTable
                canPaginate={false}
                getCameraNameById={getCameraNameById}
                onRowSelect={handleTrainSelect}
                selectedEventId={selectedCameraForSelect?.value}
                trains={uniqueTrains?.data}
              />
            )}
            {isUniqueTrainsLoading && (
              <div className="p-8">
                <Loading className="text-gray-500 w-10 mx-auto" />
              </div>
            )}
            {!uniqueTrains?.data?.length && !isUniqueTrainsLoading && (
              <div className="text-center text-gray-600 p-8">
                <DatabaseSearchIcon className="w-6 mx-auto mb-2" />
                <div className="text-xs font-medium">No {!!uniqueTrainsPageNumber && 'more'} trains</div>
              </div>
            )}
          </Card>
        </div>
      )}
      {selectedTrainId && (
        <>
          <div className="flex items-center justify-between text-sm mb-4">
            <div onClick={clearTrainSelection} className="inline-flex text-gray-700 hover:text-blue-700 cursor-pointer">
              <LeftIcon className="w-3 mr-2" /> Back to list
            </div>
            <div className="relative w-full z-20" style={styleMaxWidth200}>
              {cameraDropdown}
            </div>
          </div>
          <div className="xl:flex">
            <div className="w-full xl:w-7/12 relative">
              <div className="sticky" style={styleTop6}>
                <Tabs forceRenderTabPanel={true} className="react-tabs react-tabs--transparent">
                  <TabList>
                    <Tab>Images</Tab>
                    <Tab>Panorama</Tab>
                  </TabList>
                  <TabPanel>
                    <Card title="Images" icon={<PictureIcon />}>
                      <div style={styleMinHeight250}>
                        {(isImagesLoading || isTrainSightingLoading) && (
                          <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                            <Loading className="text-gray-500 w-16 mx-auto my-20" />
                          </div>
                        )}
                        {!isImagesLoading &&
                          !isTrainSightingLoading &&
                          (!trainSighting?.data?.length || !images?.data.length) && (
                            <div className="text-gray-600 text-center p-20">
                              <PictureWarningIcon className="w-10 mx-auto mb-6" />
                              No images
                            </div>
                          )}
                        {!!trainSighting?.data?.length && !!images?.data?.length && (
                          <div>
                            <Gallery
                              images={images.data.map((i: any) => ({
                                thumbnail: i.previewImageUrl,
                                original: i.fullImageUrl,
                                data: i,
                              }))}
                              onSlide={selectImage}
                            />
                            {selectedImage && <EventImageInfo image={selectedImage} />}
                          </div>
                        )}
                      </div>
                    </Card>
                  </TabPanel>
                  <TabPanel>
                    <Card title="Images" icon={<PictureIcon />}>
                      <div style={styleMinHeight250}>
                        {(isPanoramaImagesLoading || isTrainSightingLoading) && (
                          <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                            <Loading className="text-gray-500 w-16 mx-auto my-20" />
                          </div>
                        )}
                        {!isPanoramaImagesLoading &&
                          !isTrainSightingLoading &&
                          (!trainSighting?.data?.length || !panoramaImages?.length) && (
                            <div className="text-gray-600 text-center p-20">
                              <PictureWarningIcon className="w-10 mx-auto mb-6" />
                              No images
                            </div>
                          )}
                        {!!trainSighting?.data?.length && !!panoramaImages?.length && (
                          <div className="flex overflow-x-auto">
                            {panoramaImages.map((i, idx) => (
                              <img src={i} key={idx} alt={i} />
                            ))}
                          </div>
                        )}
                      </div>
                    </Card>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className="w-full xl:w-7/12">
              <div className="pt-8 xl:pl-8 xl:pt-0">
                <Card title="Train sightings" icon={<PictureIcon />}>
                  {!!trainSighting?.data?.length && !isTrainSightingLoading && (
                    <SightingsTable
                      getCameraNameById={getCameraNameById}
                      onRowSelect={handleSightingSelect}
                      selectedEventId={selectedSightingId}
                      trains={trainSighting?.data}
                    />
                  )}
                  {!trainSighting?.data?.length && !isTrainSightingLoading && (
                    <div className="text-center text-gray-600 p-8">
                      <DatabaseSearchIcon className="w-6 mx-auto mb-2" />
                      <div className="text-xs font-medium">No sightings</div>
                    </div>
                  )}
                  {isTrainSightingLoading && (
                    <div className="p-8">
                      <Loading className="text-gray-500 w-10 mx-auto" />
                    </div>
                  )}
                </Card>
              </div>
            </div>
          </div>
        </>
      )}
    </AppLayout>
  );
};

type TrainsTable = {
  canPaginate?: boolean;
  getCameraNameById: Function;
  onRowSelect?: Function;
  selectedEventId?: string | number;
  trains: any[];
};
const TrainsTable: FC<TrainsTable> = ({
  canPaginate = true,
  getCameraNameById,
  onRowSelect = emptyFn,
  selectedEventId = '',
  trains = [],
}) => {
  const headers = useMemo(
    () => ({
      trainId: 'Train ID',
      sightingId: 'Sighting ID',
      cameraId: 'Camera',
      start: 'Start',
      end: 'End',
      direction: 'Direction',
      velocity: 'Velocity (MPH)',
    }),
    [],
  );

  const data = useMemo(() => {
    return trains
      .map((event) => {
        if (!event.trainId) return false;
        return {
          id: event.trainId,
          trainId: event.trainId,
          sightingId: event.sightingId,
          cameraId: getCameraNameById(event.cameraId),
          start: moment(event.start * 1000).format(DATETIME_FORMAT),
          end: moment(event.start * 1000).format(DATETIME_FORMAT),
          direction: event.direction,
          velocity: event.speed,
        };
      })
      .filter((e) => !!e);
  }, [trains, getCameraNameById]);

  return (
    <Table
      canPaginate={canPaginate}
      data={data}
      headers={headers}
      onRowSelect={onRowSelect}
      selectedEventId={selectedEventId}
    />
  );
};

type SightingsTable = {
  canPaginate?: boolean;
  getCameraNameById: Function;
  onRowSelect?: Function;
  selectedEventId?: string | number;
  trains: any[];
};
const SightingsTable: FC<SightingsTable> = ({
  canPaginate = true,
  getCameraNameById,
  onRowSelect = emptyFn,
  selectedEventId = '',
  trains = [],
}) => {
  const headers = useMemo(
    () => ({
      sightingId: 'Sighting ID',
      trainId: 'Train ID',
      cameraId: 'Camera',
      start: 'Start',
      end: 'End',
      direction: 'Direction',
      velocity: 'Velocity (MPH)',
    }),
    [],
  );

  const data = useMemo(() => {
    return trains.map((event) => {
      return {
        id: event.sightingId,
        sightingId: event.sightingId,
        trainId: event.trainId,
        cameraId: getCameraNameById(event.cameraId),
        start: moment(event.start * 1000).format(DATETIME_FORMAT),
        end: moment(event.start * 1000).format(DATETIME_FORMAT),
        direction: event.direction,
        velocity: event.speed,
      };
    });
  }, [trains, getCameraNameById]);

  return (
    <Table
      canPaginate={canPaginate}
      data={data}
      headers={headers}
      onRowSelect={onRowSelect}
      selectedEventId={selectedEventId}
    />
  );
};
