import React, { FC, memo } from 'react';
import { Polyline } from '@react-google-maps/api';

// Types
import { Segment, PolylineOptions } from 'types';

import { colors } from 'constants/colors';

type Polylines = {
  groupSegments?: number[];
  hoveredSegments?: any;
  onClick?: Function;
  onDblClick?: Function;
  segments: Segment[];
  segmentsWithCamera?: number[];
  selectedSegmentGroup?: any;
};

const merge = (...args: PolylineOptions[]) => Object.assign({}, ...args);

export const PolylinesFn: FC<Polylines> = ({
  groupSegments,
  hoveredSegments,
  onClick = () => {
    return;
  },
  onDblClick = () => {
    return;
  },
  segments,
  segmentsWithCamera,
  selectedSegmentGroup,
}) => {
  const onClickCb = (seg: Segment) => () => onClick(seg);
  const onDblClickCb = (seg: Segment) => () => onDblClick(seg);

  const content = (segments || []).map((seg) => {
    const options = merge({ strokeWeight: 2, clickable: true }, seg.options);

    const isGroupSegment = groupSegments?.includes(seg.objectid);
    const isSegmentWithCamera = segmentsWithCamera?.includes(seg.objectid);
    const isHoveredSegment = hoveredSegments?.includes(seg.objectid);
    const isSelectedSegmentGroupSegment = selectedSegmentGroup?.segments?.includes(seg.objectid);

    options.strokeWeight = 2.5;
    options.strokeColor = options.active === seg.objectid ? colors.red700 : colors.gray800;

    if (seg.tracks === 0) {
      options.strokeWeight = 1.5;
      options.strokeColor = colors.gray800;
      options.strokeOpacity = 0.2;

      // disabling click also disables mouseOver and mouseOut events
      options.clickable = false;
    }

    if (seg.hasCamera) {
      options.strokeColor = colors.indigo600;
      options.zIndex = 2;
    }

    if (isSegmentWithCamera) options.strokeColor = colors.indigo600;

    if (isGroupSegment || isHoveredSegment || isSelectedSegmentGroupSegment) {
      options.strokeColor = colors.red700;
      options.strokeWeight = 3.5;
    }

    if (isSelectedSegmentGroupSegment) {
      options.zIndex = 3;
    }

    return (
      <Polyline
        key={`seg-${seg.objectid}`}
        onClick={onClickCb(seg)}
        onDblClick={onDblClickCb(seg)}
        options={options}
        path={seg.coordinates}
      />
    );
  });

  return <>{content}</>;
};

export const Polylines = memo(PolylinesFn);
