export const illuminatorEnums = [
  { label: 'precise', value: 'precise' },
  { label: 'near', value: 'near' },
  { label: 'medium', value: 'medium' },
  { label: 'far', value: 'far' },
];

export const psocEnums = [
  { label: 'v4', value: 'v4' },
  { label: 'v5', value: 'v5' },
];

export const coolerEnums = [
  { label: 'passive cooler', value: 'passive cooler' },
  { label: 'teca cooler', value: 'teca cooler' },
  { label: 'laird 48v', value: 'laird 48v' },
  { label: 'laird 24v', value: 'laird 24v' },
  { label: 'sanda 24v', value: 'sanda 24v' },
];
