import React, { FC, useState, useMemo, useCallback, MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';

import { useMediaQuery } from 'hooks/use-media-query';
import { useUser, useAuth } from 'auth.provider';
import { User } from 'services/user.model';

import { AppRoutes } from 'constants/routes';

import { Logo } from 'shared/logo';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/question-help-message-alternate.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout-alternate.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/navigation-menu.svg';

export const Header = () => {
  const user = useUser();
  const { logout } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = useCallback(() => setShowMenu(!showMenu), [showMenu, setShowMenu]);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const isAuthorizedForDashboard = AppRoutes.dashboard.roles.includes(user.role);

  return (
    <div className="text-sm bg-white border-b border-gray-400 px-6 py-4 fixed right-0 top-0 left-0 z-20">
      <div className="flex items-center justify-between">
        <Logo />
        <div className="flex items-center">
          {!isMobile && isAuthorizedForDashboard && (
            <>
              <NavLink
                to={AppRoutes.dashboard.path}
                exact={true}
                className="mr-6 text-gray-600 hover:text-gray-800"
                activeClassName="text-blue-700 hover:text-blue-700"
              >
                {AppRoutes.dashboard.name}
              </NavLink>
              <NavLink
                to={AppRoutes.help.path}
                exact={true}
                className="mr-6 flex text-gray-600 hover:text-gray-800"
                activeClassName="text-blue-700 hover:text-blue-700"
              >
                <HelpIcon className="w-4 mr-2" />
                {AppRoutes.help.name}
              </NavLink>
            </>
          )}
          {!isMobile && (
            <div className="mr-6 last:mr-0 flex text-gray-600 hover:text-gray-800 cursor-pointer" onClick={logout}>
              <LogoutIcon className="w-4 mr-2" />
              Logout
            </div>
          )}
          {user.isCustomer && !isMobile ? (
            ''
          ) : (
            <MenuIcon className="w-4 text-gray-600 hover:text-gray-800 cursor-pointer" onClick={toggleMenu} />
          )}
        </div>
      </div>
      {user.isCustomer && !isMobile ? (
        ''
      ) : (
        <Menu user={user} showMenu={showMenu} toggleMenu={toggleMenu} logout={logout} />
      )}
    </div>
  );
};

const MenuLink: FC<{ to: string; text: string }> = ({ to, text }) => {
  return (
    <NavLink
      to={to}
      exact={true}
      className="block text-gray-600 hover:text-gray-800 my-4"
      activeClassName="text-blue-700 hover:text-blue-700"
    >
      {text}
    </NavLink>
  );
};

const MenuLinks: FC<{ role: string }> = ({ role }) => {
  const routes = [];
  for (const key in AppRoutes) {
    const route = AppRoutes[key];
    const isAuthorized = (route.roles || []).includes(role);
    if (route?.hide) continue;
    if (isAuthorized) routes.push(<MenuLink to={route.path} text={route.name} key={key} />);
  }
  return <div className="border-b px-6 py-2">{routes}</div>;
};

const styleHeight60 = { height: '60px' };

type Menu = { user: User; showMenu: boolean; toggleMenu: MouseEventHandler; logout: MouseEventHandler };
const Menu: FC<Menu> = ({ user, showMenu, toggleMenu, logout }) => {
  const overlayStyle = useMemo(() => ({ minWidth: '300px', marginRight: showMenu ? 0 : '-300px' }), [showMenu]);

  return (
    <div>
      <div
        className={
          'fixed left-0 top-0 right-0 bottom-0 bg-gray-800 bg-opacity-50 transition-all duration-200 cursor-pointer z-30 ' +
          `${showMenu || 'hidden'}`
        }
        onClick={toggleMenu}
      ></div>
      <div
        className="fixed right-0 top-0 bottom-0 h-screen bg-white shadow-lg overflow-y-auto transition-all duration-200 z-40"
        style={overlayStyle}
      >
        <div className="flex items-center justify-between border-b px-6 py-4" style={styleHeight60}>
          <div>
            <div className="text-xs font-medium uppercase tracking-widest">{user.displayName || 'User'}</div>
            <div className="text-xs">{user.email}</div>
          </div>
          <CloseIcon className="w-4 text-gray-600 cursor-pointer" onClick={toggleMenu} />
        </div>
        <MenuLinks role={user.role} />
        <div className="border-b px-6 py-2">
          <div className="w-full flex text-gray-600 hover:text-gray-800 my-4 cursor-pointer" onClick={logout}>
            <LogoutIcon className="w-4 mr-2" />
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};
