import firebase from 'firebase/app';
import { Roles } from 'constants/roles';

export type User = {
  id: string;
  email: string;
  displayName: string;
  role: string;
  tier: string;
  isAdmin: boolean;
  isCustomer: boolean;
  isReadyOnly: boolean;
  token: string;
  refreshToken: string;
  isLoggedIn: boolean;
};

export type CurrentUser = User | { isLoading: true } | { isLoggedIn: false } | { authError: firebase.auth.Error };

export const createUser = (user: firebase.User, idTokenResult: firebase.auth.IdTokenResult): User => ({
  id: user.uid,
  email: idTokenResult.claims.email,
  displayName: user.displayName || '',
  role: idTokenResult.claims.role,
  tier: idTokenResult.claims && idTokenResult.claims.tier ? idTokenResult.claims.tier : "1",
  isAdmin: idTokenResult.claims.role === Roles.admin,
  isCustomer: idTokenResult.claims.role === Roles.customer,
  isReadyOnly: [Roles.readOnly, Roles.thirdPartyAuditor].includes(idTokenResult.claims.role),
  token: idTokenResult.token,
  refreshToken: user.refreshToken,
  isLoggedIn: true,
});
