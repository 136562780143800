import React, { FC } from 'react';

import moment from 'moment';
import { DATETIME_FORMAT } from 'constants/formats';

type SampleImageMeta = { image: any };
export const SampleImageMeta: FC<SampleImageMeta> = ({ image: { time, metaJson } = {} }) => {
  if (!metaJson || !time) return null;

  return (
    <div className="flex items-center justify-evenly text-sm leading-loose p-4">
      <div>
        Exposure Time (us): <span className="font-medium">{metaJson.exposure_time_us}</span>
      </div>
      <div>
        Gain: <span className="font-medium">{metaJson.gain_db}</span>
      </div>
      <div>
        Illuminator Active: <span className="font-medium">{metaJson.illuminator_active ? 'Yes' : 'No'}</span>
      </div>
      <div>
        Time: <span className="font-medium">{moment(time * 1000).format(DATETIME_FORMAT)}</span>
      </div>
    </div>
  );
};
