import React, { FC, ReactNode, FormEvent } from 'react';

const FILL_SOLID = 'solid';
const FILL_OUTLINE = 'outline';
const onClickDefault = (e: FormEvent) => e.preventDefault();

type Button = {
  icon?: ReactNode;
  color: string;
  fill?: typeof FILL_SOLID | typeof FILL_OUTLINE;
  onClick?: (e: FormEvent) => void;
};
export const Button: FC<Button> = ({ icon, color, fill = FILL_SOLID, onClick = onClickDefault, children }) => {
  const isSolid = fill === FILL_SOLID;
  const hasIcon = !!icon;

  return (
    <div
      className={
        'flex items-center text-sm rounded cursor-pointer px-4 py-2 ' +
        (isSolid ? `text-white bg-${color}` : `text-${color} border border-${color} border-opacity-50`)
      }
      onClick={onClick}
    >
      {hasIcon && <div className="w-4 mr-2">{icon}</div>}
      <div>{children}</div>
    </div>
  );
};
