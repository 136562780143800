import React, { FC, useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useToasts } from 'react-toast-notifications';

import moment, { Moment } from 'moment';

import { ReactComponent as CameraIcon } from 'assets/icons/meeting-camera.svg';
import { ReactComponent as CameraOffIcon } from 'assets/icons/controls-camera-off.svg';
import { ReactComponent as FocusIcon } from 'assets/icons/focus-landscape.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/button-play.svg';
import { ReactComponent as StopIcon } from 'assets/icons/button-stop.svg';
import { ReactComponent as ZoomInIcon } from 'assets/icons/zoom-in-1.svg';
import { ReactComponent as ZoomOutIcon } from 'assets/icons/zoom-out-1.svg';

import { Card } from 'shared/card';
import { SelectOptionType } from 'shared/select';

import { Mqtt } from 'services/mqtt';

const streamContainerStyle = { paddingTop: '75%' };

type CameraStream = {
  selectedNodeForSelect: SelectOptionType | undefined;
  startStream: (id: string) => void;
  stopStream: (id: string, cb: Function) => void;
};
export const CameraStream: FC<CameraStream> = ({ selectedNodeForSelect, startStream, stopStream }) => {
  const { addToast } = useToasts();

  const [mqttClient, setMqttClient] = useState<Mqtt>();
  const [isConnected, setIsConnected] = useState(false);

  const [image, setImage] = useState<string | undefined>();
  const [selectedNodeId, setSelectedNodeId] = useState<string>();

  const wheelProp = useMemo(() => ({ wheelEnabled: false }), []);

  const [timestamp, setTimestamp] = useState<Moment | undefined>();
  const [secondsAgo, setSecondsAgo] = useState<string | undefined>();
  const [isDelayed, setIsDelayed] = useState(false);

  const resetSteam = () => {
    setImage(undefined);
    setTimestamp(undefined);
    setSecondsAgo(undefined);
  };

  const getScreenshotName = () => {
    return `${selectedNodeId}-${moment().unix()}.png`;
  };

  useEffect(() => {
    const client = Mqtt.configure(
      () => setIsConnected(true),
      () => setIsConnected(false),
    );
    setMqttClient(client);
  }, []);

  useEffect(() => {
    if (!selectedNodeForSelect) return;
    if (mqttClient && isConnected && selectedNodeId)
      mqttClient.unsubscribe(selectedNodeId, () => {
        resetSteam();
        setSelectedNodeId(String(selectedNodeForSelect.value));
      });
    else setSelectedNodeId(String(selectedNodeForSelect.value));
  }, [selectedNodeForSelect, mqttClient, isConnected, selectedNodeId]);

  useEffect(() => {
    if (!(mqttClient && isConnected && selectedNodeId)) return;
    resetSteam();
    mqttClient.subscribe(selectedNodeId, () =>
      addToast('Unable to load camera feed.', { appearance: 'error', autoDismiss: true }),
    );
    mqttClient.onMessage((base64: string) => {
      setImage(base64);
      setTimestamp(moment());
    });
  }, [mqttClient, isConnected, selectedNodeId, addToast]);

  const handleStart = useCallback(() => {
    if (selectedNodeId) startStream(selectedNodeId);
  }, [startStream, selectedNodeId]);

  const handleStop = useCallback(() => {
    if (selectedNodeId) stopStream(selectedNodeId, () => resetSteam());
  }, [stopStream, selectedNodeId]);

  useEffect(() => {
    if (!timestamp) return;
    const setSeconds = () => {
      const seconds = moment().diff(timestamp, 'seconds');
      setSecondsAgo(moment().seconds(seconds).format('s [seconds ago]'));
      setIsDelayed(seconds > 10);
    };
    const interval = setInterval(setSeconds, 1000);
    return () => clearInterval(interval);
  }, [timestamp]);

  const [checkedGrid, setCheckedGrid] = React.useState(false);
  const handleSetCheckedGrid = useCallback(() => setCheckedGrid(!checkedGrid), [checkedGrid]);

  const streamRef: React.RefObject<any> = useRef('');
  const streamH = (streamRef.current && streamRef.current.offsetHeight) || 0;
  const streamW = (streamRef.current && streamRef.current.offsetWidth) || 0;
  const streamWH = `0 0 ${streamW} ${streamH}`;

  const drawLines = (count = 1, vert = true) => {
    const lines = [];
    for (let i = 0; i <= count + 1; i++) {
      const wh = (i * (vert ? streamW : streamH)) / count;
      lines.push(
        <line
          key={i}
          x1={vert ? wh : 0}
          y1={vert ? 0 : wh}
          x2={vert ? wh : streamW}
          y2={vert ? streamH : wh}
          strokeWidth="0.8"
        />,
      );
    }

    return streamW && streamH ? lines : [];
  };

  return (
    <Card title="Site stream" icon={<CameraIcon />}>
      {!selectedNodeId && (
        <div className="text-gray-600 text-center p-20">
          <CameraOffIcon className="w-10 mx-auto mb-6" />
          Select node
        </div>
      )}
      {selectedNodeId && (
        <TransformWrapper wheel={wheelProp}>
          {({ zoomIn, zoomOut }: { zoomIn: () => void; zoomOut: () => void }) => (
            <>
              <div className="relative" style={streamContainerStyle}>
                <div className="absolute top-0 left-0 bottom-0 right-0 bg-gray-700 overflow-hidden" ref={streamRef}>
                  <TransformComponent>
                    <img src={image} alt="" />
                  </TransformComponent>
                  {checkedGrid && (
                    <svg
                      className="absolute top-0 left-0"
                      height="100%"
                      width="100%"
                      viewBox={streamWH}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g stroke="red">
                        {drawLines(30, true)}
                        {drawLines(20, false)}
                      </g>
                    </svg>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center p-4 text-gray-600">
                <div className="flex items-center ml-1">
                  {image && (
                    <>
                      <ZoomOutIcon className="w-6 cursor-pointer hover:text-blue-700 mr-3" onClick={zoomOut} />
                      <ZoomInIcon className="w-6 cursor-pointer hover:text-blue-700 mr-5" onClick={zoomIn} />
                      <a href={image} target="_blank" rel="noopener noreferrer" download={getScreenshotName()}>
                        <FocusIcon className="w-6 cursor-pointer hover:text-blue-700" />
                      </a>
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  <small>
                    Show grid:
                    <input
                      checked={checkedGrid}
                      className="form-checkbox text-blue-700 ml-3 cursor-pointer"
                      id="checkedPolylines"
                      onChange={handleSetCheckedGrid}
                      type="checkbox"
                    />
                  </small>
                </div>
                <div className="flex items-center mr-1">
                  <StopIcon
                    className="w-10 rounded-full shadow cursor-pointer hover:text-red-700 mr-3"
                    onClick={handleStop}
                  />
                  <PlayIcon
                    className="w-10 rounded-full shadow cursor-pointer hover:text-blue-700"
                    onClick={handleStart}
                  />
                </div>
              </div>
              {secondsAgo && isDelayed && (
                <div className="bg-gray-100 text-red-700 text-xs italic border-t p-2">Last received: {secondsAgo}</div>
              )}
            </>
          )}
        </TransformWrapper>
      )}
    </Card>
  );
};
