import _sample from 'lodash/sample';
import { Segment } from 'types/segment';

const capacityTable: { [key: string]: (number | null)[] } = {
  ABS: [null, 18, 53],
  CTC: [null, 30, 75, 133, 173, 248, 360],
  DCS: [null, 16, 28],
  OCS: [null, 16, 28],
  TCS: [null, 30, 75, 133, 173, 248, 360],
  TWC: [null, 16, 28],
};

export const mockSegmentData = (segment: Segment) => {
  if (!segment.tcs) {
    if (segment.tracks >= 3) segment.tcs = _sample(['CTC', 'TCS']) as string;
    else segment.tcs = 'TWC';
  }
  segment.capacity = capacityTable[segment.tcs] ? capacityTable[segment.tcs][segment.tracks] : 0;

  return segment;
};
