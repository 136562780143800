import React from 'react';

import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as DatabaseIcon } from 'assets/icons/database-1-alternate.svg';
import { ReactComponent as DownIcon } from 'assets/icons/arrow-button-down-2-alternate.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/button-refresh-arrows.svg';
import { ReactComponent as UpIcon } from 'assets/icons/arrow-button-up-2-alternate.svg';

import { isInternalEmployee } from 'utils/is-internal-employee';
import { useUser } from 'auth.provider';

import { Card } from 'shared/card';

import { useNetworkStatus } from './use-network-status';
import { AppLayout } from '../app.layout';

const displayMetrics = (metrics: Object) => {
  let outputDisplayList: JSX.Element[] = [];
  for (const [key, value] of Object.entries(metrics)) {
    let outputKey = key;
    let outputValue = value;
    if (typeof value === 'object' && value[1]) {
      outputValue = value[1];
    }

    if (key === 'last_wifi_network') {
      outputKey = 'Last WiFi Network';
    }
    if (key === 'last_bandwidth_stat') {
      outputKey = 'Last Bandwidth Stat';
      outputValue = outputValue.toFixed(2) + ' MBits/s';
    }
    if (key === 'last_wifi_stat') {
      outputKey = 'Last WiFi Link Quality';
      outputValue = Math.round(outputValue);
    }
    if (key === 'device_boot_time') {
      let d = new Date(outputValue * 1000);

      outputValue = d.toLocaleString();
      outputKey = 'Time of Boot';
    }

    outputDisplayList.push(
      <div>
        {outputKey}: {outputValue}
      </div>,
    );
  }
  return outputDisplayList;
};

export const NetworkStatusPage = () => {
  const { getNetworkStatusCb, hosts, isError, isLoading, updatedAt } = useNetworkStatus();

  const isInternal = isInternalEmployee(useUser().email);

  return (
    <AppLayout name="Network status">
      <div className="container max-w-xl mx-auto px-4">
        {!isLoading && !!hosts?.length && (
          <div className="flex items-center justify-between mx-1 mb-4">
            <div className="text-xs text-gray-600">Last updated: {updatedAt}</div>
            <RefreshIcon className="w-4 text-blue-700 cursor-pointer" onClick={getNetworkStatusCb} />
          </div>
        )}
        <Card title="Hosts" icon={<DatabaseIcon />}>
          {isLoading && <Loading className="text-gray-500 w-16 my-20 mx-auto" />}
          {!isLoading && !!hosts?.length && (
            <div className="overflow-x-scroll">
              <table className="table table-auto w-full">
                <thead>
                  <tr className="bg-gray-100 border-b text-xs">
                    <th className="p-4">Name</th>
                    <th className="p-4">Status</th>
                    {isInternal && <th className="p-4">Metrics</th>}
                  </tr>
                </thead>
                <tbody>
                  {hosts.map((host) => (
                    <tr
                      className="text-sm even:bg-gray-100 even:bg-opacity-50 border-b last:border-none"
                      key={host.name}
                    >
                      <td className="p-4 whitespace-no-wrap">{host.name}</td>
                      <td className="p-4 whitespace-no-wrap">
                        {host.status === 'up' ? (
                          <div className="flex items-center text-xs text-green-700 font-medium">
                            <UpIcon className="w-3 mr-2" /> Up
                          </div>
                        ) : (
                          <div className="flex items-center text-xs text-red-700 font-medium">
                            <DownIcon className="w-3 mr-2" /> Down
                          </div>
                        )}
                      </td>
                      {isInternal && host.metrics && (
                        <td className="p-4 whitespace-no-wrap">{displayMetrics(host.metrics)}</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {!isLoading && !hosts?.length && !isError && <div className="text-center p-10">No hosts found.</div>}
          {!isLoading && isError && (
            <div className="text-center p-10">
              <div className="mb-4">Something went wrong! Please try again.</div>
              <span className="text-blue-700 border-b cursor-pointer" onClick={getNetworkStatusCb}>
                Try again
              </span>
            </div>
          )}
        </Card>
      </div>
    </AppLayout>
  );
};
