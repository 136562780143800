import React, { FC, useEffect, useMemo, useState } from 'react';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/keyboard-arrow-up.svg';
import { Comment } from 'types';
import { ReactComponent as UserIcon } from 'assets/icons/single-neutral-circle-alternate.svg';
import Moment from 'moment';
import { useUser } from 'auth.provider';

import { degToDir } from 'utils/deg-to-dir';
import { containerPositions } from 'utils/container-positions';

type EventImageInfo = {
  image: any;
  cameraOrientation?: number;
  onSaveComment?: (commentData: Comment, user?: any) => void;
  selectedImageSeriesId?: string;
  selectedImageIdx?: any;
  imageSeries?: any;
  isComment?: any;
};
export const EventImageInfo: FC<EventImageInfo> = ({
  image: img,
  cameraOrientation,
  onSaveComment,
  selectedImageSeriesId,
  selectedImageIdx,
  imageSeries,
  isComment = false,
}) => {
  const transformStyle = useMemo(() => ({ transform: `rotate(${cameraOrientation}deg)` }), [cameraOrientation]);
  const [image, setImage] = useState<{ [key: string]: string }>();
  const [comment, setComment] = useState('');
  const [isCommented, setIsCommented] = useState(false);
  const [commentList, setCommentList] = useState<any>({});
  const [isFalsePositive, setIsFalsePositive] = useState(false);

  const user = useUser();

  useEffect(() => {
    setImage({
      ...img,
      carId: img.carId || 'Not detected',
      carType: img.carType || 'Not available',
    });
    const f = img && img.comment ? Object.keys(img.comment).find((e) => e === user.id) : '';
    f ? setIsCommented(true) : setIsCommented(false);
    if (img && img.comment) {
      setCommentList(img.comment);
    } else {
      setCommentList({});
      setComment('');
    }
    img && img.setIsFalsePositive ? setIsFalsePositive(img.setIsFalsePositive) : setIsFalsePositive(false);
  }, [img, user]);

  const onChangeComment = (e: any) => {
    setComment(e.target.value);
  };

  const saveComment = () => {
    const newCommentList = commentList;
    if (onSaveComment) {
      if (comment) {
        const commentData = {
          isFalsePositive: isFalsePositive,
          comment: comment,
          sightingId: selectedImageSeriesId,
          sequence: img && img.sequence.toString(),
          idx: selectedImageIdx.toString(),
        };
        onSaveComment(commentData, user);
        const newObj = {
          comment: comment,
          email: user.email,
          name: user.displayName,
          timestamp: new Date(),
        };
        newCommentList[user.id] = newObj;
        setCommentList(newCommentList);
        setComment('');
        setIsCommented(true);
      }
    }
  };

  const onEdit = (c: any) => {
    if (c) setComment(c);
    setIsCommented(false);
  };

  const dateTimeFormat = (time: Date) => {
    const timer = Moment.utc(time).startOf('seconds').fromNow();
    return timer.includes('day') ? Moment(time).format('MM-DD-YYYY / HH:mm') : timer;
  };

  if (!image) return null;

  let carData: any = null;
  if (selectedImageSeriesId && imageSeries) {
    imageSeries.find((v: { sightingId: { toString: () => string }; carTypes: { [x: string]: any } }) => {
      if (
        v.sightingId?.toString() === selectedImageSeriesId?.toString() &&
        v.carTypes &&
        v.carTypes[selectedImageIdx]
      ) {
        carData = v.carTypes[selectedImageIdx];
        return v;
      }
      return null;
    });
  }

  return (
    <div>
      <div className="flex items-center justify-evenly text-sm leading-loose px-4 py-8">
        <div className="px-4">
          <div>Sequence: {image.sequence}</div>
          <a href={image.fullImageUrl} target="_blank" rel="noopener noreferrer" className="text-blue-700">
            Download image
          </a>
        </div>
        <div className="flex items-center px-4">
          {cameraOrientation && (
            <div className="mr-8">
              <ArrowUpIcon className="w-8" style={transformStyle} />
            </div>
          )}
          <div>
            {cameraOrientation && (
              <div>
                Camera orientation: {cameraOrientation}&deg; ({degToDir(cameraOrientation)})
              </div>
            )}
            <div>Car Id: {image.carId}</div>
            <div>Car Type: {image.carType}</div>
          </div>
        </div>
        {carData && carData.containers?.length > 0 && (
          <div className="px-4">
            <div>Containers:</div>
            {carData.containers
              .sort((a: { position: string | null }, b: { position: string | null }) =>
                containerPositions.indexOf(a.position?.toLowerCase() || '') >
                containerPositions.indexOf(b.position?.toLowerCase() || '')
                  ? 1
                  : -1,
              )
              .map((c: any, k: React.Key | undefined) => {
                return (
                  <div key={k}>
                    {c.type.replace('Container', '').trim()} {c.position && '(' + c.position + ')'}
                  </div>
                );
              })}
          </div>
        )}
        {carData && carData.hazMatPlacard && (
          <div className="px-4">
            <div>Haz Mat Placard: {carData.hazMatPlacard || ''}</div>
            {carData.hazMatPlacard !== 'No' && <div>UN Number: {carData.hazMatPlacardUN_Number || ''}</div>}
            {carData.hazMatPlacard !== 'No' && <div>Class: {carData.hazMatClass || ''}</div>}
          </div>
        )}
      </div>
      {isComment && (
        <>
          {!isCommented && (
            <div className="flex items-start ml-10 mr-10 mb-8">
              <div className="text-blue-700 w-8 mr-3">
                <UserIcon />
              </div>
              <div className="px-2">
                <textarea
                  className="border-2 p-1 w-full rounded"
                  name="comment"
                  onChange={(e) => {
                    onChangeComment(e);
                  }}
                  value={comment}
                  placeholder="Comments ..."
                  cols={55}
                  rows={1}
                ></textarea>
              </div>
              <div className="px-2">
                <button
                  className="bg-blue-600 text-white pt-1 pr-2 pb-1 pl-2 rounded"
                  onClick={() => {
                    saveComment();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          )}
          {Object.entries(commentList).map((v: any, i: any) => {
            return (
              <div key={i} className="ml-10 mr-10 mb-8">
                <div className="flex items-start">
                  <div className="text-blue-700 w-8 mr-3">
                    <UserIcon />
                  </div>
                  <p className="text-black"> {v[1] && v[1].name ? v[1].name : ''} </p>
                  <p className="text-sm ml-2 mt-1">
                    {v[1] && v[1].timestamp ? dateTimeFormat(new Date(v[1].timestamp)) : '-|-'}
                  </p>
                </div>
                <div className="ml-12">
                  <div>
                    <p className="text-sm mb-2">{v[1].comment}</p>
                  </div>
                  {user.id === v[0] && (
                    <div>
                      <button
                        className="mr-2"
                        onClick={() => {
                          onEdit(v[1].comment);
                        }}
                      >
                        <p className="hover:underline text-sm ">Edit</p>{' '}
                      </button>
                      {/* <button className="mr-2"> <p className="hover:underline text-sm ">Delete</p> </button> */}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
