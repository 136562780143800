import React, { FormEvent, useState, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import useFormFields from '@usereact/use-form-fields';

import { useAuth, useUser } from 'auth.provider';

import { ReactComponent as BackIcon } from 'assets/icons/arrow-button-left.svg';

import { AppRoutes } from 'constants/routes';
import { AuthLayout } from './auth.layout';

export const ResetPasswordPage = () => {
  const { resetPassword } = useAuth();
  const user = useUser();

  const { values: credentials, fields } = useFormFields({ email: '' });
  const validateForm = () => credentials.email.length;
  const [alert, setAlert] = useState({ isSuccess: false, message: '' });
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setIsLoading(true);

      resetPassword(credentials.email)
        .then(() => {
          setAlert({
            isSuccess: true,
            message: 'Reset password instructions sent via email.',
          });
          setIsLoading(false);
        })
        .catch((e) => {
          setAlert({ isSuccess: false, message: e.message });
          setIsLoading(false);
        });
    },
    [credentials, resetPassword],
  );

  if (user.isLoggedIn) return <Redirect to={AppRoutes.dashboard.path} />;

  return (
    <AuthLayout>
      <form onSubmit={handleLogin}>
        <fieldset className="mb-6">
          <label className="block mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            className="p-3 placeholder-gray-400 border border-gray-400 w-full"
            placeholder="Email"
            {...fields.email}
          />
        </fieldset>
        <fieldset className="mb-6">
          <input
            className="w-full bg-gray-800 rounded text-white px-6 py-3 cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
            disabled={!validateForm() || isLoading}
            type="submit"
            value={isLoading ? 'Please wait...' : 'Reset password'}
          />
        </fieldset>
        <div
          className={`text-center text-sm px-4 py-3 rounded my-4 ${alert.message.length > 0 ? '' : 'hidden'} ${
            alert.isSuccess
              ? 'bg-blue-100 border border-blue-400 text-blue-600'
              : 'bg-red-100 border border-red-400 text-red-600'
          }`}
        >
          {alert ? alert.message : ''}
        </div>
      </form>
      <div className="text-center text-sm">
        <a href="mailto:support@railstate.com" className="hover:text-blue-700">
          Contact <span className="text-blue-700">support</span> for assistance
        </a>
      </div>
      <hr className="my-6" />
      <Link to="/login" className="flex text-gray-600 hover:text-blue-700 text-sm">
        <BackIcon className="w-3 mr-2" /> Back to login
      </Link>
    </AuthLayout>
  );
};
