import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { colors } from 'constants/colors';

const MySwal = withReactContent(Swal);

type NotifyConfirm = {
  icon?: 'question' | 'success' | 'error' | 'warning' | 'info' | undefined;
  isDestructive?: boolean;
  text: string;
  title: string;
};

export const Notify = {
  confirm: ({ icon = 'question', text, title, isDestructive = false }: NotifyConfirm) => {
    return MySwal.fire({
      cancelButtonColor: colors.gray500,
      cancelButtonText: 'No',
      confirmButtonColor: isDestructive ? colors.red700 : colors.blue700,
      confirmButtonText: 'Yes',
      icon,
      reverseButtons: true,
      showCancelButton: true,
      showCloseButton: true,
      text,
      title,
    });
  },
};
