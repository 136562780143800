import { useCallback, useEffect, useState } from 'react';

import moment from 'moment';

import { useApi } from 'hooks/use-api';

export const useCameraSnapshots = () => {
  const [{ data: sampleImages, loading: isSampleImagesLoading }, getSampleImages] = useApi({
    url: '/images/sample/dailySnapShot',
  });

  const defaultDate = moment().toDate();
  const [date, setDate] = useState(defaultDate);
  const [selectedImage, setSelectedImage] = useState();

  const onDateChange = useCallback((d: Date) => {
    setDate(d);
  }, []);

  const getSampleImagesCb = useCallback(() => {
    const start = moment(date).startOf('day').valueOf();
    const end = moment(date).endOf('day').valueOf();

    getSampleImages({
      params: {
        end,
        start,
      },
    });
  }, [date, getSampleImages]);

  useEffect(() => {
    getSampleImagesCb();
  }, [date, getSampleImagesCb]);

  const handleTodayDate = useCallback(() => {
    setDate(moment().toDate());
  }, []);

  const selectImage = useCallback((image) => {
    setSelectedImage(image);
  }, []);

  const handleRefresh = useCallback(
    (e) => {
      e.preventDefault();
      getSampleImagesCb();
    },
    [getSampleImagesCb],
  );

  return {
    date,
    handleRefresh,
    handleTodayDate,
    isSampleImagesLoading,
    onDateChange,
    sampleImages,
    selectedImage,
    selectImage,
  };
};
