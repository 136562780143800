import React, { FC, FormEvent, useCallback } from 'react';
import { AppLayout } from '../app.layout';
import { Card } from 'shared/card';
import { DevicesDetail, DevicesList } from '../operator-dashboard/camera-devices';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as DatabaseIcon } from 'assets/icons/database-1-alternate.svg';
import { ReactComponent as Loading } from 'assets/images/loading.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/button-refresh-arrows.svg';
import { Select } from 'shared/select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useDeviceDashboard } from './use-device-dashboard';

export const DeviceDashboardPage = () => {
  const {
    architectures,
    devices,
    deviceStates,
    humanize,
    isDeviceLoading,
    isDeviceRestarted,
    isDevicesLoading,
    isDeviceUpdate,
    isReadyOnly,
    isSensorsLoading,
    onDeviceRestart,
    onDeviceSubmit,
    onStateSubmit,
    selectDevice,
    selectedDevice,
    sensors,
    sensorStates,
  } = useDeviceDashboard();
  const devicesCount = `List of devices (${devices && devices?.devices ? devices?.devices?.length : 0})`;
  const selected =
    !!selectedDevice && !!selectedDevice?.deviceId
      ? { value: selectedDevice?.deviceId || '', label: selectedDevice?.deviceId || '' }
      : undefined;

  return (
    <AppLayout name="Device dashboard" hasTabs={true}>
      <Tabs defaultIndex={0} forceRenderTabPanel={true}>
        <TabList>
          <Tab>List of devices</Tab>
          {selectedDevice && (
            <div className="hidden md:flex md:items-center md:ml-auto">
              <SelectedDeviceName title={selectedDevice} />
            </div>
          )}
        </TabList>
        <div className="p-6 md:p-8">
          <TabPanel>
            <div className="xl:flex">
              <div className="w-full xl:w-1/2 relative">
                {isDevicesLoading && (
                  <div className="left-0 top-0 right-0 bottom-0 absolute bg-white bg-opacity-75 z-20">
                    <Loading className="text-gray-500 w-16 mx-auto my-20" />
                  </div>
                )}
                <Card icon={<DatabaseIcon />} title={devicesCount}>
                  <DevicesList
                    devices={devices}
                    selectedCamera={null}
                    selectDevice={selectDevice}
                    selectedDevice={selectedDevice}
                    humanize={humanize}
                    showAll={true}
                  />
                </Card>
              </div>
              <div className="w-full xl:w-1/2">
                <div className="pt-8 xl:pl-8 xl:pt-0">
                  <div className="mb-8">
                    <Select
                      isDisabled={!devices?.devices.length}
                      onChange={selectDevice}
                      options={(!!devices?.devices.length ? devices?.devices : []).map((record: any) => ({
                        record,
                        value: record.deviceId,
                        label: record.deviceId,
                      }))}
                      placeholder="Select device"
                      value={selected}
                    />
                  </div>
                  <DevicesDetail
                    architectures={architectures}
                    deviceStates={deviceStates}
                    humanize={humanize}
                    isDeviceUpdate={isDeviceUpdate}
                    isLoading={isDeviceUpdate || isDeviceLoading || isSensorsLoading || isDevicesLoading}
                    isReadyOnly={isReadyOnly}
                    onDeviceSubmit={onDeviceSubmit}
                    onStateSubmit={onStateSubmit}
                    selectedCamera={null}
                    selectedDevice={selectedDevice}
                    sensorStates={sensorStates}
                    sensors={sensors}
                  />
                  {selectedDevice && selectedDevice?.deviceId && (
                    <DeviceReload
                      isDeviceRestarted={isDeviceRestarted}
                      onDeviceRestart={onDeviceRestart}
                      selectedDevice={selectedDevice}
                    />
                  )}
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </Tabs>
    </AppLayout>
  );
};

export const SelectedDeviceName: FC<{
  title: any;
}> = ({ title }) => {
  return title && title?.deviceId ? (
    <>
      <div className="text-gray-500 w-4">
        <CameraIcon />
      </div>
      <div className="text-sm mx-3 pt-1">
        <strong>{title.deviceId}</strong>
      </div>
    </>
  ) : null;
};

export const DeviceReload: FC<{
  isDeviceRestarted: boolean;
  onDeviceRestart: Function;
  selectedDevice: any;
}> = ({ isDeviceRestarted, onDeviceRestart, selectedDevice }) => {
  const isDisabled = isDeviceRestarted || !selectedDevice || !selectedDevice?.deviceId;
  const handleRestartSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onDeviceRestart && selectedDevice && onDeviceRestart(selectedDevice);
    },
    [onDeviceRestart, selectedDevice],
  );

  return (
    <div className="w-full relative mt-6">
      <Card icon={<RefreshIcon />} title="Restart device">
        <div className="flex justify-end bg-gray-100 border-t border-gray-300 p-4">
          <form className="text-sm" onSubmit={handleRestartSubmit}>
            <input
              className="bg-blue-700 rounded text-white px-4 py-2 cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
              disabled={isDisabled}
              type="submit"
              value={isDisabled ? 'Please wait...' : 'Restart'}
            />
          </form>
        </div>
      </Card>
    </div>
  );
};
