import React, { FC, useMemo, useState, useEffect, useCallback, CSSProperties } from 'react';
import ReactSelect from 'react-select';

import { colors } from 'constants/colors';

export type SelectOptionType = {
  value: string | number;
  label: string;
  inProduction?: boolean;
};
type Select = {
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  isReadyOnly?: boolean;
  onChange: Function;
  options: SelectOptionType[];
  placeholder?: string;
  value?: SelectOptionType | SelectOptionType[];
};
export const Select: FC<Select> = ({
  isClearable = false,
  isDisabled = false,
  isMulti = false,
  isReadyOnly = false,
  onChange,
  options,
  placeholder = 'Select...',
  value = null,
}) => {
  const [val, setVal] = useState<SelectOptionType | SelectOptionType[] | null>();

  useEffect(() => {
    setVal(value);
  }, [value]);

  const styles = useMemo(
    () => ({
      control: (provided: CSSProperties, { isDisabled }: any) => ({
        ...provided,
        backgroundColor: isDisabled ? colors.gray200 : colors.white,
        borderColor: colors.gray400,
        borderRadius: 0,
        boxShadow: 'none',
        cursor: 'text',
        minHeight: '47px',
        paddingLeft: '4px',
        width: '100%',
        '&:hover': {
          borderColor: colors.gray400,
        },
      }),
      indicatorSeparator: (provided: CSSProperties) => ({
        ...provided,
        backgroundColor: colors.gray400,
        cursor: 'pointer',
        display: isReadyOnly ? 'none' : 'block',
      }),
      clearIndicator: (provided: CSSProperties) => ({
        ...provided,
        color: colors.gray400,
        cursor: 'pointer',
        '&:hover': {
          color: colors.gray500,
        },
      }),
      dropdownIndicator: (provided: CSSProperties) => ({
        ...provided,
        color: colors.gray400,
        display: isReadyOnly ? 'none' : 'block',
        '&:hover': {
          color: colors.gray400,
        },
      }),
      placeholder: (provided: CSSProperties) => ({
        ...provided,
        color: colors.gray400,
      }),
      multiValue: (provided: CSSProperties) => ({
        ...provided,
        backgroundColor: colors.gray200,
      }),
      option: (provided: CSSProperties, { isSelected, isFocused, isDisabled }: any) => ({
        ...provided,
        backgroundColor: isFocused ? colors.gray200 : colors.transparent,
        color: isSelected ? colors.blue700 : isDisabled ? colors.gray400 : colors.gray700,
        ':active': {
          backgroundColor: !isDisabled && colors.gray200,
        },
      }),
    }),
    [isReadyOnly],
  );

  const onChangeFn = useCallback(
    (v) => {
      setVal(v);
      onChange(v);
    },
    [setVal, onChange],
  );

  return (
    <ReactSelect
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isSearchable={!isReadyOnly}
      menuIsOpen={isReadyOnly ? false : undefined}
      onChange={onChangeFn}
      openMenuOnClick={!isReadyOnly}
      options={options}
      placeholder={placeholder}
      styles={styles}
      value={val}
    />
  );
};
