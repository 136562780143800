import React, { FormEvent, useEffect, useState, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import useFormFields from '@usereact/use-form-fields';

import { useAuth, useUser } from 'auth.provider';

import { AppRoutes } from 'constants/routes';
import { Checkbox } from 'shared/checkbox';

import { AuthLayout, GoogleButton, Divider } from './auth.layout';

export const LoginPage = () => {
  const { login, signInWithGoogle } = useAuth();
  const user = useUser();

  const { values: credentials, fields } = useFormFields({
    email: '',
    password: '',
  });
  const validateForm = () => credentials.email.length && credentials.password.length;

  const [loginError, setLoginError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [isInternalEmail, setIsInternalEmail] = useState(false);

  useEffect(() => {
    setIsInternalEmail(credentials.email.includes('@railstate.com'));
  }, [credentials.email]);

  const errorCallback = useCallback((error) => {
    setIsLoading(false);
    setLoginError(error.message);
  }, []);

  const handleLogin = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setIsLoading(true);

      login({ ...credentials, rememberMe }).catch(errorCallback);
    },
    [credentials, rememberMe, login, errorCallback],
  );

  const handleSignInWithGoogle = useCallback(() => {
    setIsLoading(true);
    setLoginError('');

    signInWithGoogle().catch(errorCallback);
  }, [signInWithGoogle, errorCallback]);

  const handleRememberMe = useCallback((_, val) => setRememberMe(val), []);

  if (user.isLoggedIn) return <Redirect to={AppRoutes.dashboard.path} />;

  return (
    <AuthLayout>
      <form onSubmit={handleLogin}>
        <fieldset className="mb-1">
          <label className="block mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            className="p-3 placeholder-gray-400 border border-gray-400 w-full"
            placeholder="Email"
            {...fields.email}
          />
        </fieldset>
        <div className="text-right text-xs mb-6">
          <a href="mailto:support@railstate.com" className="text-blue-700">
            Forgot email?
          </a>
        </div>
        <fieldset className="mb-1">
          <label className="block mb-1" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className="p-3 placeholder-gray-400 border border-gray-400 w-full"
            placeholder="Password"
            {...fields.password}
          />
        </fieldset>
        <div className="text-right text-xs mb-6">
          <Link to="/reset-password" className="text-blue-700">
            Forgot password?
          </Link>
        </div>
        <Checkbox uid="remember" label="Remember me" value={false} onChange={handleRememberMe} />
        <fieldset className="mb-6">
          <input
            className="w-full bg-gray-800 rounded text-white px-6 py-3 cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
            disabled={!validateForm() || isLoading}
            type="submit"
            value={isLoading ? 'Please wait...' : 'Login'}
          />
        </fieldset>
      </form>
      {isInternalEmail && (
        <>
          <Divider />
          <GoogleButton onClick={handleSignInWithGoogle} />
        </>
      )}
      {loginError && (
        <div className="bg-red-100 border border-red-400 text-red-600 text-center text-sm px-4 py-3 my-4">
          {loginError}
        </div>
      )}
      <div className="text-center text-sm">
        <a href="mailto:support@railstate.com" className="hover:text-blue-700">
          Contact <span className="text-blue-700">support</span> for assistance
        </a>
      </div>
    </AuthLayout>
  );
};
