import { useCallback, useEffect, useState } from 'react';

import _orderBy from 'lodash/orderBy';

import moment from 'moment';
import { DATETIME_FORMAT } from 'constants/formats';

import { useApi } from 'hooks/use-api';

export const useNetworkStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updatedAt, setUpdatedAt] = useState<string>();
  const [hosts, setHosts] = useState<{ name: string; status: string; metrics: Object }[]>([]);

  const [{ data: networkStatus, loading: isNetworkStatusLoading, error: isError }, getNetworkStatus] = useApi({
    manual: false,
    params: { limit: 1 },
    url: '/networkStatus',
  });

  const getNetworkStatusCb = useCallback(() => {
    getNetworkStatus();
  }, [getNetworkStatus]);

  useEffect(() => {
    if (!networkStatus?.length || !networkStatus[0]?.host_list) return;

    const updated = moment(networkStatus[0].updated * 1000).format(DATETIME_FORMAT);
    setUpdatedAt(updated);

    const _hosts = [];
    const hostList = networkStatus[0].host_list;

    for (const name in hostList) {
      _hosts.push({ name, status: hostList[name].status, metrics: hostList[name].metrics });
    }
    setHosts(_orderBy(_hosts, ['name']));
  }, [networkStatus]);

  useEffect(() => {
    const yes = isNetworkStatusLoading;
    setIsLoading(yes);
  }, [isNetworkStatusLoading]);

  return {
    getNetworkStatusCb,
    hosts,
    isError,
    isLoading,
    updatedAt,
  };
};
