// Update `.env-sample` if you update `EnvVars` type
type EnvVars = object &
  Partial<{
    REACT_APP_API_KEY: string;
    REACT_APP_AUTH_DOMAIN: string;
    REACT_APP_DATABASE_URL: string;
    REACT_APP_ENV: string;
    REACT_APP_GOOGLE_MAPS_API_KEY: string;
    REACT_APP_MQTT_PASSWORD: string;
    REACT_APP_MQTT_URL: string;
    REACT_APP_MQTT_USERNAME: string;
    REACT_APP_PROJECT_ID: string;
    REACT_APP_RAILSTATE_API_URL: string;
    REACT_APP_RAILSTATE_API_V1_URL: string;
    REACT_APP_RAILSTATE_API_V3_URL: string;
    REACT_APP_RAILSTATE_API_V3_TOKEN: string;
    REACT_APP_RAILSTATE_HARDWARE_API_URL: string;
  }>;

const throwIfUndefined = <T>(params: { [P in keyof T]?: T[P] }) => {
  const undefinedKeys = Object.entries(params)
    .filter(([, val]) => !val)
    .map(([key]) => key);

  if (undefinedKeys.length) {
    throw new Error(`No values found for keys: [${undefinedKeys.join(', ')}]`);
  }

  return params as Required<T>;
};

export class Environment {
  constructor(private config: EnvVars = process.env) {}

  get firebase() {
    return throwIfUndefined({
      apiKey: this.config.REACT_APP_API_KEY,
      authDomain: this.config.REACT_APP_AUTH_DOMAIN,
      databaseUrl: this.config.REACT_APP_DATABASE_URL,
      projectId: this.config.REACT_APP_PROJECT_ID,
    });
  }

  get apiEnv() {
    if (!this.config.REACT_APP_ENV) {
      throw new Error(`No value found for keys: REACT_APP_ENV`);
    }
    return this.config.REACT_APP_ENV;
  }

  get apiUrl() {
    if (!this.config.REACT_APP_RAILSTATE_API_URL) {
      throw new Error(`No value found for keys: REACT_APP_RAILSTATE_API_URL`);
    }
    return this.config.REACT_APP_RAILSTATE_API_URL;
  }

  get apiV1Url() {
    if (!this.config.REACT_APP_RAILSTATE_API_V1_URL) {
      throw new Error(`No value found for keys: REACT_APP_RAILSTATE_API_V1_URL`);
    }
    return this.config.REACT_APP_RAILSTATE_API_V1_URL;
  }

  get apiV3Url() {
    if (!this.config.REACT_APP_RAILSTATE_API_V3_URL) {
      throw new Error(`No value found for keys: REACT_APP_RAILSTATE_API_V3_URL`);
    }
    return this.config.REACT_APP_RAILSTATE_API_V3_URL;
  }

  get apiV3Token() {
    if (!this.config.REACT_APP_RAILSTATE_API_V3_TOKEN) {
      throw new Error(`No value found for keys: REACT_APP_RAILSTATE_API_V3_TOKEN`);
    }
    return this.config.REACT_APP_RAILSTATE_API_V3_TOKEN;
  }

  get hardwareApiUrl() {
    if (!this.config.REACT_APP_RAILSTATE_HARDWARE_API_URL) {
      throw new Error(`No value found for keys: REACT_APP_RAILSTATE_HARDWARE_API_URL`);
    }
    return this.config.REACT_APP_RAILSTATE_HARDWARE_API_URL;
  }

  get googleApiKey() {
    if (!this.config.REACT_APP_GOOGLE_MAPS_API_KEY) {
      throw new Error(`No value found for keys: REACT_APP_GOOGLE_MAPS_API_KEY`);
    }
    return this.config.REACT_APP_GOOGLE_MAPS_API_KEY;
  }

  get mqttUrl() {
    if (!this.config.REACT_APP_MQTT_URL) {
      throw new Error(`No value found for keys: REACT_APP_MQTT_URL`);
    }
    return this.config.REACT_APP_MQTT_URL;
  }

  get mqttOptions() {
    if (!this.config.REACT_APP_MQTT_USERNAME) {
      throw new Error(`No value found for keys: REACT_APP_MQTT_USERNAME`);
    }
    if (!this.config.REACT_APP_MQTT_PASSWORD) {
      throw new Error(`No value found for keys: REACT_APP_MQTT_PASSWORD`);
    }
    return {
      username: this.config.REACT_APP_MQTT_USERNAME,
      password: this.config.REACT_APP_MQTT_PASSWORD,
    };
  }
}
