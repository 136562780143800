import React, { FC, useState, useCallback } from 'react';
import { DebounceInput } from 'react-debounce-input';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as SubtractIcon } from 'assets/icons/subtract.svg';

const emptyFn = () => ({});
const styleMinWidth80 = { minWidth: '80px' };

type NumberInput = {
  debounce?: number;
  label: string;
  max?: number;
  min?: number;
  onChange?: Function;
  onDecrement?: Function;
  onIncrement?: Function;
  showInput?: boolean;
  step?: number;
  value?: number;
};
export const NumberInput: FC<NumberInput> = ({
  debounce = 1000,
  label,
  max = null,
  min = null,
  onChange = emptyFn,
  onDecrement = emptyFn,
  onIncrement = emptyFn,
  showInput = true,
  step = 1,
  value = null,
}) => {
  const [val, setVal] = useState(value);
  const hasMinMax = min !== null && max !== null;

  const _set = useCallback(
    (v: number) => {
      if (min !== null && v < min) v = min;
      if (max !== null && v > max) v = max;
      setVal(v);
      onChange(v);
    },
    [max, min, onChange],
  );

  const handleChange = useCallback(
    (e, isIncrement) => {
      e.preventDefault();
      isIncrement ? onIncrement() : onDecrement();
      if (showInput && val !== null) {
        const newVal = val + (isIncrement ? step : -step);
        _set(newVal);
      }
    },
    [_set, onDecrement, onIncrement, showInput, step, val],
  );

  const handleDecrement = useCallback((e) => handleChange(e, false), [handleChange]);
  const handleIncrement = useCallback((e) => handleChange(e, true), [handleChange]);

  const handleValueChange = useCallback(
    (e) => {
      e.preventDefault();
      if (!showInput || val === null) return;
      const newVal = Number(e.target.value);
      _set(newVal);
    },
    [_set, showInput, val],
  );

  return (
    <>
      <label className="block mb-1">{label}</label>
      <div className={'border border-gray-400 ' + (showInput ? 'flex' : 'inline-flex')}>
        <button
          className={
            'p-4 border-r border-gray-400 cursor-pointer hover:text-blue-700 ' + (showInput ? 'bg-gray-100' : '')
          }
          onClick={handleDecrement}
        >
          <SubtractIcon className="w-3" />
        </button>
        {showInput && (
          <div className="flex-1">
            <DebounceInput
              className="w-full h-full text-center border-r border-gray-400 px-4"
              debounceTimeout={debounce}
              onChange={handleValueChange}
              style={styleMinWidth80}
              type="number"
              value={String(val)}
            />
          </div>
        )}
        <button
          className={'p-4 cursor-pointer hover:text-blue-700 ' + (showInput ? 'bg-gray-100' : '')}
          onClick={handleIncrement}
        >
          <AddIcon className="w-3" />
        </button>
      </div>
      {hasMinMax && (
        <div className="flex justify-between text-xs text-gray-500 mt-1">
          <span>{min}</span>
          <span>{max}</span>
        </div>
      )}
    </>
  );
};
