import React, { useState, useEffect, useCallback, useMemo } from 'react';

import _isNull from 'lodash/isNull';

import { useApi } from 'hooks/use-api';

import moment from 'moment';
import { DEFAULT_LIMIT, DEFAULT_IMAGE_LIMIT, DEFAULT_SEGMENT_LIMIT, DEFAULT_MAP_BOUNDS } from 'constants/defaults';

import { stringifyMapBounds } from 'utils/stringify-map-bounds';
import { containerPositions } from 'utils/container-positions';
import { mockSegmentData } from 'mocks/segment';

// Types
import { NodeGroup, Segment, SegmentGroup, MapBounds, SegmentTrainActivity } from 'types';

export const useDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [selectedNodeGroup, setSelectedNodeGroup] = useState<NodeGroup | null>();
  const [selectedSegment, setSelectedSegment] = useState<Segment | null>();
  const [selectedSegmentTrainActivity, setSelectedSegmentTrainActivity] = useState<SegmentTrainActivity | null>();
  const [selectedSegmentId, setSelectedSegmentId] = useState();

  const [groupSegments, setGroupSegments] = useState<number[]>([]);
  const [segmentsWithCamera, setSegmentsWithCamera] = useState<number[]>([]);
  const [open, setOpen] = useState(false);

  const defaultDate = moment().toDate();
  const [date, setDate] = useState(defaultDate);
  const startTime = moment(date).startOf('day').valueOf();
  const endTime = moment(date).endOf('day').valueOf();

  const [{ data: segments, loading: isSegmentsLoading }, getSegments] = useApi({ url: '/segments' });
  const [{ data: nodeGroups, loading: isNodeGroupsLoading }, getNodeGroups] = useApi({ url: '/nodeGroups' });
  const [{ data: segmentGroups, loading: isSegmentGroupsLoading }, getSegmentGroups] = useApi({
    url: '/segmentGroups',
  });
  const [{ data: segmentImageSeries, loading: isSegmentImageSeriesLoading }, getSegmentImageSeries] = useApi({
    url: '/imageSeries',
  });

  const getSegmentImageSeriesFn = useCallback(() => {
    getSegmentImageSeries({
      params: {
        endTime,
        limit: DEFAULT_IMAGE_LIMIT,
        segmentId: selectedSegmentId,
        skip: 0,
        startTime,
        type: 'cd',
      },
    }).catch((err) => console.log('err', err));
  }, [selectedSegmentId, startTime, endTime, getSegmentImageSeries]);

  useEffect(() => {
    const segmentGroupsData = segmentGroups?.data;
    if (!segmentGroupsData) return;

    let _groupSegments: number[] = [];
    let _segmentsWithCamera: number[] = [];

    segmentGroupsData.forEach(({ segments = [], hasCamera = [] }: { segments: number[]; hasCamera: number[] }) => {
      _groupSegments = _groupSegments.concat(segments);
      if (hasCamera) _segmentsWithCamera = _segmentsWithCamera.concat(segments);
    });

    setGroupSegments(_groupSegments);
    setSegmentsWithCamera(_segmentsWithCamera);
  }, [segmentGroups]);

  useEffect(() => {
    const yes = isSegmentsLoading || isNodeGroupsLoading || isSegmentGroupsLoading;
    setIsLoading(yes);
  }, [isSegmentsLoading, isNodeGroupsLoading, isSegmentGroupsLoading]);

  useEffect(() => {
    if (selectedSegmentId) getSegmentImageSeriesFn();
  }, [selectedSegmentId, getSegmentImageSeriesFn]);

  const getData = (bounds: MapBounds = DEFAULT_MAP_BOUNDS, cb: Function = () => ({})) => {
    const bbox = stringifyMapBounds(bounds);
    getNodeGroups({ params: { limit: DEFAULT_LIMIT, bbox } });
    getSegments({ params: { limit: DEFAULT_SEGMENT_LIMIT, bbox } });
    getSegmentGroups({ params: { limit: DEFAULT_SEGMENT_LIMIT, bbox } });
    cb();
  };

  const selectSegment = (segment: Segment) => {
    const selectedSegmentGroup = segmentGroups?.data.filter((segmentGroup: SegmentGroup) =>
      segmentGroup.segments.includes(segment.objectid),
    );
    const segmentGroup = selectedSegmentGroup && selectedSegmentGroup.length > 0 ? selectedSegmentGroup[0] : undefined;
    segment = mockSegmentData(segment);
    setSelectedSegment(segmentGroup || segment);
    setSelectedSegmentId(segmentGroup ? segmentGroup.id : segment.objectid);
    setSelectedNodeGroup(null);
  };

  const selectNodeGroup = (nodeGroup: NodeGroup) => {
    setSelectedNodeGroup(nodeGroup);
    setSelectedSegment(null);
  };

  const onDateChange = (d: Date) => {
    setDate(d);
    getSegmentImageSeriesFn();
  };

  const handleTodayDate = useCallback(() => {
    setDate(moment().toDate());
    getSegmentImageSeriesFn();
  }, [getSegmentImageSeriesFn]);

  const getPositionForNodeGroup = (marker: NodeGroup) => {
    return (
      marker.coordinates || {
        lat: parseFloat(marker.avg.lat),
        lng: parseFloat(marker.avg.lng),
      }
    );
  };

  const isNodeGroupInactive = (nodeGroup: NodeGroup) => {
    return !_isNull(nodeGroup.isActive) ? !nodeGroup.isActive : false;
  };

  const openModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const onOpenModal = (ta: SegmentTrainActivity) => {
    setSelectedSegmentTrainActivity(ta);
    openModal();
  };

  const modalTrainDetailHeaderTier2 = useMemo(
    () => ({
      positionInTrain: 'Position In Train',
      carId: 'Car ID',
      carType: 'Car Type',
      containersList: 'Containers',
      hazMatPlacard: 'Haz Mat Placard',
      // eslint-disable-next-line @typescript-eslint/camelcase
      hazMatPlacardUN_Number: 'Haz Mat Placard UN Number',
      hazMatClass: 'Haz Mat Class',
    }),
    [],
  );
  const modalTrainDetailHeaderTier1 = useMemo(
    () => ({
      positionInTrain: 'Position In Train',
      carType: 'Car Type',
    }),
    [],
  );
  const modalTrainDetailData: any = useMemo(() => {
    return selectedSegmentTrainActivity?.carTypes.map((ta) => {
      const styles = { display: 'flex', flexFlow: 'column' };

      if (ta.containers) {
        const list = ta.containers
          .sort((a: { position: string | null }, b: { position: string | null }) =>
            containerPositions.indexOf(a.position?.toLowerCase() || '') >
            containerPositions.indexOf(b.position?.toLowerCase() || '')
              ? 1
              : -1,
          )
          .map((c, k) => (
            <div key={k}>
              {c.type.replace('Container', '').trim()} {c.position && '(' + c.position + ')'}
            </div>
          ));
        ta.containersList = <div style={styles}>{list}</div>;
      }

      return ta;
    });
  }, [selectedSegmentTrainActivity]);

  return {
    date,
    getData,
    getPositionForNodeGroup,
    groupSegments,
    handleTodayDate,
    isLoading,
    isNodeGroupInactive,
    isSegmentImageSeriesLoading,
    nodeGroups,
    onDateChange,
    segmentImageSeries,
    segments,
    segmentsWithCamera,
    selectedNodeGroup,
    selectedSegment,
    selectNodeGroup,
    selectSegment,
    onOpenModal,
    onCloseModal,
    open,
    selectedSegmentTrainActivity,
    modalTrainDetailHeaderTier1,
    modalTrainDetailHeaderTier2,
    modalTrainDetailData,
  };
};
