import React, { FC, useCallback, useEffect, useState, FormEvent, useMemo } from 'react';
import useFormFields from '@usereact/use-form-fields';
import { ReactComponent as DatabaseIcon } from 'assets/icons/database-1-alternate.svg';
import { Select, SelectOptionType } from 'shared/select';
import { Button } from 'shared/button';
import { Card } from 'shared/card';
import { TierList } from 'constants/tier';
import { Customer } from 'types';

type CustomerForm = {
  isLoading: boolean;
  onClear: Function;
  onDelete: Function;
  onSubmit: Function;
  customer: Customer;
};
export const CustomerForm: FC<CustomerForm> = ({ isLoading, onClear, onDelete, onSubmit, customer }) => {
  const [id, setId] = useState<number | null>();
  const isExisting = !!id;
  const tierOptions = useMemo(() => TierList.map((value) => ({ value, label: value })), []);
  const [tier, setTier] = useState<SelectOptionType>({ value: "1", label: "1" });

  const { values, fields, setValue } = useFormFields({ name: '', email: '' });
  const selectTierFromSelect = useCallback((option) => setTier(option), []);

  useEffect(() => {
    setId(customer.id);
    setValue('email', customer.email);
    setValue('name', customer.name);
    setTier({ label: customer && customer.tier ? customer.tier : "1", value: customer && customer.tier ? customer.tier : "1" });
  }, [customer, setValue]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit(isExisting ? { id, name: values.name , tier: tier?.value } : {tier: tier?.value, ...values });
    },
    [onSubmit, id, values, isExisting , tier],
  );

  const handleClear = useCallback(() => onClear(), [onClear]);
  const handleDelete = useCallback(() => onDelete(customer.id), [onDelete, customer.id]);

  return (
    <Card title={isExisting ? 'Edit customer' : 'Add customer'} icon={<DatabaseIcon />}>
      <form className="text-sm" onSubmit={handleSubmit}>
        <div className="pt-6 px-6">
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6">
              <label className="block mb-1" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                className="p-3 placeholder-gray-400 border border-gray-400 w-full mb-6"
                placeholder="Name"
                {...fields.name}
              />
            </fieldset>
            <fieldset className="w-full sm:w-1/2 sm:mx-4 mb-6">
              <label className="block mb-1" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                disabled={isExisting}
                className="p-3 placeholder-gray-400 border border-gray-400 w-full"
                placeholder="Email"
                {...fields.email}
              />
            </fieldset>
          </div>
          <div className="block sm:flex">
            <fieldset className="w-full sm:w-6/12 sm:mx-4 mb-6">
              <label className="block mb-1" htmlFor="password">
                Tier
              </label>
              <Select onChange={selectTierFromSelect} options={tierOptions} placeholder="Role" value={tier} />
            </fieldset>
          </div>
        </div>
        <div className="flex items-center justify-end bg-gray-100 border-t border-gray-300 p-4">
          {!isLoading && (
            <div className="mx-2">
              <Button fill="outline" color="gray-600" onClick={handleClear}>
                Clear
              </Button>
            </div>
          )}
          {isExisting && !isLoading && (
            <div className="mx-2">
              <Button color="red-700" onClick={handleDelete}>
                Delete
              </Button>
            </div>
          )}
          <div className="mx-2">
            <input
              className="bg-blue-700 rounded text-white px-4 py-2 cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed"
              disabled={isLoading}
              type="submit"
              value={isLoading ? 'Please wait...' : `${isExisting ? 'Update customer' : 'Add customer'}`}
            />
          </div>
        </div>
      </form>
    </Card>
  );
};
