import React, { FC, ReactNode } from 'react';

type Card = { title?: string; icon?: ReactNode };
export const Card: FC<Card> = ({ title, icon, children }) => {
  return (
    <div className="bg-white border border-gray-400 box-border">
      {title && (
        <div className="flex items-center border-b border-gray-400 px-4 py-2">
          {icon && <div className="text-blue-700 w-4 mr-3">{icon}</div>}
          <div className={(!icon ? 'text-xs text-gray-800 font-medium' : 'text-sm') + ' pt-1'}>{title}</div>
        </div>
      )}
      <div className="relative">{children}</div>
    </div>
  );
};
