export const colors = {
  blue700: '#2b6cb0',
  gray200: '#edf2f7',
  gray400: '#cbd5e0',
  gray500: '#a0aec0',
  gray700: '#4a5568',
  gray800: '#2d3748',
  indigo600: '#5a67d8',
  red700: '#c53030',
  transparent: 'transparent',
  white: '#ffffff',
};
