import { useCallback, useEffect, useState } from 'react';

import { useApi } from 'hooks/use-api';
import { useDashboard } from '../operator-dashboard/use-operator-dashboard';
import { useToasts } from 'react-toast-notifications';
import { useUser } from 'auth.provider';
import { useParams } from 'react-router-dom';

export const useDeviceDashboard = () => {
  const { addToast } = useToasts();
  const { humanize } = useDashboard();
  const { isReadyOnly = false } = useUser();
  const [loadError, setLoadError] = useState(false);

  const [{ data: devices, loading: isDevicesLoading }, getDevices] = useApi({
    url: '/infrastructure/devices',
    isApiV3: true,
  });
  const [{ loading: isDeviceLoading }, getDeviceDetail] = useApi({
    url: '/infrastructure/devices',
    isApiV3: true,
  });
  const [{ data: deviceStates }, getDeviceStates] = useApi({
    url: '/infrastructure/device_states',
    method: 'GET',
    isApiV3: true,
  });
  const [{ data: sensors, loading: isSensorsLoading }, getSensors] = useApi({
    url: '/infrastructure/sensors',
    isApiV3: true,
  });
  const [{ data: sensorStates }, getSensorStates] = useApi({
    url: '/infrastructure/sensor_states',
    method: 'GET',
    isApiV3: true,
  });
  const [{ data: architectures }, getArchitecture] = useApi({
    url: '/infrastructure/architectures',
    method: 'GET',
    isApiV3: true,
  });
  const [{ loading: isDeviceUpdate }, updateDevice] = useApi({
    url: '/infrastructure/devices',
    method: 'PUT',
    isApiV3: true,
  });
  const [{ loading: isStateUpdate }, updateStateUpdate] = useApi({
    url: '/infrastructure/state_updates',
    method: 'PUT',
    isApiV3: true,
  });
  const [{ loading: isDeviceRestarted }, reastartDevice] = useApi({
    url: '/infrastructure/devices',
    method: 'POST',
    isApiV3: true,
  });

  const onDeviceSubmit = (record: any) => {
    if (!record?.deviceId) {
      addToast('Failed to save device.', { appearance: 'error', autoDismiss: true });
      return;
    }

    const url = `/infrastructure/devices/${record?.deviceId}`;
    const method = 'PUT';
    const data = {
      architecture: record?.architecture || null,
      sshTunnelPort: Number(record?.sshTunnelPort) || null,
      teltonika: {
        imei: record?.imei || null,
        lanMac: record?.lanMac || null,
        serialNumber: record?.serialNumber || null,
        simNumber: record?.simNumber || null,
      },
      info: record?.info || null,
      hardwareComponents: record?.hardwareComponents || null,
    };

    updateDevice({ url, method, data })
      .then(() => getDevices())
      .then(() => {
        addToast('Saved successfully', { appearance: 'success', autoDismiss: true });
      })
      .catch(() => {
        addToast('Failed to save.', { appearance: 'error', autoDismiss: true });
      });
  };

  const [selectedDevice, setSelectedDevice] = useState({ deviceId: null });
  const selectDevice = useCallback(
    (record: any) => {
      record = record && !!record?.record ? record.record : record;

      getDeviceDetail({ url: `/infrastructure/devices/${record?.deviceId}`, method: 'GET' })
        .then((result) => {
          if (result?.data) {
            result.data['lastState'] = record?.lastState || result?.data['lastState'] || null;
            setSelectedDevice(result?.data);
            localStorage.setItem('selectedDevice', JSON.stringify(record));
          } else {
            localStorage.removeItem('selectedDevice');
            addToast('Failed to load.', { appearance: 'error', autoDismiss: true });
          }
        })
        .catch(() => {
          localStorage.removeItem('selectedDevice');
          addToast('Failed to load.', { appearance: 'error', autoDismiss: true });
        });
    },
    [addToast, getDeviceDetail],
  );

  useEffect(() => {
    getSensors().catch(() => {
      addToast('Failed to load sites.', { appearance: 'error', autoDismiss: true });
    });

    getSensorStates().catch(() => {
      addToast('Failed to load site states.', { appearance: 'error', autoDismiss: true });
    });

    getDeviceStates().catch(() => {
      addToast('Failed to load device states.', { appearance: 'error', autoDismiss: true });
    });

    getArchitecture().catch(() => {
      addToast('Failed to load architecture enums.', { appearance: 'error', autoDismiss: true });
    });
  }, [addToast, getSensors, getDeviceStates, getSensorStates, getArchitecture]);

  const { deviceID } = useParams();

  useEffect(() => {
    if (deviceID && !isDeviceLoading) {
      const openDevice = { deviceId: String(deviceID), lastState: '' };
      localStorage.setItem('selectedDevice', JSON.stringify(openDevice));
      window.history.pushState({}, '', '/device-dashboard');
    }

    if (!devices && !isDevicesLoading && !loadError) {
      getDevices().catch(() => {
        addToast('Failed to load devices.', { appearance: 'error', autoDismiss: true });
        setLoadError(true);
      });
    }
  }, [addToast, devices, isDevicesLoading, getDevices, deviceID, isDeviceLoading, loadError]);

  useEffect(() => {
    if (!!localStorage.getItem('selectedDevice') && (!selectedDevice || !selectedDevice?.deviceId)) {
      selectDevice(JSON.parse(String(localStorage.getItem('selectedDevice'))));
    }
  }, [selectDevice, selectedDevice]);

  const onStateSubmit = (record: any) => {
    const data = {
      id: null,
      sensorId: record.sensorId || null,
      deviceId: record.deviceId || null,
      sensorState: record?.sensorState || null,
      deviceState: record?.deviceState || null,
      info: record?.info || null,
    };
    updateStateUpdate({ url: '/infrastructure/state_updates', method: 'POST', data })
      .then(() => selectDevice(record))
      .then(() => getDevices())
      .then(() => {
        addToast('Saved successfully', { appearance: 'success', autoDismiss: true });
      })
      .catch((error) => {
        const message = 'Failed to save.' + (error ? ' ' + String(error) : '');
        addToast(message, { appearance: 'error', autoDismiss: true });
        console.error(message);
      });
  };

  const onDeviceRestart = (record: any) => {
    reastartDevice({ url: `/infrastructure/devices/${record?.deviceId}/restart`, method: 'POST' })
      .then(() => {
        window.scrollTo(0, 0);
        addToast('Restarted successfully', { appearance: 'success', autoDismiss: true });
      })
      .catch((error) => {
        const message = 'Failed to restart.' + (error ? ' ' + String(error) : '');
        addToast(message, { appearance: 'error', autoDismiss: true });
        console.error(message);
      });
  };

  return {
    architectures,
    devices,
    deviceStates,
    getDevices,
    humanize,
    isDeviceLoading,
    isDeviceRestarted,
    isDevicesLoading,
    isDeviceUpdate,
    isReadyOnly,
    isSensorsLoading,
    isStateUpdate,
    onDeviceRestart,
    onDeviceSubmit,
    onStateSubmit,
    selectDevice,
    selectedDevice,
    sensors,
    sensorStates,
    setSelectedDevice,
    updateDevice,
  };
};
