import React from 'react';
import { Redirect } from 'react-router-dom';

import { useUser } from 'auth.provider';
import { AuthRoutes } from 'constants/routes';
import { AppLayout } from './app.layout';

import WelcomeImage from 'assets/images/welcome.png';

export const DefaultPage = () => {
  const user = useUser();
  if (!user.isLoggedIn) return <Redirect to={AuthRoutes.login.path} />;


  return (
    <AppLayout name="Welcome to RailState">
      <div className="flex flex-col items-center justify-center p-4">
        <img src={WelcomeImage} alt="Welcome" className="max-w-xs mb-2" />
        <div className="text-center text-blue-900">Select page from sidebar to proceed</div>
      </div>
    </AppLayout>
  );
};
