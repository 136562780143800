import axios, { Method } from 'axios';
import { makeUseAxios } from 'axios-hooks';

import { useUser } from 'auth.provider';
import { Environment } from 'environment';

type ApiOptions = {
  isApiV1?: boolean;
  isApiV3?: boolean;
  isHardwareApi?: boolean;
  manual?: boolean;
  method?: Method;
  params?: any;
  url: string;
};

export const useApi = (
  {
    isApiV1 = false,
    isApiV3 = false,
    isHardwareApi = false,
    manual = true,
    method = 'GET',
    params = {},
    url,
  }: ApiOptions = {} as ApiOptions,
) => {
  const env = new Environment();
  const user = useUser();

  const config = isHardwareApi
    ? {
        baseURL: env.hardwareApiUrl,
        headers: {
          'Content-Type': undefined,
        },
        auth: {
          username: 'camuser',
          password: 't3mpKAgdjafdslgje345aaadd',
        },
      }
    : {
        baseURL: isApiV1 ? env.apiV1Url : isApiV3 ? env.apiV3Url : env.apiUrl,
        headers: {
          Authorization: `Bearer ${isApiV3 ? env.apiV3Token : user.token}`,
        },
      };

  const axiosInstance = axios.create(config);
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      if (error && [400, 401, 402, 403, 404].includes(error?.response?.status)) {
        // window.location.reload();
      }
      throw new SyntaxError(error?.response?.data?.message || error?.response?.data || error.message);
    },
  );
  const useAxios = makeUseAxios({ axios: axiosInstance });
  return useAxios({ method, url, params }, { manual });
};
